import React, { useEffect, useState } from "react";
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from "reactstrap";
import "../../assets/css/Accordion.css";
import { getRunObjectStorageDataReferences } from "../../common/workflow";
import DataItem from "./DataItem";

const ListRunData = ({ run_id }) => {
    const [objectStorage, setObjectStorage] = useState([]);
    const [open, setOpen] = useState("");

    const toggle = (id) => {
        if (open === id) {
            setOpen("");
        } else {
            setOpen(id);
        }
    };

    useEffect(() => {
        (async () => {
            setObjectStorage(await getRunObjectStorageDataReferences(run_id));
        })();
    }, []);

    return (
        <div className="mb-3">
            <Accordion flush open={open} toggle={toggle}>
                <AccordionItem key="0">
                    <AccordionHeader targetId="0">Datasets used in Workflow</AccordionHeader>
                    <AccordionBody accordionId="0">
                        {objectStorage.map((element, index) => (
                            <DataItem dataset={element} key={`obj_${index}`} />
                        ))}
                    </AccordionBody>
                </AccordionItem>
            </Accordion>
        </div>
    );
};

export default ListRunData;
