import React from "react";
import { Card, CardBody, CardTitle, CardText, Button } from "reactstrap";

export default ({ id, name, callback }) => {
    return (
        <>
            <Card>
                <CardBody>
                    <CardTitle tag="h3">{name}</CardTitle>
                    <CardText>Mark this step as completed</CardText>
                    <Button color="success" onClick={() => callback(id)}>
                        Complete
                    </Button>
                </CardBody>
            </Card>
        </>
    );
};
