import endpoints from "../endpoints";
import { axios_v1 as axios } from "../axios";

/**
 * @typedef {Object} OAuthAPIs
 * @property {Object} OAuthAPIs.v1 - v1 api functions
 * @property {Promise} OAuthAPIs.v1.login - login()
 * @property {Promise} OAuthAPIs.v1.logout - logout()
 */

/**
 * Get workflow API functions
 * @returns {OAuthAPIs} api functionss
 */
const oauth_api = (function OAuthAPIs() {
    return {
        v1: {
            /**
             * @returns {Promise}
             */
            login: () => axios.get(endpoints.v1.login()),
            logout: () => axios.get(endpoints.v1.logout()),
        },
    };
})();

export default oauth_api;
