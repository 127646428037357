import React, { useEffect } from "react";

import { Input, Label, Button, InputGroup, Form } from "reactstrap";

import { useForm, Controller } from "react-hook-form";

import { createWorkflowCategory, updateWorkflowCategory, deleteWorkflowCategory } from "../../common/workflow";

const WorkflowCategoryModel = ({ inputValues, isDeleteMode }) => {
    isDeleteMode = isDeleteMode || false;
    let updatedValues = inputValues || {
        name: "",
        code: "",
    };

    const {
        control,
        setError,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({ updatedValues });

    useEffect(() => {
        setValue("name", updatedValues.name);
        setValue("code", updatedValues.code);
    }, [updatedValues]);

    const onSubmit = (data) => {
        if (Object.values(data).every((field) => field.length > 0 || field.toString().length > 0)) {
            const processedData = {
                name: data.name,
                code: data.code,
            };

            let callbackStatus = false;
            inputValues == undefined
                ? (callbackStatus = createWorkflowCategory(processedData))
                : (callbackStatus = updateWorkflowCategory(inputValues.id, processedData));
            // (inputValues != undefined) && callbackStatus ? AlertHelper.showSuccessAlert('Good job!', 'New Module Category Created!') : AlertHelper.showSuccessAlert('Good job!', 'Module Category was successfully updated!');
        } else {
            for (const key in data) {
                if (data[key].length === 0) {
                    setError(key, {
                        type: "manual",
                    });
                }
            }
        }
    };

    return (
        <React.Fragment>
            <Form className="mt-2 pt-50" onSubmit={handleSubmit(onSubmit)}>
                {!isDeleteMode ? (
                    <>
                        <div className="mb-1">
                            <Label className="form-label" for="name">
                                Name
                            </Label>
                            <Controller
                                name="name"
                                control={control}
                                render={({ field }) => (
                                    <InputGroup>
                                        <Input
                                            id="name"
                                            name="name"
                                            placeholder="Category Name..."
                                            invalid={errors.name && true}
                                            {...field}
                                        />
                                    </InputGroup>
                                )}
                            />
                            {errors.name && (
                                <span style={{ color: "red" }}>
                                    Please enter a valid Category Name
                                    <br />
                                </span>
                            )}
                            <Label className="form-label" for="code">
                                Code
                            </Label>
                            <Controller
                                name="code"
                                control={control}
                                render={({ field }) => (
                                    <InputGroup>
                                        <Input
                                            id="code"
                                            name="code"
                                            placeholder="Code..."
                                            invalid={errors.code && true}
                                            {...field}
                                        />
                                    </InputGroup>
                                )}
                            />
                            {errors.code && (
                                <span style={{ color: "red" }}>
                                    Please enter a valid Code
                                    <br />
                                </span>
                            )}
                        </div>
                        <Button className="me-1" color="primary" type="submit">
                            Submit
                        </Button>
                    </>
                ) : (
                    <>
                        <span>Are you sure you want to delete the selected entry?</span> <br />
                        <br />
                        <Button
                            color="danger"
                            onClick={() => {
                                deleteWorkflowCategory(inputValues.id);
                            }}
                        >
                            Confirm
                        </Button>
                    </>
                )}
            </Form>
        </React.Fragment>
    );
};

export default WorkflowCategoryModel;
