import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button, Sizes } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

export default function RestoreWorfklowModal(props) {
    //react hook for modal
    const [show, setShow] = useState();

    const handleClose = () => {
        document.querySelector("#root").style.display = "block";
        setShow(false);
    };
    const handleShow = () => {
        document.querySelector("#root").style.display = "none";

        setShow(true);
    };

    const { deletedworkflowid } = props;

    function restoreWorkflow() {
        // /workflow/{workflow_id}/revert

        console.log("trying to restore");
        var config = {
            method: "delete",
            url: process.env.REACT_APP_API_LINK + "/workflow/" + deletedworkflowid + "/revert",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        axios(config)
            .then(function (response) {
                console.log(response.data);

                console.log("restored workflow with id ... " + deletedworkflowid);

                props.callback();
            })
            .catch(function (error) {
                console.log(error);
            });

        handleClose();
    }
    return (
        <>
            <Button variant={props.buttoncolor} className="nextButton" onClick={handleShow}>
                {props.modaltitle}
            </Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.modalheader}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{props.modalbody}</Modal.Body>
                <Modal.Footer>
                    <Button className="mb-2" variant="danger" size="sm" onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        className="mb-2"
                        variant="primary"
                        size="sm"
                        deletedworkflowid={deletedworkflowid}
                        onClick={() => {
                            restoreWorkflow(deletedworkflowid);
                            handleClose();
                        }}
                    >
                        {props.rightbutton}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
