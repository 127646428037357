import React from "react";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Form } from "reactstrap";
import TaskSettings from "../../components/workflowCRUD/TaskSettings";

import { initializeWorkflowRun, getAllWorkflowData } from "../../common/workflow";

const InitRunWorkflow = () => {
    const navigate = useNavigate();
    const { workflowid } = useParams();
    const [tasks, setTasks] = useState({});
    const settings = {};

    async function onSubmitForm(e) {
        e.target.disabled = true;

        const response = await initializeWorkflowRun(workflowid, { settings, name: new Date().toISOString() });
        navigate(`/workflow/${workflowid}/run/${response.id}`);
    }

    useEffect(() => {
        (async () => {
            let data = await getAllWorkflowData(workflowid);

            setTasks(data.tasks);
        })();
    }, []);

    return (
        <>
            <TaskSettings tasks={tasks} settings={settings} />

            <Button type="submit" onClick={onSubmitForm}>
                Run workflow
            </Button>
        </>
    );
};

export default InitRunWorkflow;
