import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { loginCallback } from "../redux/auth/auth-actions";
import { selectPrevPath } from "../redux/token/localstore-slice";
import { setPrevPathAction } from "../redux/token/localstore-actions";

const LoginCallback = () => {
    const { token, user, actual_token, refresh_token } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const prevPath = useSelector(selectPrevPath);

    useEffect(() => {
        localStorage.setItem('es_actual_token', actual_token);
        localStorage.setItem('es_refresh_token', refresh_token);
        if (token) {
            dispatch(
                loginCallback(token, user, () => {
                    const tmp = prevPath;
                    dispatch(setPrevPathAction("/"));
                    navigate(tmp);
                })
            );
        }
    }, []);

    return <></>;
};

export default LoginCallback;
