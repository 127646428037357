import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Card, CardHeader, CardTitle, CardBody } from "reactstrap";

const CustomDataTable = ({ data, columns, tableName, hasServerPagination, getDataCallback, totalDataCount }) => {
    const currentPage = 0;
    const [items, setItems] = useState(hasServerPagination ? [] : data);
    const totalRows = totalDataCount;
    const [perPage, setPerPage] = useState(10);
    const [dtProps, setDtProps] = useState({});

    useEffect(() => {
        if (hasServerPagination) fetchData(1, perPage);

        let paginationServer = !(hasServerPagination === undefined || hasServerPagination === false);

        if (paginationServer) {
            setDtProps({
                paginationServer,
                paginationRowsPerPageOptions: [10, 12, 15],
                paginationTotalRows: totalRows,
                onChangePage: handlePageChange,
                onChangeRowsPerPage: handlePerRowsChange,
            });
        } else {
            setDtProps({
                className: "react-dataTable",
                paginationDefaultPage: currentPage + 1,
                paginationRowsPerPageOptions: [10, 25, 50, 100],
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [perPage]);

    const fetchData = async (page, per_page) => {
        const skip = (page - 1) * per_page;
        let dataFetched = await getDataCallback(skip, per_page);
        console.log(dataFetched);
        setItems(dataFetched.data);
    };

    const handlePageChange = (page) => {
        fetchData(page, perPage);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
    };

    return (
        <>
            <Card>
                <CardHeader>
                    <CardTitle tag="h4">{tableName}</CardTitle>
                </CardHeader>
                <CardBody>
                    <DataTable
                        noHeader
                        pagination
                        data={items}
                        columns={columns}
                        {...dtProps}
                        className="datatables-overflow"
                    />
                </CardBody>
                
            </Card>
        </>
    );
};

export default CustomDataTable;
