import React, { useEffect, useState } from "react";
import { CardText, Button } from "reactstrap";
import { ListGroup, ListGroupItem } from "reactstrap";

const DataObjectMapFormWizard = ({ wizardList, wizardData, callback, showProceed = true }) => {
    const [formStep, setFormStep] = useState(0);
    const [formLength, setFormLength] = useState(1);
    const [formObject, setFormObject] = useState({});

    useEffect(() => {
        setFormLength(wizardList.length);
        setFormObject((oldObj) => {
            return { ...oldObj, ...{ data_input: {} } };
        });
    }, []);

    function selectAndProceed(object) {
        const oldObj = formObject;
        oldObj.data_input[wizardList[formStep]] = object;

        setFormObject(oldObj);

        let newStep = formStep;
        newStep += 1;
        setFormStep(newStep);

        if (newStep >= formLength && !showProceed) {
            submit();
        }
    }

    function goBack() {
        if (formStep > 0) {
            let newStep = formStep;
            newStep -= 1;
            setFormStep(newStep);
        }
    }

    function submit() {
        callback(formObject);
    }

    if (formStep >= 0 && formStep < formLength) {
        return (
            <div className="mb-3">
                <h4>
                    Please select an object to use as <span className="font-weight-bold">{wizardList[formStep]}</span>
                </h4>

                {wizardData.map((element, index) => (
                    <ListGroupItem className="card" key={index}>
                        <>
                            {element.object_name}
                            <Button color="primary" onClick={() => selectAndProceed(element)}>
                                select
                            </Button>
                        </>
                    </ListGroupItem>
                ))}

                {formStep > 0 && (
                    <Button color="danger" onClick={goBack}>
                        back
                    </Button>
                )}
            </div>
        );
    } else if (formStep >= formLength) {
        return (
            <div className="mb-3">
                {Object.keys(formObject.data_input).map((name) => {
                    return (
                        <ListGroupItem className="card" key={name}>
                            <>
                                <h5>Selected {name}:</h5>
                                <CardText>
                                    <span className="font-weight-bold">Bucket</span>:{" "}
                                    {formObject.data_input[name].bucket_name}
                                </CardText>
                                <CardText>
                                    <span className="font-weight-bold">Object</span>:{" "}
                                    {formObject.data_input[name].object_name}
                                </CardText>
                            </>
                        </ListGroupItem>
                    );
                })}
                <Button color="danger" onClick={goBack}>
                    back
                </Button>
                {showProceed && (
                    <Button color="primary" onClick={submit}>
                        proceed
                    </Button>
                )}
            </div>
        );
    } else {
        return <></>;
    }
};

export default DataObjectMapFormWizard;
