import axios from 'axios';
import { store } from "../redux";

export class WorkflowsService{
    ws_id = store.getState().localStore.wsid;
    token = store.getState().localStore.token;
    api = axios.create({
        baseURL: process.env.REACT_APP_API_LINK,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'x-es-token': this.token,
            'x-es-wsid': this.ws_id,
            'x-jwt-token': localStorage.getItem('es_actual_token'),
        },
    });

    async getWorkflows(skip = 0, limit = 100) {
        try{
            const response = await this.api.get('/v2/workflow/?skip=' + skip + '&limit=' + limit);
            console.log(response.data);
            return response.data;
        }catch(e){
            console.log(e);
        
        }
    }

    async createWorkflow(data) {
        try{
            const response = await this.api.post('/v2/workflow/', data);
            return response.data;
        }catch(e){
            console.log(e);
        }
    }

    async update_workflow(data, id) {
        try{
            const response = await this.api.put('/v2/workflow/' + id, data);
            return response.data;
        }catch(e){
            console.log(e);
        }
    }

    async getCategories(){
        try{
            const response = await this.api.get('/category/workflow');
            return response.data;
        }catch(e){
            console.log(e);
        }
    }

    async getVariables(){
        try{
            const response = await this.api.get('/variable?limit=1000');
            return response.data;
        }catch(e){
            console.log(e);
        }
    }

}