import React, { useContext, useState } from "react";
import { Button, ListGroupItem } from "reactstrap";
import { getDataStorageCatalogSchemaTableSources, downloadStorageCatalogSchemaTableSource } from "../../common/datalake";
import { DataSelectedContext } from "../../contexts/datalake/datastorage";
import { store } from "../../redux";
import CatalogSchemaDatasets from "./CatalogSchemaDatasets";

const CatalogSchemaTables = ({ catalog, schema, tables }) => {
    const [defaultTableInspection, setDefaultTableInspection] = useState("csv_anonymized_data");
    const [hasCSVdatasets, setHasCSVdatasets] = useState(false);
    const [hasTables, setHasTables] = useState(false);
    const [showSources, setShowSources] = useState(false);
    const [sources, setSources] = useState([]);
    const { dataSelected, setDataSelected } = useContext(DataSelectedContext);

    function selectWsID(state) {
        return state.localStore.wsid;
    }

    const selectAll = () => {
        for (let i = 0; i < tables.length; i++) {
            selectTable(tables[i][0]);
        }
    }

    const selectTable = (table) => {
        let wsid = selectWsID(store.getState());

        if (!isTableSelected(table)) {
            let obj = {
                catalog,
                schema,
                table,
                name: table,
                ws_id: wsid,
            };
            setDataSelected((oldArray) => [...oldArray, obj]);
        }
    };

    const downloadTable = async (table) => {
        let wsid = selectWsID(store.getState());
        
        const data = await downloadStorageCatalogSchemaTableSource(catalog, schema, table);
        console.log(data);
    };

    function isTableSelected(table) {
        let isSelected = false;
    
        dataSelected.every((data) => {
            if (data.catalog === catalog && data.schema === schema && data.table === table) {
                isSelected = true;
                return false;
            }
            return true;
        });

        return isSelected;
    }

    async function fetchSources(element, table = defaultTableInspection) {
        element.target.disabled = true;

        try {
            element.target.innerText = "Loading...";
            const objList = await getDataStorageCatalogSchemaTableSources(catalog, schema, table);

            setShowSources(true);
            setSources(objList.data);
        } catch (error) {
            element.target.innerText = "Inspect";
            setShowSources(false);
            setSources([]);
        }
    }

    return (
        <>
            {showSources && (
                <CatalogSchemaDatasets
                    catalog={catalog}
                    schema={schema}
                    table={defaultTableInspection}
                    sources={sources}
                />
            )}

            {!showSources && hasCSVdatasets && (
                <ListGroupItem className="card">
                    <>
                        Inspect datasets inside
                        <Button color="success" onClick={(element) => fetchSources(element)}>
                            Inspect
                        </Button>
                    </>
                </ListGroupItem>
            )}

            {!showSources && hasTables && <>
                <h3 className="mt-5">List of Tables</h3>
                <Button size='sm' color="primary" onClick={selectAll}>Select All Tables</Button>
            </>}
            {!showSources &&
                tables
                    .filter(function (element) {
                        if (element[0] === defaultTableInspection) {
                            if (!hasCSVdatasets) {
                                setHasCSVdatasets(true);
                            }
                            return false;
                        }
                        return true;
                    })
                    .map((element, index) => {
                        if (!hasTables) {
                            setHasTables(true);
                        }

                        return (
                            <ListGroupItem className="card" key={index}>
                                <>
                                    {element[0]}{" "}
                                    <Button size='sm'
                                        disabled={isTableSelected(element[0])}
                                        color={!isTableSelected(element[0]) ? "info" : "warning"}
                                        onClick={() => {
                                            selectTable(element[0]);
                                        }}
                                    >
                                        Select Table
                                    </Button>
                                </>
                            </ListGroupItem>
                        );
                    })}
        </>
    );
};

export default CatalogSchemaTables;
