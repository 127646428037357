import { React, useEffect, useState } from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import SelectObjects from "../datalake/SelectObjects";
import ReviewVariables from "../metadataManager/ReviewVariables";

export default ({ id, name, callback, workflowStores, stores, variables }) => {
    const [parsedStores, setParsedStores] = useState({});

    function checkForWfStores() {
        let workflowStoresKeys = Object.keys(workflowStores);
        let returnStores = {};

        if (stores.length > 0) {
            stores.forEach((store) => {
                let itemKey = Object.keys(store)[0];
                let item = store;

                if (workflowStoresKeys.includes(itemKey)) {
                    if (item[itemKey].mode == "get") {
                        returnStores[workflowStores[itemKey].type] = {
                            mode: item[itemKey].mode,
                            sid: itemKey,
                            info: workflowStores[itemKey],
                            exists: true,
                        };
                    }
                }
            });
        }

        return returnStores;
    }

    useEffect(() => {
        setParsedStores(checkForWfStores());
    }, []);

    function storeCallback(store) {
        callback(id, { store });
    }

    function variablesCallback(variables) {
        callback(id, { variables });
    }

    return (
        <>
            <Card>
                <CardTitle tag="h3">{name}</CardTitle>
                <CardBody>
                    {Object.keys(parsedStores).length !== 0 && parsedStores.constructor === Object && (
                        <SelectObjects stores={parsedStores} callback={storeCallback} />
                    )}

                    {Object.keys(parsedStores).length === 0 && (
                        <ReviewVariables stateVariables={variables} callback={variablesCallback} />
                    )}
                </CardBody>
            </Card>
        </>
    );
};
