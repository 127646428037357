import { React, useEffect, useState } from "react";
import ListVariables from "./ListVariables";
import { Button, ListGroupItem, Accordion, AccordionBody, AccordionHeader, AccordionItem, Row, Col } from "reactstrap";
import "../../assets/css/Accordion.css";

export default ({ stateVariables, callback }) => {
    const [inputVariables, setInputVariables] = useState([]);
    const [outputVariables, setOutputVariables] = useState([]);
    const [open, setOpen] = useState("");

    const toggle = (id) => {
        if (open === id) {
            setOpen("");
        } else {
            setOpen(id);
        }
    };

    function containsVariable(variable, list) {
        const length = list.length;
        for (let i = 0; i < length; i++) {
            if (list[i]?.variable_id === variable.variable_id) {
                return { exists: true, position: i };
            }
        }

        return { exists: false, position: null };
    }

    function pushVariable(variable) {
        if (open === "input") {
            const { exists } = containsVariable(variable, inputVariables);
            if (!exists) {
                setInputVariables([...inputVariables, variable]);
            }
        } else if (open === "output") {
            const { exists } = containsVariable(variable, outputVariables);
            if (!exists) {
                setOutputVariables([...outputVariables, variable]);
            }
        }
    }

    function removeVariable(variable) {
        if (open === "input") {
            const { exists, position } = containsVariable(variable, inputVariables);
            if (exists) {
                setInputVariables((old) => [...old.slice(0, position), ...old.slice(position + 1)]);
            }
        } else if (open === "output") {
            const { exists, position } = containsVariable(variable, outputVariables);
            if (exists) {
                setOutputVariables((old) => [...old.slice(0, position), ...old.slice(position + 1)]);
            }
        }
    }

    function callbackMiddleware() {
        callback({
            input: inputVariables,
            output: outputVariables,
        });
    }

    useEffect(() => {
        console.log(stateVariables);
        if (stateVariables?.input) {
            setInputVariables(stateVariables.input);
        }
        if (stateVariables?.output) {
            setOutputVariables(stateVariables.output);
        }
    }, []);

    return (
        <>
            <Accordion flush open={open} toggle={toggle}>
                <AccordionItem key="input">
                    <AccordionHeader targetId="input">Input Variables</AccordionHeader>
                    <AccordionBody accordionId="input">
                        {inputVariables.map((variable, idx) => (
                            <ListGroupItem className="card" key={variable?.variable_id ?? idx}>
                                <Row>
                                    <Col xs="8">
                                        ({variable?.variable_id}) {variable?.name}
                                    </Col>
                                    <Col xs="4">
                                        <Button color="danger" onClick={() => removeVariable(variable)}>
                                            remove
                                        </Button>
                                    </Col>
                                </Row>
                            </ListGroupItem>
                        ))}
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem key="output">
                    <AccordionHeader targetId="output">Output Variables</AccordionHeader>
                    <AccordionBody accordionId="output">
                        {outputVariables.map((variable, idx) => (
                            <ListGroupItem className="card" key={variable?.variable_id ?? idx}>
                                <Row>
                                    <Col xs="8">
                                        ({variable?.variable_id}) {variable?.name}
                                    </Col>
                                    <Col xs="4">
                                        <Button color="danger" onClick={() => removeVariable(variable)}>
                                            remove
                                        </Button>
                                    </Col>
                                </Row>
                            </ListGroupItem>
                        ))}
                    </AccordionBody>
                </AccordionItem>
            </Accordion>
            {open !== "" && (
                <>
                    <h3 className="mt-5">Press select to set as {open} variable</h3>
                    <ListVariables text={open} callback={pushVariable} />
                </>
            )}
            <h3 className="mt-5">Are you ok with the Variables in the system so far?</h3>
            <Button color="success" onClick={callbackMiddleware}>
                Yes
            </Button>
        </>
    );
};
