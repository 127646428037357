import endpoints from "../endpoints";
import { axios_v1 as axios } from "../axios";

/**
 * @typedef {Object} ModuleAPIs
 * @property {Object} ModuleAPIs.v1 - v1 api functions
 * @property {Promise} ModuleAPIs.v1.getCategoryModules - getCategoryModules(params?)
 * @property {Promise} ModuleAPIs.v1.postCategoryModule - postCategoryModule(data)
 * @property {Promise} ModuleAPIs.v1.putCategoryModule - putCategoryModule(id, data)
 * @property {Promise} ModuleAPIs.v1.deleteCategoryModule - deleteCategoryModule(id)
 * @property {Promise} ModuleAPIs.v1.getModules - getModules(params?)
 * @property {Promise} ModuleAPIs.v1.postModule - postModule(data)
 * @property {Promise} ModuleAPIs.v1.putModule - putModule(id, data)
 * @property {Promise} ModuleAPIs.v1.deleteModule - deleteModule(id)
 */

/**
 * Get workflow API functions
 * @returns {ModuleAPIs} api functionss
 */
const module_api = (function ModuleAPIs() {
    return {
        v1: {
            /**
             * @param {object?} params
             * @returns {Promise}
             */
            getCategoryModules: (
                params = {
                    skip: 0,
                    limit: 10,
                    order: "name",
                    direction: "asc",
                }
            ) => {
                return axios.get(endpoints.v1.moduleCategories(), { params });
            },
            /**
             * @param {object} data
             * @param {string} code
             */
            postCategoryModule: (data) => {
                return axios.post(endpoints.v1.moduleCategories(), data);
            },
            /**
             * @param {string} id
             * @param {object} data
             * @param {string} code
             */
            putCategoryModule: (id, data) => {
                return axios.put(endpoints.v1.moduleCategoryID(id), data);
            },
            /**
             * @param {string} id
             * @param {string} code
             */
            deleteCategoryModule: (id) => {
                return axios.delete(endpoints.v1.moduleCategoryID(id));
            },
            /**
             * @param {object?} params
             * @returns {Promise}
             */
            getModules: (
                params = {
                    skip: 0,
                    limit: 10,
                    order: "name",
                    direction: "asc",
                    task: null,
                    category: null,
                }
            ) => {
                return axios.get(endpoints.v1.modules(), { params });
            },
            /**
             * @param {object} data
             * @param {string} code
             */
            postModule: (data) => {
                return axios.post(endpoints.v1.modules(), data);
            },
            /**
             * @param {string} id
             * @param {object} data
             * @param {string} code
             */
            putModule: (id, data) => {
                return axios.put(endpoints.v1.moduleID(id), data);
            },
            /**
             * @param {string} id
             * @param {string} code
             */
            deleteModule: (id) => {
                return axios.delete(endpoints.v1.moduleID(id));
            },
        },
    };
})();

export default module_api;
