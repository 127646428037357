import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Table, Card, CardHeader, CardBody, 
    CardFooter, Modal, ModalHeader, ModalBody, ModalFooter 
} from 'reactstrap';
import { RunService } from '../../services/runService';
import { WorkflowsService } from '../../services/workflowsService';
import { useNavigate } from 'react-router-dom';

const ListRuns = () => {
    const [runs, setRuns] = useState([]);
    const [workflows, setWorkflows] = useState([]);
    const [modal, setModal] = useState(false);
    const [selectedRun, setSelectedRun] = useState(null);
    const runsService = new RunService();
    const workflowsService = new WorkflowsService();
    const navigate = useNavigate();

    useEffect(() => {
        runsService.getRuns().then((response) => {
            setRuns(response);
        });

        workflowsService.getWorkflows().then((response) => {
            setWorkflows(response.data);
        });
    }, []);

    const toggle = (run) => {
        setSelectedRun(run);
        setModal(!modal);
    }

    const addRun = () => {
        navigate('/run/newrun');
    }

    const goto = (runId) => {
        navigate(`/run/${runId}`);
    }

    return (
        <div className="animated fadeIn">
            <Row>
                <Col>
                    <Card>
                        <CardHeader>
                            <i className="fa fa-align-justify"></i> Runs
                        </CardHeader>
                        <CardBody>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Title</th>
                                        <th>Workflow</th>
                                        <th>Start Time</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {runs.map((run, index) => (
                                        <tr key={index}>
                                            <td>{run.id}</td>
                                            <td>{run.title}</td>
                                            <td>{workflows.find(w => w.id === run.workflow_id)?.name}</td>
                                            <td>{run.created_at}</td>
                                            <td>
                                                <Button color="primary" onClick={() => goto(run.id)}>Details</Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </CardBody>
                        <CardFooter>
                            <Button color="success" onClick={addRun}>Add Run</Button>
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default ListRuns;