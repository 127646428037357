import React, { useEffect } from "react";
import "boxicons/css/boxicons.min.css";
import AppRouting from "./routes";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/css/App.css";
import "./assets/css/loading.css";
import "./assets/css/Modal.scss";
import { useDispatch, useSelector } from "react-redux";
import { loginSuccess } from "./redux/auth/auth-slice";
import { initTokenAction, initPrevPathAction } from "./redux/token/localstore-actions";
import { selectToken } from "./redux/token/localstore-slice";
import BreadcrumbsProvider, { breadcrumbsContext } from "./contexts/breadcrumbsContext";
import ObjectSelectedProvider, { ObjectSelectedContext } from "./contexts/datalake/objectstorage";
import DataSelectedProvider from "./contexts/datalake/datastorage";
import { AuthService } from "./services/authService";

function App() {
    const dispatch = useDispatch();
    const token = useSelector(selectToken);
    const authService = new AuthService();

    useEffect(() => {
        if (token) {
            dispatch(loginSuccess(token));
        }
        setInterval(() => {
            authService.refresh_token();
        
        }, 60000);
    }, [token]);

    useEffect(() => {
        dispatch(initTokenAction());
        dispatch(initPrevPathAction());
    }, [dispatch]);
    // const { breadcrumbs, setBreadcrumbs } = useContext(breadcrumbsContext)
    return (
        <>
            <DataSelectedProvider>
                <ObjectSelectedProvider>
                    <BreadcrumbsProvider>
                        <AppRouting />
                    </BreadcrumbsProvider>
                </ObjectSelectedProvider>
            </DataSelectedProvider>
        </>
    );
}

export default App;
