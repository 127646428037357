import React from "react";
import "../../assets/css/Tasks.css";

function Task(props, { onTglStatus }) {
    // console.log('loading a single Task...');

    //below,  we implement a task to send the result on Tasks.jsx
    return (
        <>
            <div className="card tex-left">
                <div className="row">
                    <div className="col-12">
                        <h3>Task Name: {props.task.name}</h3>
                    </div>
                    <div className="col-12 is-center">type: {props.task.type}</div>
                    <div className="col-12 is-center">input: {props.task.inputs}</div>
                    <div className="col-12 is-center">output: {props.task.outputs}</div>
                    <div className="col-12 is-center">
                        Manual task:
                        {
                            //Check if task is manual or not
                            props.task.manual === true ? <div>Yes</div> : <div>No</div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default Task;
