import { React, useEffect, useState } from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import { getRunObjectStorageDataReferences } from "../../common/workflow";
import DataObjectMapFormWizard from "../ObjectStorage/DataObjectMapFormWizard";
import { Input, Label, FormGroup, Button } from "reactstrap";

export default ({ id, name, callback, modules, run_id }) => {
    const [instructions, setInstructions] = useState({});
    const [userInputFields, setUserInputFields] = useState([]);
    const [objectSelected, setObjectSelected] = useState([]);
    const [showFormWizard, setShowFormWizard] = useState(false);
    const [showParamsTextarea, setShowParamsTextarea] = useState(false);
    const [showUserInputForm, setShowUserInputForm] = useState(false);
    const [form, setForm] = useState({
        data_input: {},
        user_input: {},
        params: JSON.stringify({}),
    });

    function objectToBeMappedCallback(obj) {
        setForm((old) => {
            return { ...old, data_input: obj.data_input };
        });
    }

    function parseParams(e) {
        setForm((old) => {
            return { ...old, params: e.target.value };
        });
    }

    function parseUserInput(e) {
        setForm((old) => {
            old.user_input[e.target.name] = e.target.value.trim();
            return { ...old };
        });
    }

    function submit() {
        const parsedValues = {};

        if ("data_input" in instructions) {
            parsedValues.data_input = form.data_input;
        }

        if ("params" in instructions) {
            parsedValues.params = JSON.parse(form.params);
        }

        if ("user_input" in instructions) {
            parsedValues.user_input = form.user_input;

            if ("feature_labels" in parsedValues.user_input) {
                if (parsedValues.user_input.feature_labels === "") {
                    parsedValues.user_input.feature_labels = [];
                } else {
                    const comma_split = parsedValues.user_input.feature_labels.split(",");
                    parsedValues.user_input.feature_labels = comma_split
                        .map((strs) => strs.trim())
                        .filter((strs) => strs !== "");
                }
            }
        }
        callback(id, parsedValues);
    }

    useEffect(() => {
        if ("data_input" in instructions) {
            setShowFormWizard(true);
        }

        if ("params" in instructions) {
            setShowParamsTextarea(true);

            parseParams({ target: { value: JSON.stringify(instructions.params) } });
        }

        if ("user_input" in instructions) {
            setShowUserInputForm(true);

            instructions.user_input.forEach((input_name) => {
                parseUserInput({ target: { value: "", name: input_name } });
            });

            setUserInputFields(instructions.user_input);
        }
    }, [instructions]);

    useEffect(() => {
        (async () => {
            setObjectSelected(await getRunObjectStorageDataReferences(run_id));

            const classModule = modules[0];
            setInstructions(classModule?.instructions ?? {});
        })();
    }, []);

    return (
        <>
            <Card>
                <CardBody>
                    <CardTitle tag="h3">{name}</CardTitle>
                    <div>
                        {showParamsTextarea && (
                            <FormGroup>
                                <Label className="form-label" for="instructions">
                                    Edit Parameters
                                </Label>
                                <Input
                                    id="instructions"
                                    name="instructions"
                                    type="textarea"
                                    value={form.params}
                                    onChange={parseParams}
                                />
                            </FormGroup>
                        )}
                        {showUserInputForm &&
                            userInputFields.map((index) => (
                                <FormGroup key={index}>
                                    <Label className="form-label">Edit {index}</Label>
                                    <Input
                                        id={`${index}`}
                                        name={`${index}`}
                                        type="text"
                                        value={form.user_input[index]}
                                        onChange={parseUserInput}
                                    />
                                </FormGroup>
                            ))}
                        {showFormWizard && (
                            <DataObjectMapFormWizard
                                wizardList={instructions.data_input}
                                wizardData={objectSelected}
                                callback={objectToBeMappedCallback}
                                showProceed={false}
                            />
                        )}
                    </div>
                    <Button color="success" onClick={submit}>
                        Submit
                    </Button>
                </CardBody>
            </Card>
        </>
    );
};
