import React, { useEffect, useState } from "react";
import CustomDataTable from "../../components/datatable";
import { Button } from "reactstrap";
import AddNewModal from "../../components/addNewModal";
import modalTypes from "../../utils/modalTypes";
import WorkflowCategoryModel from "./wfCategoryModel";
import { columns } from "./wfCategoriesDatatableData";
import { getWorfklowCategories } from "../../common/workflow";

const ListWorkflowCategories = () => {
    const [workflowCategories, setWorkflowCategories] = useState([]);

    const [createModal, setCreateModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleCreateModal = async () => {
        setCreateModal(!createModal);
        setWorkflowCategories((await getWorfklowCategories())?.data ?? []);
    };

    useEffect(() => {
        (async () => {
            setLoading(true);

            setWorkflowCategories((await getWorfklowCategories())?.data ?? []);

            setLoading(false);
        })();
    }, []);

    return (
        <>
            {loading ? (
                <>
                    <div className="loader-container">
                        <div className="spinner" style={{ marginLeft: "-50%", marginTop: "-10%" }}></div>
                    </div>
                </>
            ) : (
                <>
                    <Button onClick={handleCreateModal} color="primary">
                        Create Workflow Category
                    </Button>
                    <CustomDataTable data={workflowCategories} columns={columns} tableName={"Workflow Categories"} />

                    <AddNewModal
                        title={"Create Workflow Category"}
                        open={createModal}
                        handleModal={handleCreateModal}
                        type={modalTypes.CREATE}
                        Model={WorkflowCategoryModel}
                    />
                </>
            )}
        </>
    );
};

export default ListWorkflowCategories;
