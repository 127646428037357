import { useEffect, useState, useRef } from "react";
import { getWorfklowCategories, getWorkflowTemplates } from "../../common/workflow";
import { Button, Form, FormGroup, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import BpmnModeler from "../bpmn/BpmnModeler";
import { createWorkflow, updateWorkflow, getAllWorkflowData } from "../../common/workflow";
import TaskSettings from "./TaskSettings";
import BPMNInstructions from "./BPMNInstructions";

const UPDATE = "update";
const CREATE = "create";
const COPY = "copy";

const WorkflowCRUD = (props) => {
    const childRef = useRef(null);
    const { workflowId, inputModel } = props;

    const [mode, setMode] = useState(CREATE);
    const [show, setShow] = useState(false);
    const [workflowCategories, setWorkflowCategories] = useState([]);
    const [workflowTemplates, setWorkflowTemplates] = useState([]);

    const [form, setForm] = useState({
        workflowname: inputModel ? inputModel.name : "",
        workflowdescription: inputModel ? inputModel.description : "",
        workflowistemplate: inputModel ? inputModel.is_template : false,
        tasks: {},
        settings: {},
        xml: inputModel ? inputModel.raw_diagram_data : "",
        category_id: inputModel ? inputModel.category_id : "",
    }); //initialization

    const [originalXml, setOriginalXml] = useState(inputModel ? inputModel.raw_diagram_data : {});

    const onUpdateCheckbox = (e) => {
        const nextFormState = {
            ...form,
            [e.target.name]: e.target.checked,
        };
        setForm(nextFormState);
    };

    const onUpdateField = (e) => {
        const nextFormState = {
            ...form,
            [e.target.name]: e.target.value,
        };
        setForm(nextFormState);
    };

    const implementTemplate = (e) => {
        const index = e.target.value;
        if (index === "") {
            setForm({
                workflowname: "",
                workflowdescription: "",
                workflowistemplate: false,
                tasks: {},
                settings: {},
                xml: "",
                category_id: "",
            });
            setOriginalXml({});
        } else if (index >= 0) {
            const templateWf = workflowTemplates[index];
            setForm({
                workflowname: templateWf.name,
                workflowdescription: templateWf.description,
                workflowistemplate: false,
                tasks: templateWf.tasks,
                settings: {},
                xml: "",
                category_id: templateWf.category_id ?? "",
            });
            setOriginalXml(templateWf.raw_diagram_data);
        }
    };

    const onSubmitForm = async (e) => {
        e.preventDefault();
        childRef.current.saveDiagramExport((xmlvalue) => {
            var data = JSON.stringify({
                name: form.workflowname,
                description: form.workflowdescription,
                is_template: form.workflowistemplate,
                tasks: {},
                settings: form.settings,
                raw_diagram_data: xmlvalue,
                category_id: form.category_id,
            });

            if (mode == COPY || mode == CREATE) {
                createWorkflow(data);
            } else {
                updateWorkflow(workflowId, data);
            }
        });
    };

    //get all module categories
    useEffect(() => {
        (async () => {
            setWorkflowCategories((await getWorfklowCategories())?.data ?? []);
            setWorkflowTemplates((await getWorkflowTemplates())?.data ?? []);

            if (workflowId) {
                setMode(UPDATE);

                let data = await getAllWorkflowData(workflowId);
                setOriginalXml(data.raw_diagram_data);
                setForm({
                    workflowname: data.name,
                    workflowdescription: data.description,
                    tasks: data.tasks,
                    settings: {},
                    xml: data.raw_diagram_data,
                    category_id: data.category_id ?? "",
                    workflowistemplate: data.is_template,
                });
            }
        })();

        if (inputModel) {
            setMode(COPY);
        }
    }, []);

    return (
        <>
            {mode == UPDATE && <h2 className="text-center mb-5">Edit Workflow</h2>}

            <Form onSubmit={onSubmitForm}>
                <FormGroup>
                    <Label>Name</Label>
                    <Input
                        type="text"
                        name="workflowname"
                        value={form.workflowname}
                        placeholder="Enter Workflow name"
                        onChange={onUpdateField}
                        disabled={mode == UPDATE}
                    />
                </FormGroup>
                <FormGroup>
                    <Label>Description</Label>
                    <Input
                        type="text"
                        name="workflowdescription"
                        value={form.workflowdescription}
                        placeholder="Enter a description for the workflow"
                        onChange={onUpdateField}
                    />
                </FormGroup>
                <FormGroup check className="mb-3">
                    <Input
                        type="checkbox"
                        name="workflowistemplate"
                        checked={form.workflowistemplate}
                        onChange={onUpdateCheckbox}
                    />
                    <Label check>Choose if the workflow is a template</Label>
                </FormGroup>
                {!form.workflowistemplate && (
                    <FormGroup>
                        <Label for="wfTemplateToUse">Select a template</Label>
                        <Input
                            id="wfTemplateToUse"
                            name="wfTemplateToUse"
                            type="select"
                            defaultValue=""
                            onChange={implementTemplate}
                        >
                            <option value="">No Template</option>

                            {workflowTemplates &&
                                workflowTemplates.map((wf, index) => (
                                    <option key={wf.id} value={index}>
                                        {wf.name}
                                    </option>
                                ))}
                        </Input>
                    </FormGroup>
                )}
                <FormGroup>
                    <Label for="category_id">Select a category</Label>
                    <Input
                        id="category_id"
                        name="category_id"
                        value={form.category_id}
                        type="select"
                        onChange={onUpdateField}
                    >
                        <option value="" disabled hidden>
                            Choose here
                        </option>

                        {workflowCategories &&
                            workflowCategories.map((category) => (
                                <option key={category.id} value={category.id}>
                                    {category.name}
                                </option>
                            ))}
                    </Input>
                </FormGroup>
                <FormGroup>
                    <p>
                        For instructions on how to choose the right BPMN element, refer{" "}
                        <a className="font-weight-bold font-italic" onClick={() => setShow(true)}>
                            here
                        </a>
                        .
                    </p>
                </FormGroup>
                <FormGroup>
                    <BpmnModeler ref={childRef} container="createContainer" initialXml={originalXml} />
                </FormGroup>

                {mode === UPDATE && <TaskSettings tasks={form.tasks} settings={form.settings} />}

                <Button type="submit">{mode == COPY || mode == CREATE ? <>CREATE</> : <>EDIT</>}</Button>
            </Form>
            <Modal isOpen={show}>
                <ModalHeader>BPMN diagram instructions</ModalHeader>
                <ModalBody>
                    <BPMNInstructions />
                </ModalBody>
                <ModalFooter>
                    <Button variant="secondary" onClick={() => setShow(false)}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default WorkflowCRUD;
