import React, { useEffect, useState } from "react";
import CustomDataTable from "../../components/datatable";
import { getWorkflowTemplates } from "../../common/workflow";
import { Badge, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from "reactstrap";
import { MoreVertical, Edit, FileText, Archive, Trash, X } from "react-feather";
import DeleteWorkflowModal from "./Modals/DeleteWorkflow";

const ListWorkflowTemplates = () => {
    const [workflowList, setWorkflowList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalDataCount, setTotalDataCount] = useState(0);

    const columns = [
        {
            name: "Name",
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: "Description",
            selector: (row) => row.description,
            sortable: false,
        },
        {
            name: "Created At",
            cell: (row) => <>{row.created_at.split("T")[0]}</>,
            sortable: (row) => row.created_at,
        },
        {
            name: "Updated At",
            cell: (row) => <>{row.updated_at.split("T")[0]}</>,
            sortable: (row) => row.updated_at,
        },
        {
            name: "Actions",
            width: "300px",
            cell: (row) => {
                const Child = () => {
                    return (
                        <>
                            <DeleteWorkflowModal
                                workflowid={row.id}
                                workflowname={row.name}
                                modaltitle="Delete"
                                buttoncolor="danger"
                                modalheader="Delete Workflow"
                                modalbody="Are you sure that you would like to delete the workflow with the name: "
                                rightbutton="Delete"
                                callback={() => getWorkflowsCallback()}

                                // deleteWorkflow={deleteWorkflow}
                            />
                            <UncontrolledDropdown>
                                <DropdownToggle className="pe-1" tag="span">
                                    <MoreVertical size={15} />
                                </DropdownToggle>
                                <DropdownMenu end>
                                    <DropdownItem tag="a" className="w-100" href={"editworkflow/" + row.id}>
                                        <Archive size={15} />
                                        <span className="align-middle ms-50"> Edit Workflow</span>
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </>
                    );
                };
                return <Child />;
            },
            sortable: false,
        },
    ];

    async function getWorkflowsCallback() {
        setLoading(true);

        try {
            const workflows = await getWorkflowTemplates(0, 10);
            setTotalDataCount(workflows.count);
            setWorkflowList(workflows.data);

            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        (async () => {
            await getWorkflowsCallback();
        })();
    }, []);

    return (
        <>
            {loading ? (
                <>
                    <div className="loader-container">
                        <div className="spinner" style={{ marginLeft: "-50%", marginTop: "-10%" }}></div>
                    </div>
                </>
            ) : (
                <>
                    <CustomDataTable
                        data={workflowList}
                        columns={columns}
                        tableName={"Template Workflows"}
                        hasServerPagination={true}
                        getDataCallback={getWorkflowTemplates}
                        totalDataCount={totalDataCount}
                    />
                </>
            )}
        </>
    );
};

export default ListWorkflowTemplates;
