import React, { useState, useContext } from "react";
import { ListGroupItem, Form, FormGroup, Label, Input, Button, Container } from "reactstrap";
import api from "../../utils/api";
import { ObjectSelectedContext } from "../../contexts/datalake/objectstorage";
import { store } from "../../redux";

const FreeTextSearch = () => {
    const [searchValue, setSearchValue] = useState("");
    const [searchResults, setSearchResults] = useState(undefined);
    const { objectSelected, setObjectSelected } = useContext(ObjectSelectedContext);

    const [loading, setLoading] = useState(false);

    function selectWsID(state) {
        return state.localStore.wsid;
    }

    const filterResults = async () => {
        let files = await api.v1.getFile(searchValue);
        if (files.data) {
            let _searchResults = [...files.data];
            const promises = _searchResults.map(async (element, i) => {
                // let objectInfo;
                // try {
                //     objectInfo = await api.v1.getObjectStorageObjectInfo(
                //         element.info?.bucket_name || "",
                //         element.info?.object_name || ""
                //     );
                // } catch {
                //     objectInfo = undefined;
                // }
                // if (!objectInfo.data || objectInfo == undefined) {
                //     return i;
                // }
                return -1;
            });

            const result = await Promise.all(promises);
            result.forEach((index) => {
                if (index !== -1) {
                    _searchResults.splice(index, 1);
                }
            });
            setSearchResults(_searchResults);
        }
    };

    const handleSearchChange = (e) => {
        setSearchValue(e.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            await filterResults();
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const parseObjectName = (fullPathName) => {
        const objects = fullPathName.split("/");
        const len = objects.length;

        if (objects[len - 1] === "") {
            return objects[len - 2];
        } else {
            return objects[len - 1];
        }
    };

    const selectObject = (element) => {
        if (!isObjectSelected(element.info)) {
            let obj = {
                bucket_name: element.info?.bucket_name || "",
                object_name: element.info?.object_name || "",
                ws_id: selectWsID(store.getState()),
                group_name: "",
            };
            setObjectSelected((oldArray) => [...oldArray, obj]);
        }
    };

    function isObjectSelected(element) {
        let isSelected = false;

        objectSelected.every((object) => {
            if (object.bucket_name === element.bucket_name && object.object_name === element.object_name) {
                isSelected = true;
                return false;
            }
            return true;
        });

        return isSelected;
    }

    return (
        <>
            {loading ? (
                <>
                    <div className="loader-container">
                        <div className="spinner" style={{ marginLeft: "-50%", marginTop: "-10%" }}></div>
                    </div>
                </>
            ) : (
                <>
                    <Container fluid>
                        <Form onSubmit={handleSubmit}>
                            <FormGroup className="d-flex flex-column">
                                <Label for="searchInput">Search</Label>
                                <div className="d-flex">
                                    <Input
                                        type="text"
                                        name="searchInput"
                                        id="searchInput"
                                        placeholder="Search for datasets created inside QueryBuilder"
                                        value={searchValue}
                                        onChange={handleSearchChange}
                                    />
                                    <Button type="submit" color="primary" style={{ height: "38px" }}>
                                        Search
                                    </Button>
                                </div>
                            </FormGroup>
                        </Form>
                        {searchResults ? (
                            searchResults.map((searchResult) => {
                                return (
                                    <ListGroupItem key={searchResult.id} className="card">
                                        {parseObjectName(searchResult.name || searchResult.info?.object_name || "")}
                                        <Button
                                            color="info"
                                            onClick={() => {
                                                selectObject(searchResult);
                                            }}
                                        >
                                            Select Object
                                        </Button>
                                    </ListGroupItem>
                                );
                            })
                        ) : (
                            <h2>No results found...</h2>
                        )}
                    </Container>
                </>
            )}
        </>
    );
};

export default FreeTextSearch;
