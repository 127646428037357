import React, { useState, useEffect } from "react";
import { Form, Button } from "reactstrap";
import "../assets/css/login.css";
import { useLocation, useSearchParams } from "react-router-dom";

import { useDispatch } from "react-redux";
import { login } from "../redux/auth/auth-actions";
import { setPrevPathAction, setWsIDAction } from "../redux/token/localstore-actions";

const Login = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const location = useLocation();
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const handleLogin = () => {
        setLoading(true);

        dispatch(
            login(() => {
                setLoading(false);
            })
        );
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        handleLogin();
    };

    useEffect(() => {
        dispatch(setPrevPathAction(`${location.pathname}${location.search}`));
        if (searchParams.get("wsid") !== null && searchParams.get("wsid") !== "") {
            dispatch(setWsIDAction(searchParams.get("wsid") ?? 27));
        }
    }, []);

    return (
        <>
            {loading ? (
                <>
                    <div className="loader-container">
                        <div className="spinner" style={{ marginLeft: "-50%", marginTop: "-10%" }}></div>
                    </div>
                </>
            ) : (
                <Form className="login-form" onSubmit={handleSubmit}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                            style={{ margin: "auto", marginBottom: "20px" }}
                            src={process.env.PUBLIC_URL + "/assets/logo.png"}
                            alt="logo"
                        />
                    </div>
                    <div>
                        <Button className="login-button" color="primary" style={{ margin: "0" }}>
                            Login
                        </Button>
                    </div>
                </Form>
            )}
        </>
    );
};

export default Login;
