import React, { useEffect } from "react";
import Task from "./Task";
import { useState } from "react";

//A state variable tasks that will store tasks and can be accessible from different components.
//Use this variable(Tasks) to store tasks retrieved from a database or a file.

function Tasks(props, { onTglStatus }) {
    //helper tasks bel
    console.log(props.tasks);
    return (
        <>
            {Object.keys(props.tasks).map((item, i) =>
                props.tasks[item].name != null ? (
                    <>
                        <div className="card" key={i}>
                            <div className="row">
                                <Task task={props.tasks[item]} onTglStatus={onTglStatus} />
                            </div>
                        </div>
                    </>
                ) : (
                    <> </>
                )
            )}
        </>
    );
}

export default Tasks;
