import React from "react";
import { Navbar, NavbarBrand, Nav, NavItem } from "reactstrap";
import { Link } from "react-router-dom";

const NavbarComponent = () => {
    return (
        <Navbar expand="md" fixed="top">
            {/* <NavbarBrand href="/">My App</NavbarBrand> */}
            <Nav className="ml-auto" navbar>
                <NavItem>
                    <Link className="nav-link" to="/logout">
                        Logout
                    </Link>
                </NavItem>
            </Nav>
        </Navbar>
    );
};

export default NavbarComponent;
