/**
 * @typedef {Object} WorkspaceURLs
 * @property {Object} WorkspaceURLs.v1 - v1 urls
 * @property {string} WorkspaceURLs.v1.workspaceUser - workspace/user
 */

/**
 * Get workspace endpoint urls
 * @returns {WorkspaceURLs} endpoints
 */
const workspace_eps = (function WorkspaceURLs() {
    return {
        v1: {
            /**
             * @returns {string}
             */
            workspaceUser: () => `workspace/user`,
        },
    };
})();

export default workspace_eps;
