import { React, useEffect, useState } from "react";
import { Card, CardBody, CardTitle, CardText, Button } from "reactstrap";
import TaskSettings from "../../components/workflowCRUD/TaskSettings";

import { getAllWorkflowData, getWorkflowByName } from "../../common/workflow";

export default ({ id, name, callback, workflow_id }) => {
    const [tasks, setTasks] = useState({});
    const [childWorkflowID, setChildWorkflowID] = useState("");
    const settings = {};

    async function submit() {
        callback(id, {
            parent_workflow_id: workflow_id,
            workflow_id: childWorkflowID,
            settings,
            name: new Date().toISOString(),
        });
    }

    useEffect(() => {
        (async () => {
            const workflow = await getWorkflowByName(name);

            if (workflow?.id) {
                setChildWorkflowID(workflow.id);
                let data = await getAllWorkflowData(workflow.id);

                setTasks(data.tasks);
            }
        })();
    }, []);

    return (
        <>
            <Card>
                <CardBody>
                    <CardTitle tag="h3">Execute "{name}" workflow</CardTitle>

                    <TaskSettings tasks={tasks} settings={settings} />

                    {childWorkflowID != "" ? (
                        <>
                            <CardText>Press button to execute this function</CardText>
                            <Button color="success" onClick={submit}>
                                Execute
                            </Button>
                        </>
                    ) : (
                        <>
                            <CardText>Finding workflow...</CardText>
                        </>
                    )}
                </CardBody>
            </Card>
        </>
    );
};
