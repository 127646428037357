import { AlertHelper } from "./alertHelper";

export async function getAllGeneric(api, params = null) {
    try {
        let response = {};
        if (params == null) {
            response = await api();
        } else {
            response = await api(params);
        }

        return {
            data: response.data.data,
            paging: response.data.paging,
            count: response.data.count,
        };
    } catch (error) {
        AlertHelper.showErrorAlert("Hmmm", error?.response?.data?.details ?? "Something Went Wrong. Please try again!");
    }
}

export async function postGeneric(api, data) {
    try {
        const response = await api(data);

        AlertHelper.showSuccessAlert("Good job!", "Resource Created!");

        return response;
    } catch (error) {
        AlertHelper.showErrorAlert("Hmmm", error?.response?.data?.details ?? "Something Went Wrong. Please try again!");

        return error;
    }
}

export async function putGeneric(api, id, data) {
    try {
        await api(id, data);

        AlertHelper.showSuccessAlert("Good job!", "Resource Updated!");
    } catch (error) {
        AlertHelper.showErrorAlert("Hmmm", error?.response?.data?.details ?? "Something Went Wrong. Please try again!");
    }
}

export async function deleteGeneric(api, id) {
    try {
        await api(id);

        AlertHelper.showSuccessAlert("Good job!", "Resource Deleted!");
    } catch (error) {
        AlertHelper.showErrorAlert("Hmmm", error?.response?.data?.details ?? "Something Went Wrong. Please try again!");
    }
}
