import React, { useEffect, useState } from "react";
import { Button, Row, Col } from "reactstrap";
import { INSTRUCTIONS, INSTRUCTIONS_FOR_WIZARD } from "./utils/enum";
import TextAreaParams from "./components/TextAreaParams";
import UserInputFields from "./components/UserInputFields";
import DataObjectMapFormWizard from "./components/DataObjectMapFormWizard";
import TaskReferenceForm from "./components/TaskReferenceForm";

const InstructionsWizard = ({
    instructions,
    callback,
    objectStorageDataReferences = [],
    dataStorageDataReferences = [],
    previousCompletedTaskSelected = [],
}) => {
    const [formStepName, setFormStepName] = useState("");
    const [formStep, setFormStep] = useState(0);
    const [formLength, setFormLength] = useState(0);
    const [formObject, setFormObject] = useState([]);
    const [formData, setFormData] = useState({});
    const [showNext, setShowNext] = useState(false);

    useEffect(() => {
        if (Object.keys(instructions).length > 0) {
            let _formLength = 0;
            let _formObject = [];

            for (let instruction in instructions) {
                if (instruction in INSTRUCTIONS_FOR_WIZARD) {
                    if (instruction == INSTRUCTIONS.num_of_files) {
                        if (instructions[instruction] === 0) {
                            continue;
                        }
                        _formObject.push({
                            [instruction]: {
                                instruction: instructions[instruction],
                                [INSTRUCTIONS.file_types]: instructions[INSTRUCTIONS.file_types] ?? [],
                                [INSTRUCTIONS.data_input_labels]: instructions[INSTRUCTIONS.data_input_labels] ?? [],
                            },
                        });
                    } else {
                        _formObject.push({ [instruction]: instructions[instruction] });
                    }

                    _formLength++;
                }
            }

            if (_formObject.length > 0) {
                setFormStepName(Object.keys(_formObject[0])[0]);
            }
            setFormLength(_formLength);
            setFormObject(_formObject);

            setFormData((oldObj) => {
                return { ...oldObj };
            });
        }
    }, [instructions]);

    function goNext() {
        let newStep = formStep;
        newStep += 1;
        setFormStep(newStep);
        setShowNext(false);
        if (newStep < formLength) {
            setFormStepName(Object.keys(formObject[newStep])[0]);
        }
    }

    function goBack() {
        if (formStep > 0) {
            let newStep = formStep;
            newStep -= 1;
            setFormStep(newStep);
        }
        setShowNext(false);
    }

    function updateFormData(obj) {
        setFormData((old) => {
            return { ...old, ...obj };
        });
        setShowNext(true);
    }

    function submit() {
        callback(formData);
    }

    return (
        <>
            <Row>
                <Col>
                    {formStep >= 0 && formStep < formLength ? (
                        <>
                            {formStepName == INSTRUCTIONS.num_of_files && (
                                <DataObjectMapFormWizard
                                    num_of_files={formObject[formStep][INSTRUCTIONS.num_of_files].instruction}
                                    labelList={
                                        formObject[formStep][INSTRUCTIONS.num_of_files][INSTRUCTIONS.data_input_labels]
                                    }
                                    fileTypes={formObject[formStep][INSTRUCTIONS.num_of_files][INSTRUCTIONS.file_types]}
                                    objectStorageDataReferences={objectStorageDataReferences}
                                    dataStorageDataReferences={dataStorageDataReferences}
                                    callback={updateFormData}
                                    showProceed={true}
                                />
                            )}
                            {formStepName == INSTRUCTIONS.user_input && (
                                <UserInputFields
                                    fields={formObject[formStep][INSTRUCTIONS.user_input]}
                                    callback={updateFormData}
                                />
                            )}
                            {formStepName == INSTRUCTIONS.params && (
                                <TextAreaParams
                                    params={formObject[formStep][INSTRUCTIONS.params]}
                                    callback={updateFormData}
                                />
                            )}
                            {formStepName == INSTRUCTIONS.ref_completed_task && (
                                <TaskReferenceForm
                                    previousCompletedTaskSelected={previousCompletedTaskSelected}
                                    callback={updateFormData}
                                />
                            )}
                        </>
                    ) : (
                        <></>
                    )}
                    {formStep > 0 && formStep == formLength && <>Click "Proceed", or make changes by going "Back"</>}
                </Col>
            </Row>
            <Row className="mt-3">
                <Col>
                    {formStep > 0 && (
                        <Button color="danger" onClick={goBack}>
                            Back
                        </Button>
                    )}
                    {showNext && formStep < formLength && (
                        <Button color="primary" onClick={goNext}>
                            next
                        </Button>
                    )}
                    {formStep > 0 && formStep == formLength && (
                        <Button color="success" onClick={submit}>
                            Proceed
                        </Button>
                    )}
                </Col>
            </Row>
        </>
    );
};

export default InstructionsWizard;
