import api from "../utils/api";
import { AlertHelper } from "../utils/alertHelper";

export async function getUserWorkspaces() {
    try {
        return (await api.v1.getUserWorkspaces()).data;
    } catch (error) {
        AlertHelper.showErrorAlert("Hmmm", error?.response?.data?.details ?? "Something Went Wrong. Please try again!");
    }
}
