import { BrowserRouter, Routes, Route } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import AppLayout from "../layouts/AppLayout";
import LoginLayout from "../layouts/LoginLayout";
import Dahsboard from "../views/DashBoard";
import ListWorkflows from "../views/Workflows/ListWorkflowsV2";
import ListWorkflowTemplates from "../views/Workflows/ListWorkflowTemplates";
import CreateWorkflow from "../views/Workflows/CreateWorkflowV2";
import DeletedWorkflows from "../views/Workflows/ListDeletedWorkflows";
import InitRunWorkflow from "../views/Workflows/InitRunWorkflow";
import Steps from "../views/Steps";
import Runs from "../views/Runs/";
import ViewWorkflowRuns from "../views/Runs/ViewWorkflowRuns";
// import ObjectStorage from "../views/ObjectStorage/Buckets"
// import BucketObjects from "../views/ObjectStorage/BucketObjects"
import Bpmn from "../components/bpmn/Bpmn";
import ListCategories from "../views/Categories";
import ListModules from "../views/Modules";
import ListFeatures from "../views/Features";
import ListVariables from "../views/Variables";
import ListWorkflowCategories from "../views/Wf_Categories";
import CopyWorkflow from "../views/Workflows/CopyWorkflow";
import MoveWorkflow from "../views/Workflows/MoveWorkflow";
import EditWorkflowV2 from "../views/Workflows/EditWorkflowV2";
import Login from "../views/login";
import Logout from "../views/Logout";
import LoginCallback from "../views/LoginCallback";
import RunWorkflow from "../views/Workflows/RunWorkflow";
import ListRuns from "../views/Workflows/ListRuns";

export const routes = [
    {
        path: "/createworkflow",
        title: "Create Workflow",
        component: CreateWorkflow,
        icon: <i className="bx bx-shuffle"></i>,
        exportToSidebar: true,
    },
    {
        path: "/listworkflows",
        title: "List of Workflows",
        component: ListWorkflows,
        icon: <i className="bx bx-list-minus"></i>,
        exportToSidebar: true,
    },
    {
        path: "/run",
        title: "Run Workflows",
        component: ListRuns,
        icon: <i className="bx bx-list-minus"></i>,
        exportToSidebar: true,
    },
    {
        path: "/listtemplateworkflows",
        title: "List of Templates",
        component: ListWorkflowTemplates,
        icon: <i className="bx bx-list-minus"></i>,
        exportToSidebar: true,
    },
    {
        path: "/listdeletedworkflows",
        title: "Deleted Workflows",
        component: DeletedWorkflows,
        icon: <i className="bx bx-trash"></i>,
        exportToSidebar: true,
    },
    {
        path: "/listworkflowcategories",
        title: "Workflow Categories",
        component: ListWorkflowCategories,
        icon: <i className="bx bx-trash"></i>,
        exportToSidebar: true,
    },
    {
        path: "/listmodules",
        title: "Modules",
        component: ListModules,
        icon: <i className="bx bx-list-minus"></i>,
        exportToSidebar: true,
    },
    {
        path: "/copyworkflow/:workflowid",
        title: "Copy Workflow",
        component: CopyWorkflow,
        icon: <i className="bx bx-shuffle"></i>,
        exportToSidebar: false,
    },
    {
        path: "/moveworkflow/:workflowid",
        title: "Move Workflow",
        component: MoveWorkflow,
        icon: <i className="bx bx-shuffle"></i>,
        exportToSidebar: false,
    },
    {
        path: "/editworkflow/:workflowid",
        title: "Edit Workflow",
        component: EditWorkflowV2,
        icon: <i className="bx bx-shuffle"></i>,
        exportToSidebar: false,
    },
    {
        path: "/steps/:workflowid",
        title: "Steps",
        component: Steps,
        // icon: <i className='bx bx-trash'></i>,
        exportToSidebar: false,
    },
    {
        path: "/runs/:workflowid",
        title: "Initialize a Workflow run",
        component: InitRunWorkflow,
        // icon: <i className='bx bx-trash'></i>,
        exportToSidebar: false,
    },
    {
        path: "/workflow/:workflowid/run/:run_id",
        title: "Runs",
        component: Runs,
        // icon: <i className='bx bx-trash'></i>,
        exportToSidebar: false,
    },
    {
        path: "/workflowruns/:workflowid",
        title: "View Workflow Runs",
        component: ViewWorkflowRuns,
        // icon: <i className='bx bx-trash'></i>,
        exportToSidebar: false,
    },
    {
        path: "/run/newrun",
        title: "Run Workflow",
        component: RunWorkflow,
        exportToSidebar: false,
    },
    {
        path: '/run/:run_id',
        title: 'Run Workflow',
        component: RunWorkflow,
        exportToSidebar: false
    },
    {
        path: "/logout",
        title: "Logout",
        component: Logout,
        exportToSidebar: false,
    },
];

const AppRouting = () => {
    const isAuthenticated = useAuth(); // useAuth is a hook that returns whether the user is authenticated or not

    return (
        <>
            <BrowserRouter>
                <Routes>
                    {isAuthenticated ? (
                        <Route path="/" element={<AppLayout />}>
                            <Route index element={<Dahsboard />} />
                            {routes.map((route, key) => {
                                return <Route key={key} path={route.path} element={<route.component />} />;
                            })}
                            <Route path="*" element={<Dahsboard />} />
                        </Route>
                    ) : (
                        <Route path="/" element={<LoginLayout />}>
                            <Route index element={<Login />} />
                            <Route path="/auth/callback/:user/:token/:actual_token/:refresh_token" element={<LoginCallback />} />
                            <Route path="*" element={<Login />} />

                            {/* <Route path="/login" element={<Login />} /> */}
                        </Route>
                    )}
                </Routes>
            </BrowserRouter>
        </>
    );
};

export default AppRouting;
