import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "../../assets/css/Sidebar.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { routes } from "../../routes";

const Sidebar = () => {
    const routesToSidebar = [...routes.filter((route) => route.exportToSidebar == true)];
    let sidebarNavItems = [
        {
            display: "Dahsboard",
            icon: <i className="bx bx-home"></i>,
            to: "/",
            section: "",
        },
    ];

    routesToSidebar.forEach((route) => {
        let temp = {
            display: route.title,
            icon: route.icon,
            to: route.path,
            section: route.path.toLowerCase().replace("/", ""),
        };
        sidebarNavItems.push(temp);
    });

    const [activeIndex, setActiveIndex] = useState(0);
    const [stepHeight, setStepHeight] = useState(0);
    const sidebarRef = useRef();
    const indicatorRef = useRef();
    const location = useLocation();

    useEffect(() => {
        setTimeout(() => {
            const sidebarItem = sidebarRef.current.querySelector(".sidebar__menu__item");
            indicatorRef.current.style.height = `${sidebarItem.clientHeight}px`;
            setStepHeight(sidebarItem.clientHeight);
        }, 50);
    }, []);

    // change active index
    useEffect(() => {
        const curPath = window.location.pathname.split("/")[1];
        const activeItem = sidebarNavItems.findIndex((item) => item.section === curPath);
        if (activeItem >= 0) setActiveIndex(curPath.length === 0 ? 0 : activeItem);
    }, [location]);

    return (
        <div className="sidebar">
            <div style={{ paddingLeft: 90, paddingTop: 50 }}>
                <img src={process.env.PUBLIC_URL + "/assets/logo.png"} alt="logo" />
            </div>
            <div className="sidebar__logo">Expert System</div>
            <div ref={sidebarRef} className="sidebar__menu" style={{overflowY: "auto", maxHeight: '60vh'}}>
                <div
                    ref={indicatorRef}
                    className="sidebar__menu__indicator"
                    style={{
                        transform: `translateX(-50%) translateY(${activeIndex * stepHeight}px)`,
                    }}
                ></div>
                {sidebarNavItems.map((item, index) => (
                    <Link to={item.to} key={index}>
                        <div
                            className={`sidebar__menu__item ${activeIndex === index ? "active" : ""}`}
                            style={{ whiteSpace: "nowrap" }}
                        >
                            <div className="sidebar__menu__item__icon">{item.icon}</div>
                            <div className="sidebar__menu__item__text">{item.display}</div>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default Sidebar;
