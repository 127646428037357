import axios from 'axios';
import { store } from "../redux";

export class AuthService{
    api = axios.create({
        baseURL: process.env.REACT_APP_API_LINK,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'x-es-token': store.getState().localStore.token,
            'x-es-wsid': store.getState().localStore.wsid,
            'x-jwt-token': localStorage.getItem('es_actual_token'),
            'x-refresh-token': localStorage.getItem('es_refresh_token'),
        },
    });
    
    async refresh_token(){
        try{
            if(localStorage.getItem('es_refresh_token')){
                const response = await this.api.get('/oauth/refresh', {
                    headers: {
                        'x-es-token': store.getState().localStore.token,
                        'x-es-wsid': store.getState().localStore.wsid,
                        'x-jwt-token': localStorage.getItem('es_actual_token'),
                        'x-refresh-token': localStorage.getItem('es_refresh_token'),
                    }
                });
                if(response.status !== 200){
                    window.location = '/logout';
                }
                else{
                    localStorage.setItem('es_actual_token', response.data.token.access_token);
                    localStorage.setItem('es_refresh_token', response.data.token.refresh_token);
                    return response.data;
                }
            }
            else{
                console.log("I got here")
                window.location = '/logout';
            }
        }catch(e){
            console.log(e);
        }
    }

    async logout(){
        
    }
}