import { createContext, useState } from "react";

export const ObjectSelectedContext = createContext();

const ObjectSelectedProvider = ({ children }) => {
    const [objectSelected, setObjectSelected] = useState([]);

    return (
        <ObjectSelectedContext.Provider
            value={{
                objectSelected,
                setObjectSelected,
            }}
        >
            {children}
        </ObjectSelectedContext.Provider>
    );
};

export default ObjectSelectedProvider;
