// ** React Imports
import React, { useState, useEffect } from "react";

// ** Third Party Components
import { User, Briefcase, Mail, Calendar, DollarSign, X } from "react-feather";

// ** Reactstrap Imports
import {
    Modal,
    Input,
    Label,
    Button,
    ModalHeader,
    ModalBody,
    InputGroup,
    InputGroupText,
    ModalFooter,
} from "reactstrap";

import modalTypes from "../../utils/modalTypes";
import { AlertHelper } from "../../utils/alertHelper";

const AddNewModal = ({ open, handleModal, type, Model, title, inputModel, closeModalCallback }) => {
    // ** State
    // ** Custom close btn
    const CloseBtn = (
        <X
            className="cursor-pointer"
            size={15}
            onClick={() => {
                handleModal();
                closeModalCallback();
            }}
        />
    );

    useEffect(() => {
        // Update the document title using the browser API
        // console.log(model)
    });

    const modalRenderer = (Body, className) => {
        return (
            <Modal
                isOpen={open}
                toggle={handleModal}
                className="sidebar-sm"
                modalClassName={className}
                contentClassName="pt-0"
            >
                <ModalHeader className="mb-1" toggle={handleModal} close={CloseBtn} tag="div">
                    <h5 className="modal-title">{title}</h5>
                </ModalHeader>
                <ModalBody className="flex-grow-1">
                    <Body />
                </ModalBody>
            </Modal>
        );
    };

    var Body;
    switch (type) {
        case modalTypes.CREATE:
            Body = () => {
                return (
                    <React.Fragment>
                        <Model />
                        {/* <ModalFooter>
              <Button className='me-1'
                color='primary'
                onClick={dummy}
              >
                Submit
              </Button>
              <Button color='secondary'
                // onClick={handleModal}
                outline
              >
                Cancel
              </Button>
            </ModalFooter> */}
                    </React.Fragment>
                );
            };
            return modalRenderer(Body, "modal-slide-in");

        case modalTypes.UPDATE:
            Body = () => {
                return (
                    <React.Fragment>
                        <Model inputValues={inputModel} />
                    </React.Fragment>
                );
            };
            return modalRenderer(Body, "modal-slide-in");

        case modalTypes.DELETE:
            Body = () => {
                return (
                    <React.Fragment>
                        <Model inputValues={inputModel} isDeleteMode={true} />
                    </React.Fragment>
                );
            };
            return modalRenderer(Body, "modal-slide-in");
    }
};

export default AddNewModal;
