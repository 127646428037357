import BaseAxios from "axios";
import { store } from "../redux";
import { setPrevPathAction } from "../redux/token/localstore-actions";
import endpoints from "./endpoints";

export const axios_v1 = (() => {
    function selectToken(state) {
        return state.localStore.token;
    }
    function selectWsID(state) {
        return state.localStore.wsid;
    }

    const axios = BaseAxios.create({
        baseURL: endpoints.v1.base,
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
    });

    store.subscribe(() => {
        let token = selectToken(store.getState());
        let wsid = selectWsID(store.getState());
        axios.defaults.headers.common["X-ES-Token"] = token;
        axios.defaults.headers.common["X-ES-WSID"] = wsid;
    });

    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error.response.status == 401) {
                store.dispatch(setPrevPathAction(window.location.pathname));
                window.location.assign("/logout");
            } else {
                return Promise.reject(error);
            }
        }
    );

    return axios;
})();
