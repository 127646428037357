import React from "react";

const BPMNInstructions = () => {
    return (
        <>
            <h3>List of supported elements and their usage</h3>
            <h4>Events</h4>
            <p>
                Circles used to represent events. Events are something that "happens" during the course of a process.
                These can be divided into Start, Intermediate, and End events.
            </p>
            <h5>Start Event (StartEvent)</h5>
            <p>
                Indicated by a thin, single border circle, it marks the beginning of a process or sub-process. Optional
                markers within the circle can signify the trigger, such as a message or timer.
            </p>
            <h5>End Event (EndEvent)</h5>
            <p>
                Represented by a thick, single border circle, it signifies the end of a process or sub-process. It may
                have an optional marker that signifies the result of the event, such as a message being sent.
            </p>
            <h4>Gateways</h4>
            <p>
                Diamond shapes that are used to control the flow of interaction. They depict the branching, merging, and
                joining of paths within a process.
            </p>
            <h5>Exclusive Gateway (ExclusiveGateway)</h5>
            <p>Shown as an empty diamond shape, it is used for decision-making where only one path can be taken.</p>
            <h5>Parallel Gateway (ParallelGateway)</h5>
            <p>
                Depicted as a diamond shape with a plus ("+") sign, it is used to synchronize parallel flows and split a
                single flow into multiple concurrent flows.
            </p>
            <h4>Tasks</h4>
            <p>
                A rounded rectangle representing a single, atomic activity within the process. Tasks are units of work
                that cannot be broken down into smaller units without breaking the model's level of abstraction.
            </p>
            <h5>User Task (UserTask)</h5>
            <p>
                Represents a task that is manually performed by a human actor. Often represented with a small person
                icon. Used to retrieve files from the Data Lake, or manually review the variables that are identified
                during the execution of a given workflow.
            </p>
            <h5>Send Task (SendTask)</h5>
            <p>
                Represents a task whose primary purpose is to send a message. Depicted with an envelope icon. Used to
                start an AI subprocess.
            </p>
            <h5>Receive Task (ReceiveTask)</h5>
            <p>
                Represents a task that waits for a message to arrive. Also depicted with an envelope icon but may be
                visually differentiated from the Send Task. Used to wait for the response of a corresponding AI
                subprocess (it needs to be after a Send Task).
            </p>
            <h5>Manual Task (ManualTask)</h5>
            <p>
                Represents a task that is expected to be performed without the aid of any business process execution
                engine or any other automation. Usually shown with a hand icon. Used to display a message to the user
                and not interact with the system.
            </p>
            <h5>Script Task (ScriptTask)</h5>
            <p>
                Represents a task that is automated but where the service is a script or a program executed. Often
                depicted with a script icon. Used to call Data Analytic functions, or to launch Query Builder.
            </p>
            <h4>Associations</h4>
            <p>
                Dotted line used to associate artifacts and flow objects but does not show the sequence or flow of
                activities. Used to map an artifact (e.g., ScriptTask) with a system function using specific text codes.
            </p>
            <ul>
                <li>
                    If the user wants to execute a Data Analytic function, annotate a ScriptTask with the code
                    "dataanalytics" inside as text.
                </li>
                <li>
                    If the user wants to execute Query Builder, annotate a ScriptTask with the code "querybuilder"
                    inside as text.
                </li>
            </ul>
        </>
    );
};

export default BPMNInstructions;
