/**
 * @typedef {Object} RunURLs
 * @property {Object} RunURLs.v1 - v1 urls
 * @property {string} RunURLs.v1.runWorkflow - run/workflow/{workflow_id}
 * @property {string} RunURLs.v1.runID - run/{run_id}
 * @property {string} RunURLs.v1.runNext - run/{run_id}/next
 * @property {string} RunURLs.v1.runStep - run/{run_id}/step/{step_id}
 * @property {string} RunURLs.v1.runStepExclusive - run/{run_id}/step/{step_id}/exclusive/{next_step_id}
 * @property {string} RunURLs.v1.runStepParallel - run/{run_id}/step/{step_id}/parallel/{next_step_id}
 * @property {string} RunURLs.v1.runStepTaskExec - run/{run_id}/step/{step_id}/task/exec
 * @property {string} RunURLs.v1.runStepCallActivity - run/{run_id}/step/{step_id}/activity/call
 * @property {string} RunURLs.v1.runStepTaskComplete - run/{run_id}/step/{step_id}/task/complete
 * @property {string} RunURLs.v1.runCallActivityIsCompleted - run/{run_id}/activity/call/complete
 * @property {string} RunURLs.v1.runStepEvent - run/{run_id}/step/{step_id}/event
 * @property {string} RunURLs.v1.runTaskSend - run/{run_id}/step/{step_id}/task/send
 * @property {string} RunURLs.v1.runStepMetadata - run/{run_id}/step/{step_id}/metadata
 * @property {string} RunURLs.v1.listRunDataReferences - run/{run_id}/stores/dataobject
 * @property {string} RunURLs.v1.listRunDataStoreReferences - run/{run_id}/stores/datastore
 * @property {string} RunURLs.v1.listRunCompletedTasks - run/{run_id}/completed/tasks
 */

/**
 * Get workflow endpoint urls
 * @returns {RunURLs} endpoints
 */
const run_eps = (function RunURLs() {
    const BASE_RUN_STEP = (run_id, step_id) => `run/${run_id}/step/${step_id}`;

    return {
        v1: {
            /**
             * @param {string} workflow_id
             * @returns {string}
             */
            runWorkflow: (workflow_id) => `run/workflow/${workflow_id}`,
            /**
             * @param {string} run_id
             * @returns {string}
             */
            runID: (run_id) => `run/${run_id}`,
            /**
             * @param {string} run_id
             * @returns {string}
             */
            runNext: (run_id) => `run/${run_id}/next`,
            /**
             * @param {string} run_id
             * @param {string} step_id
             * @returns {string}
             */
            runStep: (run_id, step_id) => `${BASE_RUN_STEP(run_id, step_id)}`,
            /**
             * @param {string} run_id
             * @param {string} step_id
             * @param {string} next_step_id
             * @returns {string}
             */
            runStepExclusive: (run_id, step_id, next_step_id) =>
                `${BASE_RUN_STEP(run_id, step_id)}/exclusive/${next_step_id}`,
            /**
             * @param {string} run_id
             * @param {string} step_id
             * @param {string} next_step_id
             * @returns {string}
             */
            runStepParallel: (run_id, step_id, next_step_id) =>
                `${BASE_RUN_STEP(run_id, step_id)}/parallel/${next_step_id}`,
            /**
             * @param {string} run_id
             * @param {string} step_id
             * @returns {string}
             */
            runStepTaskExec: (run_id, step_id) => `${BASE_RUN_STEP(run_id, step_id)}/task/exec`,
            /**
             * @param {string} run_id
             * @param {string} step_id
             * @returns {string}
             */
            runStepCallActivity: (run_id, step_id) => `${BASE_RUN_STEP(run_id, step_id)}/activity/call`,
            /**
             * @param {string} run_id
             * @param {string} step_id
             * @returns {string}
             */
            runStepTaskComplete: (run_id, step_id) => `${BASE_RUN_STEP(run_id, step_id)}/task/complete`,
            /**
             * @param {string} run_id
             * @returns {string}
             */
            runCallActivityIsCompleted: (run_id) => `run/${run_id}/activity/call/complete`,
            /**
             * @param {string} run_id
             * @param {string} step_id
             * @returns {string}
             */
            runStepEvent: (run_id, step_id) => `${BASE_RUN_STEP(run_id, step_id)}/event`,
            /**
             * @returns {string}
             */
            runTaskSend: (run_id, step_id) => `${BASE_RUN_STEP(run_id, step_id)}/task/send`,
            /**
             * @param {string} run_id
             * @param {string} step_id
             * @returns {string}
             */
            runStepMetadata: (run_id, step_id) => `${BASE_RUN_STEP(run_id, step_id)}/metadata`,
            /**
             * @param {string} run_id
             * @returns {string}
             */
            listRunDataReferences: (run_id) => `run/${run_id}/stores/dataobject`,
            /**
             * @param {string} run_id
             * @returns {string}
             */
            listRunDataStoreReferences: (run_id) => `run/${run_id}/stores/datastore`,
            /**
             * @param {string} run_id
             * @returns {string}
             */
            listRunCompletedTasks: (run_id) => `run/${run_id}/completed/tasks`,
        },
    };
})();

export default run_eps;
