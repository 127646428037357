import endpoints from "../endpoints";
import { axios_v1 as axios } from "../axios";

/**
 * @typedef {Object} FeatureAPIs
 * @property {Object} FeatureAPIs.v1 - v1 api functions
 * @property {Promise} FeatureAPIs.v1.getFeatures - getFeatures()
 * @property {Promise} FeatureAPIs.v1.getFeature - getFeature(feature_id)
 * @property {Promise} FeatureAPIs.v1.postFeature - postFeature(data)
 * @property {Promise} FeatureAPIs.v1.putFeature - putFeature(feature_id, data)
 * @property {Promise} FeatureAPIs.v1.deleteFeature - deleteFeature(feature_id)
 */

/**
 * Get feature API functions
 * @returns {FeatureAPIs} api functions
 */
const feature_apis = (function FeatureAPIs() {
    return {
        v1: {
            /**
             * @returns {promise}
             */
            getFeatures: () => {
                return axios.get(endpoints.v1.features());
            },
            /**
             * @param {string} feature_id
             * @returns {promise}
             */
            getFeature: (feature_id) => {
                return axios.get(endpoints.v1.feature(feature_id));
            },
            /**
             * @param {object} data
             */
            postFeature: (data) => {
                return axios.post(endpoints.v1.features(), data);
            },
            /**
             * @param {string} feature_id
             * @param {object} data
             */
            putFeature: (feature_id, data) => {
                return axios.put(endpoints.v1.feature(feature_id), data);
            },
            /**
             * @param {string} feature_id
             * @returns {promise}
             */
            deleteFeature: (feature_id) => {
                return axios.delete(endpoints.v1.feature(feature_id));
            },
        },
    };
})();

export default feature_apis;
