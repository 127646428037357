function cleanSpaces(str) {
    return str.replace(/\s+/g, " ").replace(/\s*,\s*/g, ", ");
}

export function getQueryLabel(query) {
    // SELECT dummy_id,cdrsum,cdrglob,comport,cdrlang,bills,taxes,shopping,games,stove,mealprep,events,payattn,remdates FROM postgresql.public.test where taxes>0 AND bills>0
    query = query.toLowerCase();

    let variables = "";
    let clauses = "";

    const selectMatch = query.match(/select(.*?)from/i);
    if (selectMatch) {
        variables = `Variables: ${cleanSpaces(selectMatch[1].trim())}`;
    }

    const whereMatch = query.match(/where(.*)/i);
    if (whereMatch) {
        clauses = `Clauses: ${cleanSpaces(whereMatch[1].trim())}`;
    }

    return (
        <>
            <span style={{ display: "block", fontWeight: "bold" }}>{variables.trim()}</span>
            <span style={{ display: "block", fontWeight: "bold" }}>{clauses.trim()}</span>
        </>
    );
}
