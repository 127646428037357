import React from "react";
import { useState } from "react";

function TaskEdit(onSaveTask) {
    //description and tasktype on State
    const [taskname, setTaskName] = useState("");
    const [tasktype, setTaskType] = useState("");
    const [taskmanual, setTaskManual] = useState("");
    const [inputtask, setInputTask] = useState("");
    const [output, setOutputTask] = useState("");
    //saveTask implemented on Steps.jsx
    const saveTask = (e) => {
        e.preventDefault();
        // onSaveTask({ taskname: taskname, tasktype: tasktype, taskmanual:taskmanual  });
        console.log(taskmanual);
        setTaskName("");
        setTaskType("");
    };
    return (
        <div className="card">
            <h3>Add Task</h3>
            <form>
                <div className="col-md-12">
                    <label htmlFor="taskname">Task name</label>
                    <input
                        type="text"
                        name="taskname"
                        id="taskname"
                        value={taskname}
                        onChange={(e) => setTaskName(e.target.value)}
                    />
                </div>
                <div className="col-md-12">
                    <label htmlFor="tasktype">Task type</label>
                    <input
                        type="text"
                        name="tasktype"
                        id="tasktype"
                        value={tasktype}
                        onChange={(e) => setTaskType(e.target.value)}
                    />
                </div>
                <div className="col-md-12">
                    <label htmlFor="taskmanual">Manual task</label>
                    <input
                        type="checkbox"
                        name="taskmanual"
                        id="taskmanual"
                        value={taskmanual}
                        onChange={(e) => setTaskType(e.target.value)}
                    />
                </div>
                <div className="col-md-12">
                    <label htmlFor="inputtask">Input task</label>
                    <input
                        type="select"
                        name="inputtask"
                        id="inputtask"
                        value={inputtask}
                        onChange={(e) => setTaskType(e.target.value)}
                    />
                </div>
                <div className="col-md-12">
                    <label htmlFor="outputtask">Output task</label>
                    <input
                        type="select"
                        name="inputtask"
                        id="inputtask"
                        value={inputtask}
                        onChange={(e) => setTaskType(e.target.value)}
                    />
                </div>
                <div className="text-right">
                    <button className="btn button " onClick={saveTask}>
                        Save
                    </button>
                </div>
            </form>
        </div>
    );
}

export default TaskEdit;
