import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import { getUserWorkspaces } from "../../common/workspace";
import { updateWorkflowWorkspace } from "../../common/workflow";
import { store } from "../../redux";

const MoveWorkflow = () => {
    const navigate = useNavigate();
    const { workflowid } = useParams();
    const [workspaces, setWorkspaces] = useState([]);
    const [workspaceID, setWorkspaceID] = useState(0);

    function selectWsID(state) {
        return state.localStore.wsid;
    }

    async function onSubmitForm(e) {
        e.target.disabled = true;
        e.preventDefault();

        await updateWorkflowWorkspace(workflowid, workspaceID);
        navigate(`/listworkflows`);
    }

    function handleWorkspaceID(e) {
        const value = e.target.value;

        setWorkspaceID(parseInt(value));
    }

    useEffect(() => {
        (async () => {
            let data = await getUserWorkspaces();

            setWorkspaceID(selectWsID(store.getState()));
            setWorkspaces(data);
        })();
    }, []);

    return (
        <>
            <Form>
                <FormGroup>
                    <Label for={`workspace`}>Select a workspace</Label>
                    <Input
                        id={`workspace`}
                        name={`workspace`}
                        type="select"
                        value={workspaceID}
                        onChange={handleWorkspaceID}
                    >
                        <option value="0" disabled>
                            Choose here
                        </option>

                        {workspaces &&
                            workspaces.map((workspace) => (
                                <option key={workspace.ws_id} value={workspace.ws_id}>
                                    <>{workspace?.ws_name}</>
                                </option>
                            ))}
                    </Input>
                </FormGroup>
            </Form>

            <Button type="submit" onClick={onSubmitForm}>
                Move
            </Button>
        </>
    );
};

export default MoveWorkflow;
