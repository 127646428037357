import endpoints from "../endpoints";
import { axios_v1 as axios } from "../axios";

/**
 * @typedef {Object} WorkspaceAPIs
 * @property {Object} WorkspaceAPIs.v1 - v1 api functions
 * @property {Promise} WorkspaceAPIs.v1.getUserWorkspaces - getUserWorkspaces()
 */

/**
 * Get workspace API functions
 * @returns {WorkspaceAPIs} api functionss
 */
const workspace_api = (function WorkspaceAPIs() {
    return {
        v1: {
            /**
             * @returns {Promise}
             */
            getUserWorkspaces: () => axios.get(endpoints.v1.workspaceUser()),
        },
    };
})();

export default workspace_api;
