import { AlertHelper } from "../utils/alertHelper";
import api from "../utils/api";
import { getAllGeneric, postGeneric, putGeneric, deleteGeneric } from "../utils/crud";

async function ApiWrapper(response) {
    const pending = response.data?.pending;
    const queue = response.data?.queue ?? [];
    const steps = response.data?.steps ?? [];
    const state = response.data?.state ?? {};

    return [pending, queue, steps, state];
}

export function parseElementType(element) {
    if ("or" in element) {
        return "exclusive";
    } else if ("and" in element) {
        return "parallel";
    } else {
        return "single";
    }
}

export function getQueueFirst(queue) {
    let firstElement = queue[0];
    return {
        type: parseElementType(firstElement),
        data: firstElement,
    };
}

export async function getWorkflow(workflow_id) {
    const workflowResponse = await api.v1.getWorkflowByID(workflow_id);
    const workflowTaskTypesResponse = await api.v1.getWorkflowTaskTypes();

    const name = workflowResponse.data.name;
    const tasks = workflowResponse.data.tasks;
    const workflowStores = workflowResponse.data.stores;

    const taskTypes = {};
    for (const taskType in workflowTaskTypesResponse.data) {
        workflowTaskTypesResponse.data[taskType].forEach((name) => {
            taskTypes[name] = taskType;
        });
    }

    return [name, tasks, taskTypes, workflowStores];
}

export async function getWorkflowByName(name) {
    const workflowResponse = await api.v1.getWorkflowByName(name);

    return workflowResponse.data ?? {};
}

export async function getAllWorkflowData(workflow_id) {
    const workflowResponse = await api.v1.getWorkflowByID(workflow_id);
    return workflowResponse.data;
}

export async function getWorkflows(skip, per_page) {
    let params = {
        skip: skip,
        limit: per_page,
        order: "created_at",
        direction: "desc",
        is_template: false,
        category: null,
    };
    return await getAllGeneric(api.v1.getWorkflows, params);
}
export async function geDeletedtWorkflows() {
    return await getAllGeneric(api.v1.getDeletedWorkflows);
}

export async function getWorkflowTemplates(skip = 0, per_page = 100) {
    return await getAllGeneric(api.v1.getWorkflows, {
        skip: skip,
        limit: per_page,
        order: "created_at",
        direction: "desc",
        is_template: true,
        category: null,
        test: "null",
    });
}

export async function createWorkflow(data) {
    await postGeneric(api.v1.postWorkflow, data);
}

export async function updateWorkflow(id, data) {
    await putGeneric(api.v1.putWorkflow, id, data);
}

export async function updateWorkflowWorkspace(id, ws_id) {
    await putGeneric(api.v1.putWorkflowWorkspace, id, ws_id);
}

export async function getWorkflowRuns(skip, per_page, workflow_id) {
    let params = {
        skip: skip,
        limit: per_page,
        order: "updated_at",
        direction: "desc",
    };

    try {
        const response = await api.v1.getWorkflowRun(workflow_id, params);

        return {
            data: response.data.data,
            paging: response.data.paging,
            count: response.data.count,
        };
    } catch (error) {
        AlertHelper.showErrorAlert("Hmmm", error?.response?.data?.details ?? "Something Went Wrong. Please try again!");
    }
}

export async function initializeWorkflowRun(workflow_id, data = {}) {
    try {
        const response = await api.v1.postRunInit(workflow_id, data);
        return response.data;
    } catch (error) {
        AlertHelper.showErrorAlert("Hmmm", error?.response?.data?.details ?? "Something Went Wrong. Please try again!");

        return error;
    }
}

export async function getRun(run_id) {
    const response = await api.v1.getRunByID(run_id);
    return response.data;
}

export async function getNextSteps(run_id) {
    return ApiWrapper(await api.v1.getRunNext(run_id));
}

export async function runStep(run_id, step_id) {
    return ApiWrapper(await api.v1.getRunStep(run_id, step_id));
}

export async function runStepExclusive(run_id, step_id, next_step_id) {
    return ApiWrapper(await api.v1.patchRunStepExclusive(run_id, step_id, next_step_id));
}

export async function runStepParallel(run_id, step_id, next_step_id) {
    return ApiWrapper(await api.v1.patchRunStepParallel(run_id, step_id, next_step_id));
}

export async function runStepComplete(run_id, step_id, data) {
    return ApiWrapper(await api.v1.patchRunStepTaskComplete(run_id, step_id, data));
}

export async function runCallActivityComplete(run_id) {
    return ApiWrapper(await api.v1.patchRunCallActivityComplete(run_id));
}

export async function runStepExec(run_id, step_id, data) {
    return ApiWrapper(await api.v1.patchRunStepTaskExec(run_id, step_id, data));
}

export async function runCallActivity(run_id, step_id, data) {
    return ApiWrapper(await api.v1.patchRunStepCallActivity(run_id, step_id, data));
}

export async function runStepEvent(run_id, step_id) {
    return ApiWrapper(await api.v1.patchRunStepEvent(run_id, step_id));
}

export async function runStepTaskSend(run_id, step_id, data) {
    return ApiWrapper(await api.v1.patchRunStepSend(run_id, step_id, data));
}

export async function getNextStepsCompletedRun(run_id) {
    const response = await api.v1.getRunNext(run_id);
    return response.data;
}

export async function getRunObjectStorageDataReferences(run_id) {
    const response = await api.v1.getRunObjectStorageDataReferences(run_id);
    return response.data;
}

export async function getRunObjectStorageDataStoreReferences(run_id) {
    const response = await api.v1.getRunObjectStorageDataStoreReferences(run_id);
    return response.data;
}

export async function getWorfklowCategories() {
    return await getAllGeneric(api.v1.getWorkflowCategories);
}

export const createWorkflowCategory = async (data) => {
    await postGeneric(api.v1.postWorkflowCategories, data);
};

export const updateWorkflowCategory = async (id, data) => {
    await putGeneric(api.v1.putWorkflowCategory, id, data);
};

export const deleteWorkflowCategory = async (id) => {
    await deleteGeneric(api.v1.deleteWorkflowCategory, id);
};
