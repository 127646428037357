import React, { useEffect, useState } from "react";
import { FormGroup, Label, Input, Row, Col } from "reactstrap";
import { INSTRUCTIONS } from "../utils/enum";

const UserInputFields = ({ fields, callback }) => {
    const [form, setForm] = useState({});

    useEffect(() => {
        let _form = {};
        fields.forEach((input_name) => {
            _form[input_name] = "";
        });
        setForm(_form);
    }, [fields]);

    function parseUserInput(e) {
        callback({ [INSTRUCTIONS.user_input]: { ...form, ...{ [e.target.name]: e.target.value.trim() } } });
        setForm((old) => {
            return { ...old, [e.target.name]: e.target.value.trim() };
        });
    }

    return fields.map((input_name) => (
        <FormGroup key={input_name}>
            <Label className="form-label">Edit {input_name}</Label>
            <Input
                id={`field_${input_name}`}
                name={`${input_name}`}
                type="text"
                value={form[input_name]}
                onChange={parseUserInput}
            />
        </FormGroup>
    ));
};

export default UserInputFields;
