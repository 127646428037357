import React from "react";
const Dahsboard = () => {
    return (
        <>
            <h6>Welcome to Workflow Engine</h6>
        </>
    );
};

export default Dahsboard;
