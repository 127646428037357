import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export const AlertHelper = {
    showSuccessAlert: showSuccessAlert,
    showInfoAlert: showInfoAlert,
    showWarningAlert: showWarningAlert,
    showErrorAlert: showErrorAlert,
    showQuestionAlert: showQuestionAlert,
};

function showSuccessAlert(title, text) {
    return MySwal.fire({
        title: title,
        text: text,
        icon: "success",
        customClass: {
            confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
    });
}

function showInfoAlert(title, text) {
    return MySwal.fire({
        title: title,
        text: text,
        icon: "info",
        customClass: {
            confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
    });
}

function showWarningAlert(title, text) {
    return MySwal.fire({
        title: title,
        text: text,
        icon: "warning",
        customClass: {
            confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
    });
}

function showErrorAlert(title, text) {
    return MySwal.fire({
        title: title,
        text: text,
        icon: "error",
        customClass: {
            confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
    });
}

function showQuestionAlert(title, text) {
    return MySwal.fire({
        title: title,
        text: text,
        icon: "question",
        customClass: {
            confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
    });
}
