/**
 * @typedef {Object} StorageURLs
 * @property {Object} StorageURLs.v1 - v1 urls
 * @property {string} StorageURLs.v1.listObjectStorageBuckets - datalake/objectstorage
 * @property {string} StorageURLs.v1.listObjectStorageObjects - datalake/objectstorage/list/{bucket}
 * @property {string} StorageURLs.v1.ObjectStorageObjectInfo - datalake/objectstorage/info/{bucket}
 * @property {string} StorageURLs.v1.file - file
 * @property {string} StorageURLs.v1.dataStorage - datalake/datastorage/
 * @property {string} StorageURLs.v1.dataStorageCatalog - datalake/datastorage/{catalog}
 * @property {string} StorageURLs.v1.dataStorageCatalogSchema - datalake/datastorage/{catalog}/{schema}
 * @property {string} StorageURLs.v1.dataStorageCatalogSchemaTableDescribe - datalake/datastorage/{catalog}/{schema}/{table}/describe
 * @property {string} StorageURLs.v1.dataStorageCatalogSchemaTableSources - datalake/datastorage/{catalog}/{schema}/{table}/sources
 */

/**
 * Get storage endpoint urls
 * @returns {StorageURLs} endpoints
 */
const storage_eps = (function StorageURLs() {
    const BASE_DATA_LAKE = "datalake";
    const BASE_OBJ_STORAGE = `${BASE_DATA_LAKE}/objectstorage`;
    const BASE_DATA_STORAGE = `${BASE_DATA_LAKE}/datastorage`;

    return {
        v1: {
            /**
             * @returns {string}
             */
            listObjectStorageBuckets: () => BASE_OBJ_STORAGE,
            /**
             * @param {string} bucket
             * @returns {string}
             */
            listObjectStorageObjects: (bucket) => `${BASE_OBJ_STORAGE}/list/${bucket}`,
            /**
             * @param {string} bucket
             * @returns {string}
             */
            ObjectStorageObjectInfo: (bucket) => `${BASE_OBJ_STORAGE}/info/${bucket}`,
            /**
             * @returns {string}
             */
            file: () => `file`,
            /**
             * @returns {string}
             */
            dataStorage: () => `${BASE_DATA_STORAGE}`,
            /**
             * @param {string} catalog
             * @returns {string}
             */
            dataStorageCatalog: (catalog) => `${BASE_DATA_STORAGE}/${catalog}`,
            /**
             * @param {string} catalog
             * @param {string} schema
             * @returns {string}
             */
            dataStorageCatalogSchema: (catalog, schema) => `${BASE_DATA_STORAGE}/${catalog}/${schema}`,
            /**
             * @param {string} catalog
             * @param {string} schema
             * @param {string} table
             * @returns {string}
             */
            dataStorageCatalogSchemaTableDescribe: (catalog, schema, table) =>
                `${BASE_DATA_STORAGE}/${catalog}/${schema}/${table}/describe`,
            /**
             * @param {string} catalog
             * @param {string} schema
             * @param {string} table
             * @returns {string}
             */
            dataStorageCatalogSchemaTableSources: (catalog, schema, table) =>
                `${BASE_DATA_STORAGE}/${catalog}/${schema}/${table}/sources`,
            dataStorageCatalogSchemaTableDownload: (catalog, schema, table) =>
                `${BASE_DATA_STORAGE}/${catalog}/${schema}/${table}/download`
        },
    };
})();

export default storage_eps;
