import React from "react";
import { ListGroupItem } from "reactstrap";
import { getQueryLabel } from "../../utils/formatter";

const DataItem = ({ dataset }) => {
    return (
        <ListGroupItem className="card">
            <>
                {dataset?.query ? (
                    <>
                        <p>{getQueryLabel(dataset.query)}</p>
                        <p>File path: {dataset?.object_name}</p>
                    </>
                ) : (
                    dataset?.object_name
                )}
            </>
        </ListGroupItem>
    );
};

export default DataItem;
