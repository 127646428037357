/**
 * @typedef {Object} FeatureURLs
 * @property {Object} FeatureURLs.v1 - v1 urls
 * @property {string} FeatureURLs.v1.features - feature
 * @property {string} FeatureURLs.v1.feature - feature/{feature_id}
 */

/**
 * Get feature endpoint urls
 * @returns {FeatureURLs} endpoints
 */
const feature_eps = (function FeatureURLs() {

    return {
        v1: {
            /**
             * @returns {string}
             */
            features: () => `feature`,
            /**
             *
             * @param {string} feature_id
             * @returns {string}
             */
            feature: (feature_id) => `feature/${feature_id}`,
        },
    };
})();

export default feature_eps;
