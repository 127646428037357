import { createSlice } from "@reduxjs/toolkit";

const localStoreSlice = createSlice({
    name: "localStore",
    initialState: {
        token: "",
        prevPath: localStorage.getItem("prevPath") ?? "/",
        wsid: localStorage.getItem("wsid") ?? 27, // hardcode db's global ws
    },
    reducers: {
        setToken(state, action) {
            state.token = action.payload;
        },
        setPrevPath(state, action) {
            state.prevPath = action.payload;
        },
        setWsID(state, action) {
            state.wsid = action.payload;
        },
    },
});

export const { setToken, setPrevPath, setWsID } = localStoreSlice.actions;
export const selectToken = (state) => state.localStore.token;
export const selectPrevPath = (state) => state.localStore.prevPath;
export const selectWsID = (state) => state.localStore.wsid;
export default localStoreSlice;
