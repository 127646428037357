import React, { useEffect, useState, useContext, useImperativeHandle, forwardRef, useCallback } from "react";
// import ReactBpmn from 'react-bpmn';
import Modeler from "bpmn-js/lib/Modeler";
import "bpmn-js/dist/assets/diagram-js.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const BpmnModeler = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        childFunction1() {
            console.log("child function 1 called");
        },
        saveDiagramExport(callback) {
            saveDiagram(callback);
        },
    }));
    //modal show-hide
    const [show, setShow] = useState();
    const handleClose = () => {
        document.querySelector("#root").style.display = "block";
        setShow(false);
    };
    const handleShow = () => {
        document.querySelector("#root").style.display = "none";

        setShow(true);
    };

    // const xmldiagram = xmldiagram_view; //diagram got from database of the
    const [diagram, diagramSet] = useState([]);
    const [modeler, setModeler] = useState(null);
    const [shapes_in_xml, set_shapes_xml] = useState("");

    useEffect(() => {
        const container = document.getElementById(props.container);

        const initModeler = new Modeler({
            container,
            keyboard: {
                bindTo: document,
            },
        });

        var eventBus = initModeler.get("eventBus");

        // you may hook into any of the following events
        var events = ["element.click"];

        events.forEach(function (event) {
            eventBus.on(event, function (e) {
                // e.element = the model element
                // e.gfx = the graphical element
                console.log(e);
            });
        });

        setModeler(initModeler);
    }, []);

    useEffect(() => {
        (async () => {
            if (diagram.length > 0) {
                try {
                    await modeler.importXML(diagram);

                    const canvas = modeler.get("modeling");
                    canvas.setColor("CalmCustomerTask", {
                        stroke: "green",
                        fill: "yellow",
                    });
                } catch (error) {
                    console.error("error", error);
                }
            }
        })();
    }, [diagram]);

    useEffect(() => {
        let xmlOriginal = props.initialXml || {};

        if (Object.keys(xmlOriginal).length === 0) {
            axios
                .get(process.env.PUBLIC_URL + "/resources/newDiagram.bpmn")
                .then((r) => {
                    diagramSet(r.data);
                })
                .catch((e) => {
                    console.log(e);
                });
        } else {
            diagramSet(xmlOriginal.xml_original);
        }
    }, [props.initialXml]);

    const saveDiagram = async (callback) => {
        // handleShow();
        console.log("Save Diagram was clicked.");
        let xml;
        // data = await modeler.saveXML({format: true}).then(res => console.log()).catch(err => console.log())

        try {
            const { xml } = await modeler.saveXML({ format: true });

            console.log("EXPORTING XML...TO JSON");
            set_shapes_xml(xml); //add shapes_in_xml the new value to preview
            // console.log(xml);
            console.log("sending xml to create back");
            //USING xmlToJson to create the json and save it with the tasks

            //append the xml on the bpmn diagram json
            let json_created_from_xml = {};
            json_created_from_xml.xml_original = xml;

            callback(json_created_from_xml);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <div className="App">
                <div
                    id={props.container}
                    style={{
                        border: "1px solid #000000",
                        height: "60vh",
                        width: "60vw",
                        margin: "auto",
                    }}
                ></div>
                <Modal size="lg" show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>BPMN diagram</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{shapes_in_xml}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        {/* <Button variant="primary" onClick={show_json}>
              Save Diagram
            </Button> */}
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    );
});

export default BpmnModeler;
