import React, { useEffect, useContext, useState } from "react";
import { Button, CardText } from "reactstrap";
import "../../assets/css/Accordion.css";
import { ObjectSelectedContext } from "../../contexts/datalake/objectstorage";
import { DataSelectedContext } from "../../contexts/datalake/datastorage";
import { AlertHelper } from "../../utils/alertHelper";
import ObjectStorage from "../ObjectStorage/Buckets";
import Catalogs from "../DataStorage/Catalogs";
import FreeTextSearch from "../freeTextSearch/freeTextSearch";
import ViewSelectedObjects from "./ViewSelectedObjects";

const SelectObjects = ({ stores, callback }) => {
    const { objectSelected, setObjectSelected } = useContext(ObjectSelectedContext);
    const { dataSelected, setDataSelected } = useContext(DataSelectedContext);
    const [showObjectStorage, setShowObjectStorage] = useState(false);
    const [showDataStorage, setShowDataStorage] = useState(false);
    const [searchMode, setSearchMode] = useState(undefined); // to track the user's choice
    const [store, setStore] = useState({});

    function handleStoreUpdate(storeType, obj = []) {
        setStore((oldStore) => {
            try {
                oldStore[stores[storeType].sid][stores[storeType].mode] = obj;
            } catch (error) {
                oldStore[stores[storeType].sid] = { [stores[storeType].mode]: obj };
            }
            return oldStore;
        });
    }

    useEffect(() => {
        setObjectSelected([]);
        setDataSelected([]);
    }, []);

    useEffect(() => {
        if (Object.keys(stores).length !== 0 && stores.constructor === Object) {
            if ("DataObject" in stores) {
                handleStoreUpdate("DataObject");
                setShowObjectStorage(true);
            }

            if ("DataStore" in stores) {
                handleStoreUpdate("DataStore");
                setShowDataStorage(true);
            }
        }
    }, [stores]);

    useEffect(() => {
        if (objectSelected.length > 0) {
            handleStoreUpdate("DataObject", objectSelected);
        }
    }, [objectSelected]);

    useEffect(() => {
        if (dataSelected.length > 0) {
            handleStoreUpdate("DataStore", dataSelected);
        }
    }, [dataSelected]);

    function handleSearch() {
        setSearchMode("search");
    }

    function handleViewAllDatalake() {
        setSearchMode("viewAllDatalake");
    }

    function handleViewAllParquet() {
        setSearchMode("viewAllParquet");
    }

    function callbackMiddleware(element) {
        if (objectSelected.length == 0 && dataSelected.length == 0) {
            AlertHelper.showErrorAlert("Please select at least one object!");
            return;
        }

        if (Object.keys(store).length > 0) {
            element.target.disabled = true;

            callback(store);
        } else {
            AlertHelper.showErrorAlert("Store is not properly configured");
        }
    }

    return (
        <>
            {/* show confirmation dialog if search mode hasn't been chosen */}
            <p>Please choose a search mode:</p>
            <Button color="primary" onClick={handleSearch}>
                Search for filtered datasets
            </Button>{" "}
            <Button color="secondary" onClick={handleViewAllDatalake}>
                View all from Datalake
            </Button>{" "}
            <Button color="secondary" onClick={handleViewAllParquet}>
                View all Parquet
            </Button>
            {/* show FreeTextSearch and ObjectStorage components if user has chosen search mode */}
            {searchMode === "search" && showObjectStorage && (
                <>
                    <FreeTextSearch />

                    <h3 className="mt-5">List of Selected Objects</h3>
                </>
            )}
            {/* show all objects if user has chosen viewAll mode */}
            {searchMode === "viewAllDatalake" && showObjectStorage && (
                <>
                    <div className="mt-3">
                        <ObjectStorage />
                    </div>
                </>
            )}
            {/* show all catalogs if task has Data Storage */}
            {searchMode === "viewAllParquet" && showDataStorage && (
                <div className="mt-3">
                    <Catalogs />
                </div>
            )}
            {/* show confirmation button after user has chosen search mode */}
            {searchMode && (
                <>
                    <ViewSelectedObjects className="mt-5" />
                    <CardText className="mt-5">Are you ok with the Data from DataLake?</CardText>
                    <Button color="success" onClick={callbackMiddleware}>
                        Yes
                    </Button>
                </>
            )}
        </>
    );
};

export default SelectObjects;
