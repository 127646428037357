import endpoints from "../endpoints";
import { axios_v1 as axios } from "../axios";

/**
 * @typedef {Object} StorageAPIs
 * @property {Object} StorageAPIs.v1 - v1 api functions
 * @property {Promise} StorageAPIs.v1.getListObjectStorageBuckets - getListObjectStorageBuckets()
 * @property {Promise} StorageAPIs.v1.getListObjectStorageObjects - getListObjectStorageObjects(bucket, params?)
 * @property {Promise} StorageAPIs.v1.getObjectStorageObjectInfo - getObjectStorageObjectInfo(bucket, object)
 * @property {Promise} StorageAPIs.v1.getFile - getFile(object_name)
 * @property {Promise} StorageAPIs.v1.getDataStorageCatalogs - getDataStorageCatalogs()
 * @property {Promise} StorageAPIs.v1.getDataStorageCatalogSchemas - getDataStorageCatalogSchemas(catalog)
 * @property {Promise} StorageAPIs.v1.getDataStorageCatalogSchemaTables - getDataStorageCatalogSchemaTables(catalog, schema)
 * @property {Promise} StorageAPIs.v1.getDataStorageCatalogSchemaTableDescribe - getDataStorageCatalogSchemaTableDescribe(catalog, schema, table)
 * @property {Promise} StorageAPIs.v1.getDataStorageCatalogSchemaTableSources - getDataStorageCatalogSchemaTableSources(catalog, schema, table)
 */

/**
 * Get workflow API functions
 * @returns {StorageAPIs} api functionss
 */
const storage_api = (function StorageAPIs() {
    return {
        v1: {
            /**
             * @returns {Promise}
             */
            getListObjectStorageBuckets: () => {
                return axios.get(endpoints.v1.listObjectStorageBuckets());
            },
            /**
             * @returns {Promise}
             */
            getListObjectStorageObjects: (bucket, params = {}) => {
                if (Object.keys(params).length !== 0) {
                    return axios.get(endpoints.v1.listObjectStorageObjects(bucket), { params });
                }

                return axios.get(endpoints.v1.listObjectStorageObjects(bucket));
            },
            /**
             * @param {string} bucket
             * @param {string} object
             * @returns {Promise}
             */
            getObjectStorageObjectInfo: (bucket, object) => {
                return axios.get(endpoints.v1.ObjectStorageObjectInfo(bucket), { params: { obj_name: object } });
            },
            /**
             * @param {string} object_name
             * @returns {Promise}
             */
            getFile: (object_name) => {
                return axios.get(endpoints.v1.file(), { params: { term: object_name } });
            },
            /**
             * @returns {Promise}
             */
            getDataStorageCatalogs: () => {
                return axios.get(endpoints.v1.dataStorage());
            },
            /**
             * @param {string} catalog
             * @returns {Promise}
             */
            getDataStorageCatalogSchemas: (catalog) => {
                return axios.get(endpoints.v1.dataStorageCatalog(catalog));
            },
            /**
             * @param {string} catalog
             * @param {string} schema
             * @returns {Promise}
             */
            getDataStorageCatalogSchemaTables: (catalog, schema) => {
                return axios.get(endpoints.v1.dataStorageCatalogSchema(catalog, schema));
            },
            /**
             * @param {string} catalog
             * @param {string} schema
             * @param {string} table
             * @returns {Promise}
             */
            getDataStorageCatalogSchemaTableDescribe: (catalog, schema, table) => {
                return axios.get(endpoints.v1.dataStorageCatalogSchemaTableDescribe(catalog, schema, table));
            },
            /**
             * @param {string} catalog
             * @param {string} schema
             * @param {string} table
             * @returns {Promise}
             */
            getDataStorageCatalogSchemaTableSources: (catalog, schema, table) => {
                return axios.get(endpoints.v1.dataStorageCatalogSchemaTableSources(catalog, schema, table));
            },
            
            downloadDataStorageCatalogSchemaTableSources: (catalog, schema, table) => {
                let url = endpoints.v1.dataStorageCatalogSchemaTableDownload(catalog, schema, table);
                return axios.get(url);
            },
        },
    };
})();

export default storage_api;
