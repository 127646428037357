import oauth_apis from "./oauth";
import module_apis from "./module";
import run_apis from "./run";
import storage_apis from "./storage";
import workflow_apis from "./workflow";
import feature_apis from "./feature";
import variable_apis from "./variable";
import workspace_apis from "./workspace";

/**
 * @returns {ApiV1} apiV1
 */
const apiV1 = (function ApiV1() {
    return {
        ...oauth_apis.v1,
        ...module_apis.v1,
        ...run_apis.v1,
        ...storage_apis.v1,
        ...workflow_apis.v1,
        ...feature_apis.v1,
        ...variable_apis.v1,
        ...workspace_apis.v1,
    };
})();

export default {
    v1: apiV1,
};
