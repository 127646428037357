import React, { useEffect, useState } from "react";
import { FormGroup, Label, Input, Row, Col } from "reactstrap";
import { INSTRUCTIONS } from "../utils/enum";

const TextAreaParams = ({ params, callback }) => {
    const [text, setText] = useState("{}");

    function parseParams(e) {
        const value = e.target.value;
        setText(value);
        callback({ [INSTRUCTIONS.params]: value });
    }

    useEffect(() => {
        setText(JSON.stringify(params));
    }, []);

    return (
        <FormGroup>
            <Label className="form-label" for={`instructions_${INSTRUCTIONS.params}`}>
                Edit Parameters
            </Label>
            <Input
                id={`instructions_${INSTRUCTIONS.params}`}
                name={`instructions_${INSTRUCTIONS.params}`}
                type="textarea"
                value={text}
                onChange={parseParams}
            />
        </FormGroup>
    );
};

export default TextAreaParams;
