import React from "react";
import { useState, useEffect } from "react";
import { Card, CardBody, CardTitle, CardText, Button } from "reactstrap";

export default ({ title, tasks, queue, callback }) => {
    const [currentComponent, setCurrentComponent] = useState([]);

    useEffect(() => {
        const ElementArray = [];

        queue.forEach((task) => {
            let taskText = "";

            switch (tasks[task.sid].type) {
                case "ExclusiveGateway":
                    taskText = "Please select one of the following";
                    break;
                case "ParallelGateway":
                    taskText = "Proceed to mandatory tasks";
                    break;
                case "ScriptTask":
                    taskText = "This executes the desired function";
                    break;
                case "ManualTask":
                    taskText = "You have to manualy complete this step";
                    break;
                case "EndEvent":
                    taskText = "This will terminate the process";
                    break;
            }

            ElementArray.push({
                id: task.id,
                name: task.name,
                text: taskText,
            });
        });

        setCurrentComponent(ElementArray);
    }, [queue]);

    return (
        <>
            <Card>
                <CardBody>
                    <CardTitle tag="h3">{title}</CardTitle>

                    {currentComponent.map((task, key) => {
                        return (
                            <Card key={key}>
                                <CardBody>
                                    <CardTitle tag="h3">{task.name}</CardTitle>
                                    <CardText>{task.text}</CardText>
                                    <Button color="primary" onClick={() => callback(task.id)}>
                                        Select
                                    </Button>
                                </CardBody>
                            </Card>
                        );
                    })}
                </CardBody>
            </Card>
        </>
    );
};
