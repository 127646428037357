/**
 * @typedef {Object} AuthURLs
 * @property {Object} AuthURLs.v1 - v1 urls
 * @property {string} AuthURLs.v1.login - oauth/login
 * @property {string} AuthURLs.v1.logout - oauth/logout
 */

/**
 * Get auth endpoint urls
 * @returns {AuthURLs} endpoints
 */
const auth_eps = (function AuthURLs() {
    return {
        v1: {
            /**
             * @returns {string}
             */
            login: () => `oauth/login`,
            /**
             * @returns {string}
             */
            logout: () => `oauth/logout`,
        },
    };
})();

export default auth_eps;
