import React, { useEffect, useState } from "react";
import { Button, ListGroupItem, Accordion, AccordionBody, AccordionHeader, AccordionItem, Row, Col } from "reactstrap";
import "../../assets/css/Accordion.css";

const ModuleSelector = ({ listData, defaultTask, callback }) => {
    const [modulesCategorized, setModulesCategorized] = useState({});
    const [preSelectedModule, setPreSelectedModule] = useState({});

    const [open, setOpen] = useState("");

    const toggle = (id) => {
        if (open === id) {
            setOpen("");
        } else {
            setOpen(id);
        }
    };

    function select(object) {
        callback(object);
    }

    useEffect(() => {
        (async () => {
            let _modulesCategorized = {};
            listData.forEach((module) => {
                if (defaultTask == module.code) {
                    setPreSelectedModule(module);
                }

                const codeList = module.code.split("/");
                const category = codeList[0];
                // const moduleCode = codeList[codeList.length - 1];

                if (category in _modulesCategorized) {
                    _modulesCategorized[category].push(module);
                } else {
                    _modulesCategorized[category] = [module];
                }
            });

            setModulesCategorized(_modulesCategorized);
        })();
    }, [listData]);

    return (
        <div className="mb-3">
            {preSelectedModule?.code && (
                <>
                    <h4>"{preSelectedModule.name}" is the default task to be executed</h4>
                    <p>
                        If you want, please{" "}
                        <Button color="primary" onClick={() => select(preSelectedModule)}>
                            proceed
                        </Button>
                    </p>
                    <p>else, select another module below:</p>
                </>
            )}
            {!preSelectedModule?.code && <h4>Please select a module</h4>}

            <Accordion flush open={open} toggle={toggle}>
                {Object.keys(modulesCategorized).map((category, index) => (
                    <AccordionItem key={index}>
                        <AccordionHeader targetId={index.toString()}>{category}</AccordionHeader>
                        <AccordionBody accordionId={index.toString()}>
                            {modulesCategorized[category].map((module, idx) => (
                                <ListGroupItem className="card" key={idx}>
                                    <Row>
                                        <Col xs="8">{module.name}</Col>
                                        <Col xs="4">
                                            <Button color="primary" onClick={() => select(module)}>
                                                select
                                            </Button>
                                        </Col>
                                    </Row>
                                </ListGroupItem>
                            ))}
                        </AccordionBody>
                    </AccordionItem>
                ))}
            </Accordion>
        </div>
    );
};

export default ModuleSelector;
