import React, { useEffect, useState } from "react";
import { Button, ListGroupItem, CardText } from "reactstrap";
import { INSTRUCTIONS } from "../utils/enum";

const TaskReferenceForm = ({ previousCompletedTaskSelected, callback, num_of_references = 1, showProceed = true }) => {
    const [mode, setMode] = useState("");
    const [showNext, setShowNext] = useState(false);
    const [formStep, setFormStep] = useState(0);
    const [formLength, setFormLength] = useState(0);
    const [formObject, setFormObject] = useState([]);
    const [parsedPreviousCompletedTaskSelected, setParsedPreviousCompletedTaskSelected] = useState([]);

    useEffect(() => {
        setFormLength(num_of_references == -1 ? 1 : num_of_references);

        if (num_of_references == -1) {
            setShowNext(true);
        }
    }, []);

    useEffect(() => {
        if (previousCompletedTaskSelected) {
            let _array = [];
            previousCompletedTaskSelected.forEach((element) => {
                if (!("selected" in element)) {
                    element.selected = false;
                }
                _array.push(element);
            });
            setParsedPreviousCompletedTaskSelected(_array);
        }
    }, [previousCompletedTaskSelected]);

    function _objInArray(list, element) {
        return list.findIndex((x) => x.id == element.id) !== -1;
    }

    function selectAndProceed(object, index) {
        const updatedObject = { ...object, selected: true };
        const oldObj = formObject;

        if (!_objInArray(oldObj, object)) {
            oldObj.push({ id: object.id, name: object.name, module: object?.metadata?.class });
            setFormObject(oldObj);
            setParsedPreviousCompletedTaskSelected((oldObj) => {
                const newObj = [...oldObj];
                newObj[index] = updatedObject;
                return newObj;
            });
        }

        if (!showNext) {
            goNext();
        }
    }

    function goNext() {
        let newStep = formStep;
        newStep += 1;
        setFormStep(newStep);

        if (newStep >= formLength && !showProceed) {
            submit();
        }
    }

    function goBack() {
        if (formStep > 0) {
            let newStep = formStep;
            newStep -= 1;
            setFormStep(newStep);
        }
    }

    function deselect(object, index) {
        const updatedObject = { ...object, selected: false };
        const oldObj = formObject;

        oldObj = oldObj.filter((obj) => {
            return !(obj.id == object.id);
        });

        setFormObject(oldObj);
        setParsedPreviousCompletedTaskSelected((oldObj) => {
            const newObj = [...oldObj];
            newObj[index] = updatedObject;
            return newObj;
        });
    }

    function submit() {
        callback({ [INSTRUCTIONS.ref_completed_task]: formObject });
    }

    if (formStep >= 0 && formStep < formLength) {
        return (
            <div className="mb-3">
                <h4>Please select the completed task you want to reference</h4>

                {parsedPreviousCompletedTaskSelected &&
                    parsedPreviousCompletedTaskSelected.map((element, index) => (
                        <ListGroupItem className="card" key={index}>
                            <>
                                {element.name} {element?.metadata?.class && <span>({element.metadata.class})</span>}
                                {element.selected ? (
                                    <Button color="secondary" onClick={() => deselect(element, index)}>
                                        deselect
                                    </Button>
                                ) : (
                                    <Button color="primary" onClick={() => selectAndProceed(element, index)}>
                                        select
                                    </Button>
                                )}
                            </>
                        </ListGroupItem>
                    ))}

                {formStep > 0 && (
                    <Button color="danger" onClick={goBack}>
                        Back
                    </Button>
                )}
                {showNext && (
                    <Button color="secondary" onClick={goNext}>
                        View selected
                    </Button>
                )}
            </div>
        );
    } else if (formStep >= formLength) {
        return (
            <div className="mb-3">
                {formObject.map((obj, index) => {
                    return (
                        <ListGroupItem className="card" key={index}>
                            <>
                                <CardText>
                                    <span className="font-weight-bold">Name</span>: {obj.name}{" "}
                                    {obj.module && <span>({obj.module})</span>}
                                </CardText>
                            </>
                        </ListGroupItem>
                    );
                })}
                <Button color="danger" onClick={goBack}>
                    Return
                </Button>
                {showProceed && (
                    <Button color="secondary" onClick={submit}>
                        Save
                    </Button>
                )}
            </div>
        );
    } else {
        return <></>;
    }
};

export default TaskReferenceForm;
