const INSTRUCTIONS = {
    num_of_files: "num_of_files",
    file_types: "file_types",
    data_input: "data_input",
    data_input_labels: "data_input_labels",
    user_input: "user_input",
    params: "params",
    ref_completed_task: "ref_completed_task",
};

const INSTRUCTIONS_FOR_WIZARD = {};
INSTRUCTIONS_FOR_WIZARD[INSTRUCTIONS.num_of_files] = INSTRUCTIONS.num_of_files;
INSTRUCTIONS_FOR_WIZARD[INSTRUCTIONS.user_input] = INSTRUCTIONS.user_input;
INSTRUCTIONS_FOR_WIZARD[INSTRUCTIONS.params] = INSTRUCTIONS.params;
INSTRUCTIONS_FOR_WIZARD[INSTRUCTIONS.ref_completed_task] = INSTRUCTIONS.ref_completed_task;

export { INSTRUCTIONS, INSTRUCTIONS_FOR_WIZARD };
