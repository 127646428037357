/**
 * @typedef {Object} WorkflowURLs
 * @property {Object} WorkflowURLs.v1 - v1 urls
 * @property {string} WorkflowURLs.v1.workflows - workflow
 * @property {string} WorkflowURLs.v1.deletedWorkflows - workflow/deleted
 * @property {string} WorkflowURLs.v1.workflowSearch - workflow/search
 * @property {string} WorkflowURLs.v1.workflowID - workflow/{workflow_id}
 * @property {string} WorkflowURLs.v1.workflowWorkspace - workflow/{workflow_id}/workspace
 * @property {string} WorkflowURLs.v1.workflowTaskTypes - workflow/entity_types
 * @property {string} WorkflowURLs.v1.workflowRuns - workflow/{workflow_id}/run
 * @property {string} WorkflowURLs.v1.workflowCategories - category/workflow
 * @property {string} WorkflowURLs.v1.workflowCategoryID - category/workflow/{category_id}
 */

/**
 * Get workflow endpoint urls
 * @returns {WorkflowURLs} endpoints
 */
const workflow_eps = (function WorkflowURLs() {
    return {
        v1: {
            workflows: () => `workflow`,
            /**
             * @returns {string}
             */
            deletedWorkflows: () => `workflow/deleted`,
            /**
             * @returns {string}
             */
            workflowSearch: () => `workflow/search`,
            /**
             * @param {string} workflow_id
             * @returns {string}
             */
            workflowID: (workflow_id) => `workflow/${workflow_id}`,
            /**
             * @param {string} workflow_id
             * @returns {string}
             */
            workflowWorkspace: (workflow_id) => `workflow/${workflow_id}/workspace`,
            /**
             * @returns {string}
             */
            workflowTaskTypes: () => `workflow/entity_types`,
            /**
             * @param {string} workflow_id
             * @returns {string}
             */
            workflowRuns: (workflow_id) => `workflow/${workflow_id}/run`,
            /**
             * @returns {string}
             */
            workflowCategories: () => `category/workflow`,
            /**
             * @param {string} category_id
             * @returns {string}
             */
            workflowCategoryID: (category_id) => `category/workflow/${category_id}`,
        },
    };
})();

export default workflow_eps;
