/**
 * @typedef {Object} ModuleURLs
 * @property {Object} ModuleURLs.v1 - v1 urls
 * @property {string} ModuleURLs.v1.moduleCategories - category/module
 * @property {string} ModuleURLs.v1.moduleCategoryID - category/module/{category_id}
 * @property {string} ModuleURLs.v1.modules - module
 * @property {string} ModuleURLs.v1.moduleID - module/{module_id}
 */

/**
 * Get module endpoint urls
 * @returns {ModuleURLs} endpoints
 */
const module_eps = (function ModuleURLs() {
    return {
        v1: {
            /**
             * @returns {string}
             */
            moduleCategories: () => `category/module`,
            /**
             * @param {string} category_id
             * @returns {string}
             */
            moduleCategoryID: (category_id) => `category/module/${category_id}`,
            /**
             * @returns {string}
             */
            modules: () => `module`,
            /**
             * @param {string} module_id
             * @returns {string}
             */
            moduleID: (module_id) => `module/${module_id}`,
        },
    };
})();

export default module_eps;
