import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button, Container, FormGroup, Input, Label, 
    Card, CardHeader, CardBody, Row, Col, 
    Modal, ModalHeader, ModalFooter,
    ModalBody,
    CardFooter} from "reactstrap";
import { WorkflowsService } from "../../services/workflowsService";
import { analytics_functions } from "../../analytics-functions";
import { AlertHelper } from "../../utils/alertHelper";

const CreateWorkflow = () => {

    const [workflow, setWorkflow] = useState({});
    const [steps, setSteps] = useState([]);
    const [step, setStep] = useState({});
    const [action, setAction] = useState({});
    const [actionModal, setActionModal] = useState(false);
    const [categories, setCategories] = useState([]);
    const [variables, setVariables] = useState([]);
    const [workflows, setWorkflows] = useState([]);
    const [search, setSearch] = useState('');

    const workflowsService = new WorkflowsService();

    const a_functions = analytics_functions;

    const openAction = (s, a) => {
        setAction(a);
        setStep(s);
        setActionModal(true);
    }

    const toggleAcionModal = () => setActionModal(!actionModal);

    const createWorkflow = async () => {
        setWorkflow({
            ...workflow,
            steps: steps
        });
        let wf = {
            ...workflow,
            steps
        };

        wf = {
            ...wf,
            json_representation: JSON.stringify(wf)
        }
        console.log(workflow);
        const response = await workflowsService.createWorkflow(wf);
        AlertHelper.showSuccessAlert("Good job!", "The workflow " + response + " has been created!");
    }

    useEffect(() => {
        getCategories();
        getVariables();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getCategories = async () => {
        const cs = await workflowsService.getCategories();
        console.log(cs);
        setCategories(cs.data);
    }

    const getVariables = async () => {
        const vs = await workflowsService.getVariables();
        console.log(vs);
        setVariables(vs.data);
    }

    const getWorkflows = async () => {
        const ws = await workflowsService.getWorkflows(0, 100);
        setWorkflows(ws);
    }

    return (<>
        <Container fluid>

            <h2 className="text-center">Create New Workflow</h2>

            <Card>
                <CardHeader>
                    <h3>Workflow</h3>
                </CardHeader>
                <CardBody>
                    <FormGroup>
                        <Label for="workflowName">Workflow Name</Label>
                        <Input type="text" name="workflowName" defaultValue={ workflow.name } onChange={(e) => setWorkflow({
                            ...workflow, 
                            name: e.target.value
                        })} placeholder="Workflow Name" />
                    </FormGroup>
                    <FormGroup>
                        <Label for="workflowDescription">Workflow Description</Label>
                        <Input type="textarea" name="workflowDescription" defaultValue={ workflow.description } onChange={(e) => setWorkflow({
                            ...workflow,
                            description: e.target.value
                        })} placeholder="Workflow Description" />
                    </FormGroup>
                    <FormGroup>
                        <Label for="workflowCategory">Workflow Category</Label>
                        <Input type="select" defaultValue={ workflow.category_id } className="form-control" name="workflowCategory" onChange={(e) => setWorkflow({
                            ...workflow,
                            category_id: e.target.value
                        })}>
                            <option>Select Category</option>
                            {
                                categories.map((c, i) => {
                                    return <option key={i} value={c.id}>{c.name}</option>
                                })
                            }
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="workflowTemplate"> <Input defaultChecked={ workflow.is_template } type="checkbox" name="workflowTemplate" onChange={(e) => setWorkflow({
                            ...workflow,
                            is_template: e.target.checked
                        })} /> Check if the workflow is a blueprint</Label>
                    </FormGroup>
                    <FormGroup>
                        <Label for="workflowPart"> <Input type="checkbox" defaultChecked={ workflow.is_part_of_other } name="workflowPart" onChange={(e) => setWorkflow({
                            ...workflow,
                            is_part_of_other: e.target.checked
                        })} /> Check if the workflow can be run as a part of another workflow</Label>
                    </FormGroup>
                </CardBody>
            </Card>
            <Card>
                <CardHeader>
                    <h3>Steps</h3>
                </CardHeader>
                <CardBody>
                    {
                        steps.sort((s1, s2) => s1.order > s2.order ? 1 : -1).map((s, i) => {
                            return <Row key={i}>
                                    <Col md={12}>
                                        <Button className="float-end" color="danger" onClick={() => setSteps(steps.filter(step => step.order !== s.order))}>Delete Step</Button>
                                        <h3>Step {s.order + 1}</h3>
                                        <FormGroup>
                                            <Label>Type</Label>
                                            <Input type="select" defaultValue={ steps[i].type } className="form-control" name="stepType" onChange={(e) => setSteps([
                                                ...steps.filter(step => step.order !== steps[i].order),
                                                {
                                                    ...steps[i],
                                                    type: e.target.value
                                                }
                                            ])}>
                                                <option value="">Select Type</option>
                                                <option value="parallel">All tasks are available in parallel</option>
                                                <option value="linear">Each task appears when the previous one is done</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    {
                                        s.actions && s.actions.sort((a1, a2) => a1.order > a2.order ? 1 : -1).map((a, j) => {
                                            return <Col key={j} md={3}>
                                                <Card>
                                                    <CardBody onClick={() => openAction(s, a)}>
                                                        {
                                                            a.name ? <h4>{a.name}</h4> : <h4>Action {a.order + 1}</h4>
                                                        }
                                                    </CardBody>
                                                    <CardFooter>
                                                        <Button color="danger" onClick={() => setSteps([
                                                            ...steps.filter(step => step.order !== steps[i].order),
                                                            {
                                                                ...steps[i],
                                                                actions: steps[i].actions.filter(action => action.order !== a.order).map(action => action.order > a.order ? {
                                                                    ...action,
                                                                    order: action.order - 1
                                                                } : action)
                                                            }
                                                        ])}>Delete</Button>
                                                    </CardFooter>
                                                </Card>
                                            </Col>
                                        })
                                    }
                                    <Col md={12}>
                                        <Button color="success" onClick={() => setSteps([
                                            ...steps.filter(step => step.order !== steps[i].order),
                                            {
                                                ...steps[i],
                                                actions: steps[i].actions ? [...steps[i].actions, {
                                                    order: steps[i].actions ? steps[i].actions.length : 0,
                                                    conditions: []
                                                }] : [{
                                                    order: 0,
                                                    conditions: []
                                                }]
                                            }
                                        ])}>Add Action</Button>
                                        <hr />
                                    </Col>
                                </Row>
                        })
                    }
                    <Button onClick={() => setSteps([...steps, {
                        order: steps.length,
                    }])}>Add Step</Button>
                </CardBody>
                <CardFooter>
                    <Button color="success" onClick={createWorkflow}>Create Workflow</Button>
                </CardFooter>
            </Card>
        </Container>
        <Modal size="lg" isOpen={actionModal} toggle={toggleAcionModal}>
            <ModalHeader toggle={toggleAcionModal}>Action</ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label for="actionName">Action Name</Label>
                    <Input type="text" defaultValue={ action.name } name="actionName" onChange={(e) => setAction({
                        ...action,
                        name: e.target.value
                    })} placeholder="Action Name" />
                </FormGroup>
                <FormGroup>
                    <Label for="actionDescription">Action Description</Label>
                    <Input type="textarea" defaultValue={ action.description } name="actionDescription" onChange={(e) => setAction({
                        ...action,
                        description: e.target.value
                    })} placeholder="Action Description" />
                </FormGroup>
                <FormGroup>
                    <Label for="actionType">Action Type</Label>
                    <Input type="select" defaultValue={ action.action_type } className="form-control" name="actionType" onChange={(e) => setAction({
                        ...action,
                        action_type: e.target.value
                    })}>
                        <option value="">Select Type</option>
                        <option value="userinput">User Input</option>
                        <option value="inputFromExternalService">Get From External Service</option>
                        <option value="displayResult">Display result</option>
                        <option value="goto">Go to</option>
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Label for="actionCategory">Action</Label>
                    <Input type="select" defaultValue={ action.action } className="form-control" name="actionType" onChange={(e) => setAction({
                        ...action,
                        action: e.target.value
                    })}>
                        <option value="">Select Action</option>
                        {
                            action.action_type === "userinput" && <>
                                <option value="text">Text</option>
                                <option value="number">Number</option>
                                <option value="date">Date</option>
                                <option value="options">Options</option>
                                <option value="file">File</option>
                                <option value="workflow">Workflow (run an instance and of another workflow get the result)</option>
                                <option value="workflows">Workflows (run multiple instances of an other workflow and get their results)</option>
                            </>
                        }
                        {
                            action.action_type === "inputFromExternalService" && <>
                                <option value="queryBuilderResponse">Response From Query Builder</option>
                                <option value="dataAnalyticsResponse">Response From Data Analytics</option>
                            </>
                        }
                        {
                            action.action_type === "displayResult" && <>
                                <option value="diagnosis">Diagnosis</option>
                                <option value="hypothesis">Hypothesis</option>
                            </>
                        }
                        {
                            action.action_type === "goto" && <>
                                <option value="step">Step</option>
                                <option value="action">Action</option>
                            </>
                        }
                    </Input>
                </FormGroup>
                {
                    action.action === 'options' && <FormGroup>
                        <Label>Options</Label>
                        <Input type="text" defaultValue={ action.options } onChange={(e) => setAction({
                            ...action,
                            input: e.target.value
                        })}></Input>
                    </FormGroup>
                }
                {
                    (action.action === 'workflow' || action.action === 'workflows') && <FormGroup>
                        <Label>Workflow</Label>
                        <Input type="select" defaultValue={ action.input } className="form-control" onChange={(e) => setAction({
                            ...action,
                            input: e.target.value
                        })}>
                            <option value="">Select Workflow</option>
                            {
                                workflows.map((w, i) => {
                                    return <option key={i} value={w.id}>{w.name}</option>
                                })
                            }
                        </Input>
                    </FormGroup>
                }
                {
                    action.action === 'dataAnalyticsResponse' && <Row>
                        
                        <Col md="12">
                            <FormGroup>
                                <Label>Method</Label>
                                <Input type='search' onChange={(e) => setSearch(e.target.value)} placeholder="Search Analytics Functions" />
                                <Input type="select" defaultValue={ action.method } className="form-control" onChange={(e) => setAction({
                                    ...action,
                                    method: e.target.value
                                })}>
                                    <option value="">Select Analytics Functions</option>
                                    {
                                        a_functions.filter(f => (f.name || '').includes(search) || (f.description || '').includes(search)).sort((f1, f2) => (f1.title ? f1.title : f1.name) > (f2.title ? f2.title : f2.name) ? 1 : -1).map((f, i) => {
                                            return <option key={i} value={f.name}>{f.title ? f.title : f.name} {f.expected_input}</option>
                                        })
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                }
                {
                    action.action_type === 'goto' && action.action === 'step' && <FormGroup>
                        <Label>Step</Label>
                        <Input type="select" defaultValue={ action.step } className="form-control" onChange={(e) => setAction({
                            ...action,
                            gotostep: e.target.value
                        })}>
                            {
                                steps.map((s, i) => {
                                    return <option key={i} value={s.order}>{`Step ${s.order + 1}`}</option>
                                })
                            }
                        </Input>
                    </FormGroup>
                }
                {
                    action.action_type === 'goto' && action.action === 'action' && <FormGroup>
                        <Label>Action</Label>
                        <Input type="select" defaultValue={ action.action } className="form-control" onChange={(e) => setAction({
                            ...action,
                            gotoaction: e.target.value
                        })}>
                            {
                                steps.map((s, i) => {
                                    return <Fragment key={i}>
                                        {
                                            s.actions && s.actions.map((a, j) => {
                                                return <option key={j} value={`${s.order}-${a.order}`}>{`Step ${s.order + 1} - Action ${a.order + 1}`}</option>
                                            })
                                        }
                                    </Fragment>
                                })
                            }
                        </Input>
                    </FormGroup>
                }
                {
                    action.action_type === 'displayResult' && <FormGroup>
                        <Label><Input type="checkbox" defaultChecked={ action.is_conditional } name="isConditional" onChange={(e) => setAction({
                            ...action,
                            is_conditional: e.target.checked
                        })} /> Is Conditional</Label>
                    </FormGroup>
                }
                {
                    action.is_conditional && <>
                        <FormGroup>
                            <Label>Min value to be true</Label>
                            <Input type="number" defaultValue={ action.weight_to_true } onChange={(e) => setAction({
                                ...action,
                                weight_to_true: e.target.value
                            })}></Input>
                        </FormGroup>
                    </>
                }
                {
                    action.is_conditional && <>
                        <h4>Conditions</h4>
                        {
                            action.conditions.sort((c1, c2) => c1.order > c2.order ? 1 : -1).map((c, i) => {
                                return <Fragment key={i}>
                                    <Row>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label>Action</Label>
                                                <Input type="select" defaultValue={ c.action } className="form-control" name="action" onChange={(e) => setAction({
                                                    ...action,
                                                    conditions: [
                                                        ...action.conditions.filter((condition, index) => condition.order !== c.order),
                                                        {
                                                            ...c,
                                                            action: e.target.value
                                                        }
                                                    ]
                                                })}>
                                                    <option value="">Select Action</option>
                                                    {
                                                        steps.map((s, i) => {
                                                            return <Fragment key={i}>
                                                                {
                                                                    s.actions && s.actions.map((a, j) => {
                                                                        return <option key={j} value={`${s.order}-${a.order}`}>{`Step ${s.order + 1} - Action "${a.name ? a.name : (a.order + 1)}"`}</option>
                                                                    })
                                                                }
                                                            </Fragment>
                                                        })
                                                    }
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label>Condition</Label>
                                                <Input type="select" defaultValue={ c.condition } className="form-control" name="condition" onChange={(e) => setAction({
                                                    ...action,
                                                    conditions: [
                                                        ...action.conditions.filter((condition, index) => condition.order !== c.order),
                                                        {
                                                            ...c,
                                                            condition: e.target.value
                                                        }
                                                    ]
                                                })}>
                                                    <option value="">Select Condition</option>
                                                    <option value="hasVariable">Has Variable</option>
                                                    <option value="variableHasValue">Variable Has Value</option>
                                                    <option value="variableMoreThan">Variable More Than</option>
                                                    <option value="variableLessThan">Variable Less Than</option>
                                                    <option value="variableEqualsToVariable">Variable Equals to other variable</option>
                                                    <option value="variableMoreThanVariable">Variable More Than Variable</option>
                                                    <option value="variableLessThanVariable">Variable Less Than Variable</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        
                                    </Row>
                                    
                                    <Row>
                                    {
                                        c.condition && c.condition.includes('ariable') && <><Col md="4">
                                                <FormGroup>
                                                    <Label>Variable</Label>
                                                    <Input type="text" defaultValue={ c.variable } className="form-control" onChange={(e) => setAction({
                                                        ...action,
                                                        conditions: [
                                                            ...action.conditions.filter((condition, index) => condition.order !== c.order),
                                                            {
                                                                ...c,
                                                                variable: e.target.value
                                                            }
                                                        ]
                                                    })}>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                            {
                                                c.condition.includes('Variable') && <FormGroup>
                                                    <Label>Variable</Label>
                                                    <Input type="text" defaultValue={ c.value } onChange={(e) => setAction({
                                                        ...action,
                                                        conditions: [
                                                            ...action.conditions.filter((condition, index) => condition.order !== c.order),
                                                            {
                                                                ...c,
                                                                value: e.target.value
                                                            }
                                                        ]
                                                    })}></Input>
                                                </FormGroup>
                                            }
                                            {
                                                !c.condition.includes('Variable') && <FormGroup>
                                                    <Label>Value</Label>
                                                    <Input type="text" defaultValue={ c.value } onChange={(e) => setAction({
                                                        ...action,
                                                        conditions: [
                                                            ...action.conditions.filter((condition, index) => condition.order !== c.order),
                                                            {
                                                                ...c,
                                                                value: e.target.value
                                                            }
                                                        ]
                                                    })}></Input>
                                                </FormGroup>
                                            }
                                            
                                            </Col>
                                            <Col md="4">
                                                <Button color="danger" onClick={() => {
                                                    let od = c.order
                                                    setAction({
                                                        ...action,
                                                        conditions: action.conditions.filter((condition, index) => condition.order !== c.order).map(con => {
                                                            return {
                                                                ...con,
                                                                order: con.order > od ? con.order - 1 : con.order
                                                            }

                                                        })
                                                    })
                                                }}>Delete Condition</Button>
                                            </Col>
                                        </>
                                    }
                                    {
                                        c.condition && c.condition === 'hasMetadata' && <Col md="4">
                                            <FormGroup>
                                                <Label>Metadata</Label>
                                                <Input type="text" defaultValue={ c.metadata_value } onChange={(e) => setAction({
                                                    ...action,
                                                    conditions: [
                                                        ...action.conditions.filter((condition, index) => condition.order !== c.order),
                                                        {
                                                            ...c,
                                                            metadata_value: e.target.value
                                                        }
                                                    ]
                                                })}></Input>
                                            </FormGroup>
                                        </Col>
                                    }
                                    {
                                        c.condition && !c.condition.includes('ariable') && c.condition !== 'hasMetadata' && <Col md="4">
                                            <FormGroup>
                                                <Label>Value</Label>
                                                <Input type="text" defaultValue={ c.value } onChange={(e) => setAction({
                                                    ...action,
                                                    conditions: [
                                                        ...action.conditions.filter((condition, index) => condition.order !== c.order),
                                                        {
                                                            ...c,
                                                            value: e.target.value
                                                        }
                                                    ]
                                                })}></Input>
                                            </FormGroup>
                                        </Col>
                                    }
                                    <Col md="4">
                                    <FormGroup>
                                        <Label>Weight</Label>
                                        <Input type="text" defaultValue={ c.weight } onChange={(e) => setAction({
                                                    ...action,
                                                    conditions: [
                                                        ...action.conditions.filter((condition, index) => condition.order !== c.order),
                                                        {
                                                            ...c,
                                                            weight: e.target.value
                                                        }
                                                    ]
                                                })}></Input>
                                    </FormGroup>
                                    </Col>
                                    </Row>
                                    <hr />
                                </Fragment>
                            })
                        }
                        <Button onClick={() => {
                            setAction({
                                ...action,
                                conditions: action.conditions ? [...action.conditions, {
                                    order: action.conditions.length,
                                    conditions: []
                                }] : [{
                                    order: 0,
                                    conditions: []
                                }]
                            });
                            setSteps([
                                ...steps.filter(s => s.order !== step.order),
                                {
                                    ...step,
                                    actions: step.actions ? [...step.actions.filter(a => a.order !== action.order), action] : [action]
                                }
                            ])
                            console.log(action.conditions ? [...action.conditions, {
                                order: action.conditions.length
                            }] : [{
                                order: 0,
                                conditions: []
                            }])
                        }
                        }>Add Condition</Button>
                    </>
                }
            </ModalBody>
            <ModalFooter>
                <Button onClick={() => {
                    setSteps([
                        ...steps.filter(s => s.order !== step.order),
                        {
                            ...step,
                            actions: step.actions ? [...step.actions.filter(a => a.order !== action.order), action] : [action]
                        }
                    ]);
                    toggleAcionModal();
            }}>Save</Button>
            </ModalFooter>
        </Modal>
        </>
    );
};

export default CreateWorkflow;
