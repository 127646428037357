import api from "../utils/api";

export async function getListObjectStorageBuckets() {
    const response = await api.v1.getListObjectStorageBuckets();
    return response.data;
}

export async function getListObjectStorageObjects(bucket, params = {}) {
    const response = await api.v1.getListObjectStorageObjects(bucket, params);
    return response.data;
}

export async function getDataStorageCatalogs() {
    const response = await api.v1.getDataStorageCatalogs();
    return response.data;
}

export async function getDataStorageCatalogSchemas(catalog) {
    const response = await api.v1.getDataStorageCatalogSchemas(catalog);
    return response.data;
}

export async function getDataStorageCatalogSchemaTables(catalog, schema) {
    const response = await api.v1.getDataStorageCatalogSchemaTables(catalog, schema);
    return response.data;
}

export async function getDataStorageCatalogSchemaTableDescribe(catalog, schema, table) {
    const response = await api.v1.getDataStorageCatalogSchemaTableDescribe(catalog, schema, table);
    return response.data;
}

export async function getDataStorageCatalogSchemaTableSources(catalog, schema, table) {
    const response = await api.v1.getDataStorageCatalogSchemaTableSources(catalog, schema, table);
    return response.data;
}

export async function downloadStorageCatalogSchemaTableSource(catalog, schema, table) {
    const response = await api.v1.downloadDataStorageCatalogSchemaTableSources(catalog, schema, table);
    return response.data;
}
