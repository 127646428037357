import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import WorkflowCRUD from "../../components/workflowCRUD/workflowCRUD";
const CopyWorkflow = () => {
    const { workflowid } = useParams();

    const [workflowData, setWorkflowData] = useState(undefined);
    useEffect(() => {
        var config = {
            method: "get",
            url: process.env.REACT_APP_API_LINK + "/workflow/" + workflowid,
        };

        axios(config)
            .then(function (response) {
                console.log(response.data);
                setWorkflowData(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    return (
        <>
            {workflowData && <WorkflowCRUD inputModel={workflowData} />}
            
        </>
    )
}

export default CopyWorkflow;