import React, { useContext, useEffect } from "react";
import { Button, ListGroupItem } from "reactstrap";
import { DataSelectedContext } from "../../contexts/datalake/datastorage";
import { store } from "../../redux";

const CatalogSchemaTables = ({ catalog, schema, table, sources }) => {
    function selectWsID(state) {
        return state.localStore.wsid;
    }

    const { dataSelected, setDataSelected } = useContext(DataSelectedContext);

    useEffect(() => {
        console.log(dataSelected);
    })

    const selectSource = (source) => {
        let wsid = selectWsID(store.getState());

        if (!isSourceSelected(source)) {
            let obj = {
                catalog,
                schema,
                table,
                source,
                name: source,
                ws_id: wsid,
            };
            setDataSelected((oldArray) => [...oldArray, obj]);
        }
    };

    const downloadSource = (source) => {

    };

    function isSourceSelected(source) {
        let isSelected = false;

        dataSelected.every((data) => {
            if (data.catalog === catalog && data.schema === schema && data.table === table && data.source === source) {
                isSelected = true;
                return false;
            }
            return true;
        });

        return isSelected;
    }

    const selectAll = () => {
        for(let i = 0; i < sources.length; i++) {
            selectSource(sources[i][0]);
        }
    }

    return (
        <>
            <h3>List of Datasets</h3>

            <Button color="primary" onClick={selectAll}>Select All Datasets</Button>

            {sources ? (
                sources.map((element, index) => (
                    <ListGroupItem className="card" key={index}>
                        <>
                            {element[0]}{ " " } 
                            <Button size='sm'
                                color={!isSourceSelected(element[0]) ? "info" : "warning"}
                                disabled={isSourceSelected(element[0])}
                                onClick={() => {
                                    selectSource(element[0]);
                                }}
                            >
                                Select Dataset
                            </Button>{" "}
                            <Button size='sm'
                                color="success"
                                onClick={() => {
                                    downloadSource(element[0]);
                                }}
                            >
                                Download Dataset
                            </Button>
                            
                        </>
                    </ListGroupItem>
                ))
            ) : (
                <>Loading... </>
            )}
        </>
    );
};

export default CatalogSchemaTables;
