import React, { useContext, useState } from "react";
import { Button, ListGroupItem, Accordion, AccordionBody, AccordionHeader, AccordionItem } from "reactstrap";
import "../../assets/css/Accordion.css";
import { ObjectSelectedContext } from "../../contexts/datalake/objectstorage";
import { DataSelectedContext } from "../../contexts/datalake/datastorage";

const ViewSelectedObjects = () => {
    const { objectSelected, setObjectSelected } = useContext(ObjectSelectedContext);
    const { dataSelected, setDataSelected } = useContext(DataSelectedContext);

    const [open, setOpen] = useState("");

    const toggle = (id) => {
        if (open === id) {
            setOpen("");
        } else {
            setOpen(id);
        }
    };

    function removeObjFromSelected(index) {
        setObjectSelected((oldArr) => [...oldArr.slice(0, index), ...oldArr.slice(index + 1)]);
    }

    function removeDataFromSelected(index) {
        setDataSelected((oldArr) => [...oldArr.slice(0, index), ...oldArr.slice(index + 1)]);
    }

    return (
        <div className="mb-3">
            <h3 className="mt-5">List of Selected Objects</h3>

            <Accordion flush open={open} toggle={toggle}>
                <AccordionItem key="0">
                    <AccordionHeader targetId="0">DataLake</AccordionHeader>
                    <AccordionBody accordionId="0">
                        {objectSelected.map((element, index) => (
                            <ListGroupItem className="card" key={index}>
                                <>
                                    {element?.object_name}
                                    <Button color="danger" onClick={() => removeObjFromSelected(index)}>
                                        remove
                                    </Button>
                                </>
                            </ListGroupItem>
                        ))}
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem key="1">
                    <AccordionHeader targetId="1">Parquet</AccordionHeader>
                    <AccordionBody accordionId="1">
                        {dataSelected.map((element, index) => (
                            <ListGroupItem className="card" key={index}>
                                <>
                                    {element?.name}
                                    <Button color="danger" onClick={() => removeDataFromSelected(index)}>
                                        remove
                                    </Button>
                                </>
                            </ListGroupItem>
                        ))}
                    </AccordionBody>
                </AccordionItem>
            </Accordion>
        </div>
    );
};

export default ViewSelectedObjects;
