import React, { useEffect, useState } from "react";
import { FormGroup, Label, Input, Button } from "reactstrap";
import { MoreVertical, Archive } from "react-feather";
import CustomDataTable from "../datatable";
import { metadataManager } from "../../common/metadatamanager";

const ListVariables = ({ callback }) => {
    const [text, setText] = useState("");
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalDataCount, setTotalDataCount] = useState(0);

    const columns = [
        {
            name: "ID",
            selector: (row) => row.variable_id,
            sortable: true,
        },
        {
            name: "Name",
            selector: (row) => row.name,
            sortable: false,
        },
        {
            name: "Actions",
            cell: (row) => {
                const Child = () => {
                    return (
                        <>
                            <Button color="primary" onClick={() => select(row)}>
                                select
                            </Button>
                        </>
                    );
                };
                return <Child />;
            },
            sortable: false,
        },
    ];

    function select(data) {
        callback(data);
    }

    async function getVariables(skip, limit) {
        const query = metadataManager
            .from("variables")
            .select("*", { count: "exact" })
            .range(skip, skip + limit - 1);

        if (text !== "") {
            query.or(`name.ilike.%${text.trim()}%, variable_id.ilike.%${text.trim()}%`);
        }

        const { count, data, error } = await query;

        if (error) {
            throw error;
        } else {
            return { data, count };
        }
    }

    async function getVariablesHandler() {
        setLoading(true);

        try {
            const { count, data } = await getVariables(0, 10);

            setTotalDataCount(count);
            setList(data);

            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        (async () => {
            await getVariablesHandler();
        })();
    }, []);

    return (
        <>
            {loading ? (
                <>
                    <div className="loader-container">
                        <div className="spinner" style={{ marginLeft: "-50%", marginTop: "-10%" }}></div>
                    </div>
                </>
            ) : (
                <>
                    <FormGroup>
                        <Label>Search variables</Label>
                        <Input
                            type="text"
                            name="search"
                            value={text}
                            placeholder="Type here if you want to search for a variable"
                            onChange={(e) => setText(e.target.value)}
                        />
                        <Button color="secondary" onClick={getVariablesHandler}>
                            search
                        </Button>
                    </FormGroup>

                    <CustomDataTable
                        data={list}
                        columns={columns}
                        tableName={"Variables from Metadata Manager"}
                        hasServerPagination={true}
                        getDataCallback={getVariables}
                        totalDataCount={totalDataCount}
                    />
                </>
            )}
        </>
    );
};

export default ListVariables;
