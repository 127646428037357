import React, { useEffect, useState } from "react";
import { Button, ListGroupItem } from "reactstrap";
import { INSTRUCTIONS } from "../utils/enum";
import { getQueryLabel } from "../../../utils/formatter";
import ListSelectedObjects from "./ListSelectedObjects";

const DataObjectMapFormWizard = ({
    num_of_files,
    labelList,
    fileTypes,
    objectStorageDataReferences,
    dataStorageDataReferences,
    callback,
    showProceed = true,
}) => {
    const [mode, setMode] = useState("");
    const [showNext, setShowNext] = useState(false);
    const [formStep, setFormStep] = useState(0);
    const [formLength, setFormLength] = useState(0);
    const [formObject, setFormObject] = useState({});
    const [parsedObjectStorageDataReferences, setParsedObjectStorageDataReferences] = useState([]);
    const [parsedDataStorageDataReferences, setParsedDataStorageDataReferences] = useState([]);

    useEffect(() => {
        setFormLength(num_of_files == -1 ? 1 : num_of_files);

        if (labelList.length > 0 && labelList.length == num_of_files) {
            setMode("ai");
        } else {
            setMode("free");
        }

        if (num_of_files == -1) {
            setShowNext(true);
        }
    }, []);

    useEffect(() => {
        if (objectStorageDataReferences) {
            let _array = [];
            objectStorageDataReferences.forEach((element) => {
                if (!("selected" in element)) {
                    element.selected = false;
                }
                _array.push(element);
            });
            setParsedObjectStorageDataReferences(_array);
        }
    }, [objectStorageDataReferences]);

    useEffect(() => {
        if (dataStorageDataReferences) {
            let _array = [];
            dataStorageDataReferences.forEach((element) => {
                if (!("selected" in element)) {
                    element.selected = false;
                }
                _array.push(element);
            });
            setParsedDataStorageDataReferences(_array);
        }
    }, [dataStorageDataReferences]);

    function _objInArray(list, name, element) {
        if (name == "datalake") {
            return (
                list[name].findIndex(
                    (x) => x.bucket_name == element.bucket_name && x.object_name == element.object_name
                ) !== -1
            );
        } else if (name == "trino") {
            return (
                list[name].findIndex(
                    (x) =>
                        x.catalog == element.catalog &&
                        x.schema_ == element.schema_ &&
                        x.table == element.table &&
                        x.name == element.name
                ) !== -1
            );
        }
    }

    function selectAndProceed(object, index) {
        const updatedObject = { ...object, selected: true };
        const oldObj = formObject;

        if (mode == "ai") {
            oldObj[labelList[formStep]] = object;
        } else if (mode == "free") {
            if ("bucket_name" in object) {
                if (Array.isArray(oldObj["datalake"])) {
                    if (!_objInArray(oldObj, "datalake", object)) {
                        oldObj["datalake"].push(object);
                    }
                } else {
                    oldObj["datalake"] = [object];
                }
            } else if ("catalog" in object) {
                if (Array.isArray(oldObj["trino"])) {
                    if (!_objInArray(oldObj, "trino", object)) {
                        oldObj["trino"].push(object);
                    }
                } else {
                    oldObj["trino"] = [object];
                }
            }
        }
        setFormObject(oldObj);

        if ("bucket_name" in object) {
            setParsedObjectStorageDataReferences((oldObj) => {
                const newObj = [...oldObj];
                newObj[index] = updatedObject;
                return newObj;
            });
        } else if ("catalog" in object) {
            setParsedDataStorageDataReferences((oldObj) => {
                const newObj = [...oldObj];
                newObj[index] = updatedObject;
                return newObj;
            });
        }

        if (!showNext) {
            goNext();
        }
    }

    function goNext() {
        let newStep = formStep;
        newStep += 1;
        setFormStep(newStep);

        if (newStep >= formLength && !showProceed) {
            submit();
        }
    }

    function goBack() {
        if (formStep > 0) {
            let newStep = formStep;
            newStep -= 1;
            setFormStep(newStep);
        }
    }

    function deselect(object, index) {
        const updatedObject = { ...object, selected: false };
        const oldObj = formObject;

        if (mode == "ai") {
            if (labelList[formStep] in formObject) {
                oldObj[labelList[formStep]] = oldObj[labelList[formStep]].filter((obj) => {
                    return !(obj.object_name == object.object_name && obj.bucket_name == object.bucket_name);
                });
            }
        } else if (mode == "free") {
            if ("bucket_name" in object) {
                if ("datalake" in formObject) {
                    oldObj["datalake"] = oldObj["datalake"].filter((obj) => {
                        return !(obj.object_name == object.object_name && obj.bucket_name == object.bucket_name);
                    });
                }
            } else if ("catalog" in object) {
                if ("trino" in formObject) {
                    oldObj["trino"] = oldObj["trino"].filter((obj) => {
                        return !(
                            obj.catalog == object.catalog &&
                            obj.schema_ == object.schema_ &&
                            obj.table == object.table &&
                            obj.name == object.name
                        );
                    });
                }
            }
        }

        setFormObject(oldObj);

        if ("bucket_name" in object) {
            setParsedObjectStorageDataReferences((oldObj) => {
                const newObj = [...oldObj];
                newObj[index] = updatedObject;
                return newObj;
            });
        } else if ("catalog" in object) {
            setParsedDataStorageDataReferences((oldObj) => {
                const newObj = [...oldObj];
                newObj[index] = updatedObject;
                return newObj;
            });
        }
    }

    function submit() {
        callback({ [INSTRUCTIONS.data_input]: formObject });
    }

    function parseObjectName(fullPathName) {
        const objects = fullPathName.split("/");
        const len = objects.length;

        if (objects[len - 1] === "") {
            return objects[len - 2];
        } else {
            return objects[len - 1];
        }
    }

    if (formStep >= 0 && formStep < formLength) {
        return (
            <div className="mb-3">
                {mode == "ai" ? (
                    <h4>
                        Please select an object to use as{" "}
                        <span className="font-weight-bold">{labelList[formStep]}</span>
                    </h4>
                ) : (
                    <h4>
                        Please select an object{" "}
                        <span className="font-weight-bold">
                            {formStep + 1}/{formLength}
                        </span>
                    </h4>
                )}
                {fileTypes.length > 0 && <p>Please choose one of these file types: {fileTypes.join(", ")}</p>}

                {parsedObjectStorageDataReferences &&
                    parsedObjectStorageDataReferences.map((element, index) => (
                        <ListGroupItem className="card" key={index}>
                            <>
                                {element?.query && <p>{getQueryLabel(element.query)}</p>}
                                <p style={{ fontWeight: "bold" }}>Name: {parseObjectName(element?.object_name)}</p>
                                <p>
                                    Bucket: {element?.bucket_name} <br />
                                    File path: {element?.object_name}
                                </p>

                                {element.selected ? (
                                    <Button color="secondary" onClick={() => deselect(element, index)}>
                                        deselect
                                    </Button>
                                ) : (
                                    <Button color="primary" onClick={() => selectAndProceed(element, index)}>
                                        select
                                    </Button>
                                )}
                            </>
                        </ListGroupItem>
                    ))}
                {parsedDataStorageDataReferences &&
                    parsedDataStorageDataReferences.map((element, index) => (
                        <ListGroupItem className="card" key={index}>
                            <>
                                {element.catalog}/{element.schema_}/{element.table}/{element.name}
                                {element.selected ? (
                                    <Button color="secondary" onClick={() => deselect(element, index)}>
                                        deselect
                                    </Button>
                                ) : (
                                    <Button color="primary" onClick={() => selectAndProceed(element, index)}>
                                        select
                                    </Button>
                                )}
                            </>
                        </ListGroupItem>
                    ))}

                {formStep > 0 && (
                    <Button color="danger" onClick={goBack}>
                        Back
                    </Button>
                )}
                {showNext && (
                    <Button color="secondary" onClick={goNext}>
                        View selected
                    </Button>
                )}
            </div>
        );
    } else if (formStep >= formLength) {
        return (
            <div className="mb-3">
                {mode == "ai" && <ListSelectedObjects objList={formObject} />}
                {mode == "free" && (
                    <ListSelectedObjects objList={formObject?.datalake ?? []} dataList={formObject?.trino ?? []} />
                )}
                <Button color="danger" onClick={goBack}>
                    Return
                </Button>
                {showProceed && (
                    <Button color="secondary" onClick={submit}>
                        Save
                    </Button>
                )}
            </div>
        );
    } else {
        return <></>;
    }
};

export default DataObjectMapFormWizard;
