import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
    getWorkflow,
    getNextSteps,
    runStep,
    runStepExclusive,
    runStepParallel,
    runStepComplete,
    runStepTaskSend,
    runCallActivity,
    runCallActivityComplete,
    getRun,
    runStepEvent,
    parseElementType,
    runStepExec,
    getNextStepsCompletedRun,
} from "../../common/workflow";
import { getModules, getCategoryModules } from "../../common/module";
import GatewayQueue from "./GatewayQueue";
import GatewaySelect from "./GatewaySelect";
import ScriptTask from "./ScriptTask";
import ManualTask from "./ManualTask";
import EndEvent from "./EndEvent";
import UserTask from "./UserTask";
import SendTask from "./SendTask";
import ReceiveTask from "./ReceiveTask";
import ListRunData from "../run/ListRunData";
import ListRunSteps from "../run/ListRunSteps";
import EditableLabel from "editable-label-react";
import api from "../../utils/api";
import CallActivity from "./CallActivity";

export default function Runs({ workflow_id, run_id }) {
    const [queue, setQueue] = useState([]);
    const [currentComponent, setCurrentComponent] = useState([]);
    const [stepid, setStepid] = useState("");

    const [runSteps, setRunSteps] = useState([]);
    const [runState, setRunState] = useState({});
    const [tasks, setTasks] = useState({});
    const [workflowStores, setWorkflowStores] = useState({});
    const [isRunCompleted, setIsRunCompleted] = useState(false);
    const [modal, setModal] = useState(false);
    const [workflowName, setWorkflowName] = useState("");
    const [modalProps, setModalProps] = useState({});
    const [text, setText] = useState(undefined);
    const [moduleCategories, setModuleCategories] = useState([]);

    const toggle = () => setModal(!modal);

    function checkForUncompletedStepTasks() {
        const uncompletedTasks = [];
        for (const task of runSteps) {
            if (!task?.completed) {
                uncompletedTasks.push(task);
            }
        }

        if (uncompletedTasks.length > 0) {
            return { exists: true, tasks: uncompletedTasks };
        }
        return { exists: false, task: null };
    }

    async function runNameCallback(run_name) {
        await api.v1.putRun(run_id, {
            name: run_name.trim(),
        });
    }

    async function checkIfStepsListHasPending(steps, pending) {
        let found = false;

        for (const step of steps) {
            if (step.id === pending.id && pending?.completed !== true) {
                found = true;
                break;
            }
        }

        if (!found) {
            await refreshWithNextSteps();
        }
    }

    async function taskCallbackWrapper(params) {
        let [pending, resQueue, resSteps, resState] = params;
        setStepid(pending.id);

        if (typeof resQueue == "undefined") {
            [pending, resQueue, resSteps, resState] = await getNextSteps(run_id);
        }

        if (resQueue.length === 0 && typeof pending != "undefined") {
            checkIfStepsListHasPending(resSteps, pending);
        }

        await setQueue(resQueue);
        setRunSteps(resSteps);
        setRunState(resState);
    }

    async function getRunSpecificTask(step_id) {
        taskCallbackWrapper(await runStep(run_id, step_id));
    }

    async function patchExclusiveCallback(next_step_id) {
        taskCallbackWrapper(await runStepExclusive(run_id, stepid, next_step_id));
    }

    async function patchParallelCallback(next_step_id) {
        taskCallbackWrapper(await runStepParallel(run_id, stepid, next_step_id));
    }
    // async function getUserCallback(next_step_id) {
    //     taskCallbackWrapper(await runStepParallel(run_id, stepid, next_step_id));
    // }

    function redirect(url) {
        toggle();
        window.location.replace(url);
    }

    async function patchTaskExecCallback(step_id, data) {
        let [pending, ] = await runStep(run_id, step_id);
        [pending, ] = await runStepExec(run_id, pending.id, data);
        setModalProps({
            id: pending.id,
            name: pending.name,
            redirectUrl: pending.metadata.url,
        });
        toggle();
    }

    async function patchCallActivityCallback(step_id, data = {}) {
        let [pending, ] = await runStep(run_id, step_id);
        [pending, ] = await runCallActivity(run_id, pending.id, data);
        setModalProps({
            id: pending.id,
            name: pending.name,
            redirectUrl: pending.metadata.url,
        });
        toggle();
    }

    async function forcePatchTaskCompleteCallback(step_id, data = {}) {
        taskCallbackWrapper(await runStepComplete(run_id, step_id, data));
    }

    async function patchCallActivityComplete(url, parent_run_id, parent_step_id) {
        await runStepComplete(parent_run_id, parent_step_id, {});
        await runCallActivityComplete(run_id);
        window.location.replace(url);
    }

    async function patchTaskSendCallback(step_id, data = {}) {
        let [pending, ] = await runStep(run_id, step_id);
        taskCallbackWrapper(await runStepTaskSend(run_id, pending.id, data));
    }

    async function patchTaskCompleteCallback(step_id) {
        let [pending, ] = await runStep(run_id, step_id);
        await forcePatchTaskCompleteCallback(pending.id);
    }

    async function patchTaskCompleteWithDataCallback(step_id, data = {}) {
        // const shouldContinue = await renderFeatureVariableModalAndGetUserChoice();

        // if (shouldContinue) {
        // }
        let [pending, ] = await runStep(run_id, step_id);
        await forcePatchTaskCompleteCallback(pending.id, data);
    }

    async function patchEventCallback(step_id) {
        const [pending, ] = await runStep(run_id, step_id);
        await runStepEvent(run_id, pending.id);
        //const response = 
        await getNextStepsCompletedRun(run_id);

        setIsRunCompleted(true);
    }

    async function refreshWithNextSteps() {
        let [pending, resQueue, resSteps] = await getNextSteps(run_id);

        if (pending) {
            setStepid(pending.id);
        } else {
            const run_details = await getRun(run_id);
            if (run_details.state.completed) {
                setIsRunCompleted(true);
            } else {
                setStepid(run_details.steps[run_details.state.step].id);
            }
        }

        setQueue(resQueue);
        setRunSteps(resSteps);
    }

    async function renderTasks(queue) {
        const ElementArray = [];

        for (const task of queue) {
            const type = parseElementType(task);

            if (type === "exclusive") {
                ElementArray.push({
                    type: "GatewayQueue",
                    data: {
                        title: "Please select one of the following",
                        tasks,
                        queue: task.or,
                        callback: patchExclusiveCallback,
                    },
                });
            } else if (type === "parallel") {
                ElementArray.push({
                    type: "GatewayQueue",
                    data: {
                        title: "Please select all of the tasks below",
                        tasks,
                        queue: task.and,
                        callback: patchParallelCallback,
                    },
                });
            } else {
                const type = tasks[task.sid].type;
                let text = "";
                let callback = () => {};
                let stores = [];
                let metadata = {};
                let variables = {};
                let modules = [];

                if ("class" in tasks[task.sid] && tasks[task.sid].class.length > 0) {
                    const classModule = moduleCategories.find((mod) => mod.code === tasks[task.sid].class[0]);
                    modules = (await getModules(0, 1000, { category: classModule.code })).data;
                }

                switch (type) {
                    case "ExclusiveGateway":
                        callback = getRunSpecificTask;
                        text = "Proceed to exclusive";
                        break;
                    case "ParallelGateway":
                        callback = getRunSpecificTask;
                        text = "Proceed to mandatory tasks";
                        break;
                    case "ScriptTask":
                        callback = patchTaskExecCallback;
                        break;
                    case "ManualTask":
                        callback = patchTaskCompleteCallback;
                        break;
                    case "UserTask":
                        callback = patchTaskCompleteWithDataCallback;
                        stores = tasks[task.sid]?.stores ?? [];
                        variables = runState?.variables ?? {};
                        break;
                    case "SendTask":
                        callback = patchTaskSendCallback;
                        break;
                    case "ReceiveTask":
                        callback = patchTaskCompleteWithDataCallback;
                        stores = tasks[task.sid].stores;
                        metadata = task.metadata;
                        break;
                    case "EndEvent":
                        callback = patchEventCallback;
                        break;
                    case "CallActivity":
                        callback = patchCallActivityCallback;
                        break;
                    default: 
                        break;
                }

                ElementArray.push({
                    type,
                    data: {
                        id: task.id,
                        name: task.name,
                        defaultActions: runState?.settings[task.sid]?.default ?? task?.default ?? {},
                        callback,
                        text,
                        stores,
                        workflow_id,
                        run_id,
                        metadata,
                        variables,
                        modules,
                    },
                });
            }
        }

        return ElementArray;
    }

    useEffect(() => {
        (async () => {
            let [_name, _tasks, _taskTypes, _workflowStores] = await getWorkflow(workflow_id);
            setTasks(_tasks);
            console.log(_taskTypes);
            setWorkflowStores(_workflowStores);

            setWorkflowName(_name);

            setModuleCategories((await getCategoryModules(0, 1000))?.data ?? []);

            let run_details = await getRun(run_id);

            setRunSteps(run_details.steps);
            setRunState(run_details.state);

            if (run_details.name) {
                setText(run_details.name);
            } else {
                setText("Give this run a name!");
            }

            await refreshWithNextSteps();
        })();
    });

    useEffect(() => {
        (async () => {
            let list = [];

            const { exists, tasks } = checkForUncompletedStepTasks();
            if (exists) {
                list = tasks;
            } else {
                if (queue && queue.length > 0) {
                    list = queue;
                }
            }

            setCurrentComponent(await renderTasks(list));
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queue]);

    if (isRunCompleted) {
        return (
            <>
                <h1>Run Completed!</h1>

                {runState?.settings?.parent && (
                    <>
                        <h3 className="mt-5">This WorkFlow was called by another one</h3>
                        {runState.settings.parent?.complete ? (
                            <>
                                <p>Please, press the button below to redirect</p>
                                <Button
                                    color="primary"
                                    onClick={() => window.location.replace(runState.settings.parent?.url)}
                                >
                                    Redirect
                                </Button>
                            </>
                        ) : (
                            <>
                                <p>Please, press the button below to mark it as complete and continue</p>
                                <Button
                                    color="success"
                                    onClick={() =>
                                        patchCallActivityComplete(
                                            runState.settings.parent?.url,
                                            runState.settings.parent?.run_id,
                                            runState.settings.parent?.step_id
                                        )
                                    }
                                >
                                    Complete and continue
                                </Button>
                            </>
                        )}
                    </>
                )}

                <ListRunData run_id={run_id} />

                <ListRunSteps run_id={run_id} tasks={tasks} runSteps={runSteps} />
            </>
        );
    } else {
        return (
            <>
                <h1>{workflowName}</h1>
                {text && (
                    <>
                        <EditableLabel
                            labelClassName="myLabelClass"
                            inputClassName="myInputClass"
                            inputWidth="400px"
                            inputHeight="25px"
                            // inputMaxLength="50"
                            labelFontWeight="bold"
                            inputFontWeight="bold"
                            value={text}
                            onChange={(e) => {
                                setText(e.target.value);
                            }}
                            // onFocus={t => console.log("focus", t)}
                            onFocusOut={() => {
                                runNameCallback(text);
                            }}
                        />
                    </>
                )}

                {currentComponent.map((task, key) => {
                    if (task.type === "GatewayQueue") {
                        return <GatewayQueue key={key} {...task.data} />;
                    } else if (task.type === "ExclusiveGateway" || task.type === "ParallelGateway") {
                        return <GatewaySelect key={key} {...task.data} />;
                    } else if (task.type === "ScriptTask") {
                        return <ScriptTask key={key} {...task.data} />;
                    } else if (task.type === "CallActivity") {
                        return <CallActivity key={key} {...task.data} />;
                    } else if (task.type === "ManualTask") {
                        return <ManualTask key={key} {...task.data} />;
                    } else if (task.type === "UserTask") {
                        return <UserTask key={key} workflowStores={workflowStores} {...task.data} />;
                    } else if (task.type === "SendTask") {
                        return <SendTask key={key} {...task.data} />;
                    } else if (task.type === "ReceiveTask") {
                        return (
                            <ReceiveTask
                                key={key}
                                workflowStores={workflowStores}
                                refreshCallback={refreshWithNextSteps}
                                {...task.data}
                            />
                        );
                    } else if (task.type === "EndEvent") {
                        return <EndEvent key={key} {...task.data} />;
                    }
                    else return null;
                })}
                <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle}>Execute {modalProps.name}</ModalHeader>
                    <ModalBody>The task has been initiated, but you shall redirect in order to complete it</ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => redirect(modalProps.redirectUrl)}>
                            Redirect
                        </Button>{" "}
                        <Button color="secondary" onClick={toggle}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </>
        );
    }
}
