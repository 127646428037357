import { Outlet } from "react-router-dom";
import Sidebar from "../components/sidebar/Sidebar";
import React, { Component } from "react";
import NavbarComponent from "../components/navbar/Navbar";
const AppLayout = () => {
    return (
        <div
            style={{
                padding: "50px 0px 0px 370px",
            }}
        >
            <div>
                <NavbarComponent />
                <Sidebar />
                <Outlet />
            </div>
        </div>
    );
};

export default AppLayout;
