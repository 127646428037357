import React, { useState, useEffect } from "react";
import axios from "axios";
import { ListGroup, ListGroupItem } from "reactstrap";
import RestoreWorfklowModal from "./Modals/RestoreWorkflow";
import api from "../../utils/api";
import CustomDataTable from "../../components/datatable";

const DeletedWorkflows = () => {
    const [workflowDeletedList, setWorkflowDeletedList] = useState([]);
    const [loading, setLoading] = useState(false);

    const columns = [
        {
            name: "Name",
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: "Description",
            selector: (row) => row.description,
            sortable: false,
        },
        {
            name: "Created At",
            cell: (row) => <>{row.created_at.split("T")[0]}</>,
            sortable: (row) => row.created_at,
        },
        {
            name: "Updated At",
            cell: (row) => <>{row.updated_at.split("T")[0]}</>,
            sortable: (row) => row.updated_at,
        },
        {
            name: "Actions",
            width: "300px",
            cell: (row) => {
                const Child = () => {
                    // const [quickEditModal, setQuickEditModal] = useState(false);
                    // const [deleteModal, setDeleteModal] = useState(false);

                    // const handleQuickEditModal = async () => {
                    //     setQuickEditModal(!quickEditModal);
                    // };

                    // const handleDeleteModal = async () => {
                    //     setDeleteModal(!deleteModal);
                    // };

                    return (
                        <>
                            <RestoreWorfklowModal
                                deletedworkflowid={row.id}
                                modaltitle="Restore"
                                buttoncolor="primary"
                                modalheader="Restore Workflow"
                                modalbody="Are you sure that you would like to restore that workflow?"
                                rightbutton="Yes"
                                callback={getDeletedWorkflowsCallback}
                            // restoreWorkflow={restoreWorkflow}
                            // onClick={handleToggleModal}
                            />


                        </>
                    );
                };
                return <Child />;
            },
            sortable: false,
        },
    ];


    async function getDeletedWorkflowsCallback() {
        setLoading(true);

        try {
            const workflows = await api.v1.getDeletedWorkflows();
            setWorkflowDeletedList(workflows.data.data)
            // console.log(workflows.data.data)
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        console.log("loading deleted workflows...");
        (async () => {
            await getDeletedWorkflowsCallback();
        })();
    }, []);

    return (
        <>
            {loading ? (
                <>
                    <div className="loader-container">
                        <div className="spinner" style={{ marginLeft: "-50%", marginTop: "-10%" }}></div>
                    </div>
                </>
            ) : (
                <>
                    <CustomDataTable data={workflowDeletedList} columns={columns} tableName={"Deleted Workflows"} />
                </>
            )}
        </>
    );
};

export default DeletedWorkflows;