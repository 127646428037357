import oauth_eps from "./oauth";
import module_eps from "./module";
import run_eps from "./run";
import storage_eps from "./storage";
import workflow_eps from "./workflow";
import feature_eps from "./feature";
import variable_eps from "./variable";
import workspace_eps from "./workspace";

/**
 * @typedef {Object} EndpointsApiV1
 * @property {string} EndpointsApiV1.base - Base Api Url
 */

/**
 * Get v1 api endpoint urls
 * @returns {EndpointsApiV1} endpoints
 */
const apiURLsV1 = (function ApiUrlsV1() {
    const BASE_URL = process.env.REACT_APP_API_LINK;

    return {
        /**
         * @returns {string}
         */
        base: BASE_URL,
        ...oauth_eps.v1,
        ...module_eps.v1,
        ...run_eps.v1,
        ...storage_eps.v1,
        ...workflow_eps.v1,
        ...feature_eps.v1,
        ...variable_eps.v1,
        ...workspace_eps.v1,
    };
})();

export default {
    v1: apiURLsV1,
};
