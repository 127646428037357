import React, { useEffect, useState } from "react";
import CustomDataTable from "../../components/datatable";
import { Button } from "reactstrap";
import AddNewModal from "../../components/addNewModal";
import modalTypes from "../../utils/modalTypes";
import ModuleModel from "./ModuleModel";
import { columns } from "./modulesDatatableData";
import { getModules } from "../../common/module";

const ListModules = () => {
    const [modules, setModules] = useState([]);

    const [createModal, setCreateModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalDataCount, setTotalDataCount] = useState(0);

    const handleCreateModal = async () => {
        setCreateModal(!createModal);
        setModules((await getModules(0, 10))?.data ?? []);
    };

    useEffect(() => {
        (async () => {
            setLoading(true);

            const mods = await getModules(0, 10);
            setModules(mods?.data ?? []);
            setTotalDataCount(mods?.count ?? 0);

            setLoading(false);
        })();
    }, []);

    return (
        <>
            {loading ? (
                <>
                    <div className="loader-container">
                        <div className="spinner" style={{ marginLeft: "-50%", marginTop: "-10%" }}></div>
                    </div>
                </>
            ) : (
                <>
                    <Button onClick={handleCreateModal} color="primary">
                        Create Module
                    </Button>
                    <CustomDataTable
                        data={modules}
                        columns={columns}
                        tableName={"Modules"}
                        hasServerPagination={true}
                        getDataCallback={getModules}
                        totalDataCount={totalDataCount}
                    />

                    <AddNewModal
                        title={"Create Module"}
                        open={createModal}
                        handleModal={handleCreateModal}
                        type={modalTypes.CREATE}
                        Model={ModuleModel}
                    />
                </>
            )}
        </>
    );
};

export default ListModules;
