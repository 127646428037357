/**
 * @typedef {Object} VariableURLs
 * @property {Object} VariableURLs.v1 - v1 urls
 * @property {string} VariableURLs.v1.variables - variable
 * @property {string} VariableURLs.v1.variable - variable/{variable_id}
 */

/**
 * Get variable endpoint urls
 * @returns {VariableURLs} endpoints
 */
const variable_eps = (function VariableURLs() {
    return {
        v1: {
            /**
             * @returns {string}
             */
            variables: () => `variable`,
            /**
             *
             * @param {string} variable_id
             * @returns {string}
             */
            variable: (variable_id) => `variable/${variable_id}`,
        },
    };
})();

export default variable_eps;
