import React, { useEffect, useState } from "react";
import { Button, Accordion, AccordionBody, AccordionHeader, AccordionItem } from "reactstrap";
import "../../assets/css/Accordion.css";
import { getRunStepMetadata } from "../../common/run";
import DataItem from "./DataItem";

const ListRunSteps = ({ run_id, tasks, runSteps }) => {
    const [parsedSteps, setParsedSteps] = useState([]);
    const [open, setOpen] = useState("");

    const toggle = (id) => {
        if (open === id) {
            setOpen("");
        } else {
            setOpen(id);
        }
    };

    useEffect(() => {
        (async () => {
            const parsedStepsList = [];

            if (runSteps.length > 0) {
                for (const step of runSteps) {
                    if (tasks[step.sid].type === "ScriptTask" || tasks[step.sid].type === "CallActivity") {
                        let type = "";
                        switch (tasks[step.sid].type) {
                            case "ScriptTask":
                                type = "Script";
                                break;
                            case "CallActivity":
                                type = "Nested Workflow";
                                break;

                            default:
                                break;
                        }

                        const metadata = await getRunStepMetadata(run_id, step.id);
                        let url = "";
                        if (tasks[step.sid].type === "CallActivity") {
                            url = metadata?.metadata?.url ?? "";
                        }
                        console.log(url, metadata);
                        parsedStepsList.push({
                            id: step.id,
                            type,
                            name: step.name,
                            datasets: metadata?.metadata?.datasets ?? [],
                            url,
                        });
                    }
                }
            }

            setParsedSteps(parsedStepsList);
        })();
    }, []);

    return (
        <div className="mb-3">
            <h3>List of executed steps</h3>

            <Accordion flush open={open} toggle={toggle}>
                {parsedSteps.map((step) => {
                    return (
                        <AccordionItem key={step.id}>
                            <AccordionHeader targetId={step.id}>
                                {step.type}: {step.name}
                            </AccordionHeader>
                            <AccordionBody accordionId={step.id}>
                                {step.datasets.map((dataset, index) => (
                                    <DataItem dataset={dataset} key={`${step.id}_${index}`} />
                                ))}

                                {step?.url && step?.url !== "" && (
                                    <Button color="success" onClick={() => window.location.replace(step?.url)}>
                                        Redirect to workflow
                                    </Button>
                                )}
                            </AccordionBody>
                        </AccordionItem>
                    );
                })}
            </Accordion>
        </div>
    );
};

export default ListRunSteps;
