import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./auth/auth-slice";
import localStoreSlice from "./token/localstore-slice";

export const store = configureStore({
    reducer: {
        auth: authSlice.reducer,
        localStore: localStoreSlice.reducer,
    },
});
