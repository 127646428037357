export var analytics_functions = [
    {
        name: 'auto_correlation',
        expected_input:	'1 EDF File or 1 CSV',
        output: 'info.json + plot.png',	
        type_of_analysis: 'Hypothesis/ EEG'
    },
    {
        name: "partial_auto_correlation",
        expected_input: "1 EDF File or 1 CSV",
        output: "info.json + plot.png",
        type_of_analysis: "Hypothesis/ EEG"
    },
    {
        name: "find_peaks",
        expected_input: "1 EDF File",
        output: "info.json",
        type_of_analysis: "EEG"
    },
    {
        name: "power_spectral_density_main",
        expected_input: "1 EDF File",
        output: "info.json + plot.png",
        type_of_analysis: "EEG"
    },
    {
        name: "stft",
        expected_input: "1 EDF File",
        output: "info.json + plot.png",
        type_of_analysis: "EEG"
    },
    {
        name: "predictions",
        expected_input: "1 EDF File",
        output: "info.json + plot.png",
        type_of_analysis: "EEG"
    },
    {
        name: "artifacts",
        expected_input: "1 EDF File",
        output: "info.json",
        type_of_analysis: "EEG"
    },
    {
        name: "asymmetry_indices",
        expected_input: "1 EDF File",
        output: "info.json",
        type_of_analysis: "EEG"
    },
    {
        name: "sleep_statistic",
        expected_input: "1 EDF File + 1 Hypnogram in CSV format or Group with same input",
        output: "info.json",
        type_of_analysis: "EEG"
    },
    {
        name: "spectogram_bandpower",
        expected_input: "1 EDF File + 1 Hypnogram in CSV format",
        output: "info.json + plot.png",
        type_of_analysis: "EEG"
    },
    {
        name: "slowwave",
        expected_input: "1 EDF File + 1 Hypnogram in CSV format or Group with same input",
        output: "info.json + plot.png",
        type_of_analysis: "EEG"
    },
    {
        name: "spindles",
        expected_input: "1 EDF File + 1 Hypnogram in CSV format or Group with same input",
        output: "info.json + plot.png",
        type_of_analysis: "EEG"
    },
    {
        name: "sleep_stage_classification",
        expected_input: "1 EDF File",
        output: "info.json + sleepstage.csv + sleepstageconfidence.csv + hypnogram.csv",
        type_of_analysis: "EEG"
    },
    {
        name: "manual_sleep_stage_classification",
        expected_input: "1 EDF File + (Optional 1 Hypnogram in CSV format )",
        output: "new_hypnogram.csv",
        type_of_analysis: "EEG"
    },
    {
        name: "group_sleep_sensitivity_analysis",
        expected_input: "Groups (1 EDF or FIF file + 1 Hypnogram in CSV Format, both with same name)",
        output: "several plots.png + several csv",
        type_of_analysis: "EEG"
    },
    {
        name: "back_average",
        expected_input: "1 EDF File",
        output: "several plots.png",
        type_of_analysis: "EEG"
    },
    {
        name: "eeg_viewer",
        expected_input: "1 EDF File",
        output: "info.json",
        type_of_analysis: "EEG"
    },
    {
        name: "envelop_trend_analysis",
        expected_input: "1 EDF File",
        output: "info.json",
        type_of_analysis: "EEG"
    },
    {
        name: "actigraphy_viewer",
        expected_input: "1 CSV File",
        output: "info.json",
        type_of_analysis: "Actigraphy"
    },
    {
        name: "actigraphy_viewer_general",
        expected_input: "1 CSV File",
        output: "info.json",
        type_of_analysis: "Actigraphy"
    },
    {
        name: "actigraphy_page",
        expected_input: "1 CSV File",
        output: "info.json",
        type_of_analysis: "Actigraphy"
    },
    {
        name: "actigraphy_metrics",
        expected_input: "1 CSV File",
        output: "info.json",
        type_of_analysis: "Actigraphy"
    },
    {
        name: "actigraphy_cosinor",
        expected_input: "1 CSV File",
        output: "info.json",
        type_of_analysis: "Actigraphy"
    },
    {
        name: "actigraphy_analysis",
        expected_input: "1 CSV File",
        output: "info.json",
        type_of_analysis: "Actigraphy"
    },
    {
        name: "",
        expected_input: "1 CSV File",
        output: "info.json",
        type_of_analysis: "Actigraphy"
    },
    {
        name: "",
        expected_input: "1 CSV File",
        output: "info.json",
        type_of_analysis: "Actigraphy"
    },
    {
        name: "",
        expected_input: "1 CSV File",
        output: "info.json",
        type_of_analysis: "Actigraphy"
    },
    {
        name: "actigraphy_masking",
        expected_input: "1 CSV File",
        output: "info.json",
        type_of_analysis: "Actigraphy"
    },
    {
        name: "mri_viewer",
        expected_input: "1 Nii File or 2 Nii Files",
        output: "info.json",
        type_of_analysis: "MRI"
    },
    {
        name: "free_surfer",
        expected_input: "1 Nii File or 2 Nii Files",
        output: "info.json + recon_all_results + samseg_results",
        type_of_analysis: "MRI"
    },
    {
        title: "Actigraphy Assessment Page",
        name: "actigraphy_page",
        expected_input: "1 CSV File",
        output: "info.json",
        type_of_analysis: "Actigraphy"
    },
    {
        title: "Actigraphy Metrics",
        name: "actigraphy_metrics",
        expected_input: "1 CSV File",
        output: "info.json",
        type_of_analysis: "Actigraphy"
    },
    {
        title: "Actigraphy Cosinor",
        name: "actigraphy_cosinor",
        expected_input: "1 CSV File",
        output: "info.json",
        type_of_analysis: "Actigraphy"
    },
    {
        title: "Actigraphy Analysis",
        name: "actigraphy_analysis",
        expected_input: "1 CSV File",
        output: "info.json",
        type_of_analysis: "Actigraphy"
    },
    {
        title: "Actigraphy Functional Linear Modelling",
        name: "",
        expected_input: "1 CSV File",
        output: "info.json",
        type_of_analysis: "Actigraphy"
    },
    {
        title: "Actigraphy Singular Spectrum Analysis",
        name: "",
        expected_input: "1 CSV File",
        output: "info.json",
        type_of_analysis: "Actigraphy"
    },
    {
        title: "Actigraphy Detrended Fluctuation Analysis",
        name: "",
        expected_input: "1 CSV File",
        output: "info.json",
        type_of_analysis: "Actigraphy"
    },
    {
        title: "Actigraphy Masking",
        name: "actigraphy_masking",
        expected_input: "1 CSV File",
        output: "info.json",
        type_of_analysis: "Actigraphy"
    },
    {
        title: "",
        name: "mri_viewer",
        expected_input: "1 Nii File or 2 Nii Files",
        output: "info.json",
        type_of_analysis: "MRI"
    },
    {
        title: "",
        name: "free_surfer",
        expected_input: "1 Nii File or 2 Nii Files",
        output: "info.json + recon_all_results + samseg_results",
        type_of_analysis: "MRI"
    },
    {
        title: "",
        name: "recon_all_results",
        expected_input: "Rar File with results from freesurfer_step",
        output: "info.json",
        type_of_analysis: "MRI"
    },
    {
        title: "",
        name: "samseg_results",
        expected_input: "Rar File with results from freesurfer_step",
        output: "info.json",
        type_of_analysis: "MRI"
    },
    {
        title: "",
        name: "level",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Z score",
        name: "general_stats_zscore",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Average",
        name: "general_stats_average",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Min",
        name: "general_stats_min",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Max",
        name: "general_stats_max",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Standard Deviation",
        name: "general_stats_Std",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Covariance Matrix",
        name: "general_stats_Cov",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Missing values Imputation",
        name: "valuesimputation",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Normality test  (Shapiro-Wilk, Kolmogorov-Smirnov,D’Agostino’s K^2, Jarque-Bera)",
        name: "normality",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Normality test Anderson",
        name: "normality_anderson",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Transform data  (Box-Cox, Yeo-Johnson, Log, Squared-root, Cube-root)",
        name: "data_transform",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Data Transformation for use in ANOVA  (O'Brien)",
        name: "data_transform_anova",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Homoscedasticity check (Levene, Bartlett, Fligner-Killeen)",
        name: "homoscedasticity",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Spearman Correlation",
        name: "spearman_correlation",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Pearson Correlation",
        name: "pearson_correlation",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Kendalltau Correlation",
        name: "kendalltau_correlation",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Biweight midcorrelation",
        name: "biweight_midcorrelation",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Percentage bend correlation",
        name: "percentage_bend_correlation",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Shepherd’s pi correlation",
        name: "shepherd_pi_correlation",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Skipped spearman correlation",
        name: "skipped_spearman_correlation",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Canonical Correlation",
        name: "canonical_correlation",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Point Biserial Correlation",
        name: "point_biserial_correlation",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Mediation Analysis",
        name: "mediation_analysis",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Structural Equation Models Optimization",
        name: "structural_equation_models_optimization",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Exploratory Factor Analysis extract latent structure",
        name: "exploratory_factor_analysis_extract_latent_structure",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Welch t-test",
        name: "welch_t_test",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Independent t-test",
        name: "independent_t_test",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "t-test on TWO RELATED samples of scores",
        name: "t_test_two_samples",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Mann-Whitney U rank test",
        name: "mann_whitney_u_rank",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Wilcoxon signed-rank test",
        name: "wilcoxon_signed_rank",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Alexander Govern test",
        name: "alexander_govern",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Kruskal-Wallis H-test",
        name: "kruskal_wallis_h",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "one-way ANOVA",
        name: "one_way_anova",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Wilcoxon rank-sum statistic",
        name: "wilcoxon_rank_statistic",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "One-way chi-square test",
        name: "one_way_chi_square",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Multiple Comparisons",
        name: "mutliple_comparisons",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Mixed ANOVA",
        name: "mixed_anova",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Ancova",
        name: "ancova",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Welch Anova",
        name: "welch_anova",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Anova Repeated Measures",
        name: "anova_rm",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "One and M Way Anova",
        name: "anova",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Pairwise tests",
        name: "pairwise_tests",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Classification analysis (LDA)",
        name: "LDA",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "KMeans",
        name: "KMeans",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Linear Regression",
        name: "linear_regression",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "ElasticNet",
        name: "ElasticNet",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Lasso Regression",
        name: "LassoRegression",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Generalized Estimating Equations",
        name: "GeneralizedEstimatingEquations",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Ridge Regression",
        name: "RidgeRegression",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "SGD Regression",
        name: "SGDRegression",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Huber Regression",
        name: "HuberRegression",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "LinearSVR",
        name: "LinearSVR",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "LinearSVC",
        name: "LinearSVC",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Logistic Regression Pinguin",
        name: "LogisticRegressionPinguin",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Logistic Regression Statsmodels",
        name: "LogisticRegressionStatsmodels",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Cox regression",
        name: "survivalanalysiscoxregression",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Linear Mixed Effects Model",
        name: "linearmixedeffectsmodel",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Granger Analysis",
        name: "GrangerAnalysis",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Poisson Regression",
        name: "Poison Regression",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Risk Ratio (frequencies)",
        name: "survivalanalysisriskratiosimple",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Risk Ratio",
        name: "survivalanalysisriskratiodataset",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Risk Difference (frequencies)",
        name: "survivalanalysisriskdifferencesimple",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Risk Difference",
        name: "survivalanalysisriskdifferencedataset",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Number needed to treat (frequencies)",
        name: "survivalanalysisNNTsimple",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Number needed to treat",
        name: "survivalanalysisNNTdataset",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Odds Ratio (frequencies)",
        name: "survivalanalysisoddsratiosimple",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Odds Ratio",
        name: "survivalanalysisoddsratiodataset",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Incidence rate ratio (frequencies)",
        name: "survivalanalysisincidencerateratiosimple",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Incidence rate ratio",
        name: "survivalanalysisincidencerateratiodataset",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Incidence rate difference (frequencies)",
        name: "survivalanalysisincidenceratedifferencesimple",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Incidence rate difference",
        name: "survivalanalysisincidenceratedifferencedataset",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Time Varying Covariates",
        name: "survivalanalysistimevaryingcovariates",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Kaplan-Meier",
        name: "survivalanalysiskaplanmeier",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Fisher Exact",
        name: "fisherexact",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "McNemar",
        name: "mcnemar",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Factor Analysis",
        name: "FactorAnalysis",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Choose Number of Factors",
        name: "ChooseFactors",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Principal Component Analysis",
        name: "principalcomponentanalysis",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "t-distributed Stochastic Neighbor Embedding",
        name: "tsne",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Friedman test for repeated measurements",
        name: "calculate_friedman_test_pinguin",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Independent Component Analysis",
        name: "fastica",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Multidimensional scaling",
        name: "multidimensional_scaling",
        expected_input: "At least 1 CSV File",
        output: "info.json",
        type_of_analysis: "Hypothesis"
    },
    {
        title: "Dataset Concatenation",
        name: 'datasetconcat',
        expected_input: "At least 2 CSV Files",
        output: "info.json",
        type_of_analysis: "Data Preparation"
    }
]