import { React, useEffect, useState } from "react";
import { Card, CardBody, CardTitle, CardText } from "reactstrap";
import { getRunObjectStorageDataReferences, getRunObjectStorageDataStoreReferences } from "../../common/workflow";
import { getRunCompletedTasks } from "../../common/run";
import InstructionsWizard from "../InstructionsWizard";
import ModuleSelector from "../module/ModuleSelector";
import { Button } from "reactstrap";

const ScriptTask = ({ id, name, callback, modules, run_id, defaultActions }) => {
    const [showModuleSelector, setShowModuleSelector] = useState(false);
    const [showSubmitButton, setShowSubmitButton] = useState(false);
    const [instructions, setInstructions] = useState({});
    const [objectSelected, setObjectSelected] = useState([]);
    const [parquetSelected, setParquetSelected] = useState([]);
    const [moduleName, setModuleName] = useState("");
    const [previousCompletedTaskSelected, setPreviousCompletedTaskSelected] = useState([]);
    const [showFormWizard, setShowFormWizard] = useState(false);

    function submit(obj) {
        obj.module = moduleName;
        callback(id, obj);
    }

    function setInstructionOfSelectedModule(object) {
        setInstructions(object?.instructions ?? {});

        setModuleName(object?.code ?? "");
    }

    useEffect(() => {
        (async () => {
            if (
                ("data_input_multiple" in instructions && instructions.data_input_multiple) ||
                ("num_of_files" in instructions && instructions.num_of_files !== 0)
            ) {
                setObjectSelected(await getRunObjectStorageDataReferences(run_id));
                setParquetSelected(await getRunObjectStorageDataStoreReferences(run_id));
            }

            if ("ref_completed_task" in instructions && instructions.ref_completed_task) {
                let params = { not_in_type: "ScriptTask" };

                if (moduleName.startsWith("mri/")) {
                    params.class_name = "mri/free_surfer";
                }

                setPreviousCompletedTaskSelected(await getRunCompletedTasks(run_id, params));
            }

            if (
                moduleName === "querybuilder" &&
                "data_input_statements" in instructions &&
                instructions.data_input_statements
            ) {
                const objects = await getRunObjectStorageDataReferences(run_id);

                const parsedObjects = objects.filter((object) => !!object?.query);

                if (parsedObjects.length > 0) {
                    setObjectSelected(parsedObjects);
                }
            }

            setShowFormWizard(true);
            setShowModuleSelector(false);
        })();
    }, [instructions]);

    useEffect(() => {
        const classModule = modules[0];

        if (classModule.code === "querybuilder") {
            setInstructionOfSelectedModule(classModule);
        } else {
            setShowModuleSelector(true);
        }
    }, []);

    return (
        <>
            <Card>
                <CardBody>
                    <CardTitle tag="h3">{name}</CardTitle>

                    <div>
                        {showModuleSelector && (
                            <ModuleSelector
                                listData={modules}
                                defaultTask={defaultActions?.module}
                                callback={setInstructionOfSelectedModule}
                            />
                        )}
                    </div>

                    <div>
                        {showFormWizard && (
                            <InstructionsWizard
                                instructions={instructions}
                                callback={submit}
                                objectStorageDataReferences={objectSelected}
                                dataStorageDataReferences={parquetSelected}
                                previousCompletedTaskSelected={previousCompletedTaskSelected}
                            />
                        )}
                    </div>

                    {showSubmitButton && (
                        <>
                            <CardText>Press button to execute this function</CardText>
                            <Button color="success" onClick={submit}>
                                Submit
                            </Button>
                        </>
                    )}
                </CardBody>
            </Card>
        </>
    );
};

export default ScriptTask;