import { createContext, useState } from "react";

export const DataSelectedContext = createContext();

const DataSelectedProvider = ({ children }) => {
    const [dataSelected, setDataSelected] = useState([]);

    return (
        <DataSelectedContext.Provider
            value={{
                dataSelected,
                setDataSelected,
            }}
        >
            {children}
        </DataSelectedContext.Provider>
    );
};

export default DataSelectedProvider;
