import { setToken, setPrevPath, setWsID } from "./localstore-slice";

export const initTokenAction = () => (dispatch) => {
    dispatch(setToken(localStorage.getItem("token")));
};

export const setTokenAction = (token) => (dispatch) => {
    localStorage.setItem("token", token);
    dispatch(setToken(token));
};

export const deleteTokenAction = () => (dispatch) => {
    localStorage.removeItem("token");
    dispatch(setToken(""));
};

export const initPrevPathAction = () => (dispatch) => {
    dispatch(setPrevPath(localStorage.getItem("prevPath")));
};

export const setPrevPathAction = (prevPath) => (dispatch) => {
    localStorage.setItem("prevPath", prevPath);
    dispatch(setPrevPath(prevPath));
};

export const setWsIDAction = (wsid) => (dispatch) => {
    localStorage.setItem("wsid", parseInt(wsid));
    dispatch(setWsID(parseInt(wsid)));
};

export const deletePrevPathAction = () => (dispatch) => {
    localStorage.removeItem("prevPath");
    dispatch(setPrevPath(""));
};
