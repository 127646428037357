import { useEffect, useState } from "react";
import { getModules, getCategoryModules } from "../../common/module";
import {
    Button,
    Form,
    FormGroup,
    Input,
    ListGroupItem,
    Label,
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from "reactstrap";
import "../../assets/css/Accordion.css";

const TaskSettings = ({ tasks, settings }) => {
    const [moduleCategories, setModuleCategories] = useState([]);
    const [modules, setModules] = useState({});
    const [open, setOpen] = useState("");

    function toggle(id) {
        if (open === id) {
            setOpen("");
        } else {
            setOpen(id);
        }
    }

    function handleSettingsVariable(key, value) {
        if (!Object.keys(settings).includes(key)) {
            settings[key] = { class: tasks[key].class };
        }

        settings[key].default = {
            module: value,
        };
    }

    function handleDefSettingsDA(e) {
        const value = e.target.value;
        const taskKey = e.target.dataset.key;

        if (value !== "") {
            handleSettingsVariable(taskKey, value);
        } else {
            if (Object.keys(settings).includes(taskKey)) {
                delete settings[taskKey];
            }
        }
    }

    useEffect(() => {
        (async () => {
            const rawModuleCategories = (await getCategoryModules(0, 1000))?.data ?? [];
            rawModuleCategories.map(async (cat) => {
                const mods = (await getModules(0, 1000, { category: cat.code })).data;

                setModules((oldMods) => {
                    return { ...oldMods, [cat.code]: mods };
                });
            });

            setModuleCategories(rawModuleCategories);
        })();
    }, []);

    return (
        <>
            <Accordion flush open={open} toggle={toggle}>
                {Object.keys(tasks).map((key) => {
                    const task = tasks[key];

                    if (task.type == "ScriptTask") {
                        if ("class" in task && task.class.length > 0 && task.class[0] == "dataanalytics") {
                            const modCategory = moduleCategories.find((mod) => mod.code == task.class[0]);

                            if (modCategory) {
                                const mods = modules[modCategory.code] ?? [];

                                function showDefaultModule(code) {
                                    for (const mod of mods) {
                                        if (mod.code == code) {
                                            handleSettingsVariable(key, code);
                                            return mod.name;
                                        }
                                    }
                                }

                                return (
                                    <AccordionItem key={key}>
                                        <AccordionHeader targetId={key}>
                                            Select default settings for {task?.name}
                                        </AccordionHeader>
                                        <AccordionBody accordionId={key}>
                                            <ListGroupItem>
                                                {task?.default?.module && (
                                                    <p>
                                                        The default module is "
                                                        {showDefaultModule(task?.default?.module)}"
                                                    </p>
                                                )}
                                                <FormGroup>
                                                    <Label for={`da_def_settings_${key}`}>Select default module</Label>
                                                    <Input
                                                        id={`da_def_settings_${key}`}
                                                        name={`da_def_settings_${key}`}
                                                        data-key={key}
                                                        type="select"
                                                        onChange={handleDefSettingsDA}
                                                    >
                                                        <option key="" value="">
                                                            Choose here
                                                        </option>

                                                        {mods.map((mod, index) => (
                                                            <option key={index} value={mod.code}>
                                                                <>{mod?.name}</>
                                                            </option>
                                                        ))}
                                                    </Input>
                                                </FormGroup>
                                            </ListGroupItem>
                                        </AccordionBody>
                                    </AccordionItem>
                                );
                            }
                        }
                    }
                })}
            </Accordion>
        </>
    );
};

export default TaskSettings;
