import { createContext, useState } from "react";

export const breadcrumbsContext = createContext();

const BreadcrumbsProvider = ({ children }) => {
    const [breadcrumbs, setBreadcrumbs] = useState([]);

    return (
        <breadcrumbsContext.Provider
            value={{
                breadcrumbs,
                setBreadcrumbs,
            }}
        >
            {children}
        </breadcrumbsContext.Provider>
    );
};

export default BreadcrumbsProvider;
