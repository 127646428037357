import React from "react";
import { useState, useEffect } from "react";
import Tasks from "../Tasks/Tasks";
import TaskEdit from "../Tasks/TaskEdit";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { useParams } from "react-router-dom";
import axios from "axios";

function Steps(props) {
    const { workflowid } = useParams();
    // if you wanna use Tasks
    const [taskakia, setTasks] = useState([]);

    useEffect(() => {
        console.log(workflowid);
        console.log("loading tasks...");
        var config = {
            method: "get",
            url: process.env.REACT_APP_API_LINK + "/workflow/" + workflowid,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        axios(config)
            .then(function (response) {
                console.log(response.data.tasks);
                console.log("finished preview tasks...");
                // setTasks('response.data.tasks');
                setTasks(response.data.tasks);
                console.log(taskakia);
            })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    //on toggle status
    // const onTglStatus = (task) => {
    //   console.log("completing task");
    //   setTasks(
    //       taskakia.map((chkTask) => {
    //         chkTask.complete =
    //           task.id === chkTask.id ? !chkTask.complete : chkTask.complete;
    //         return chkTask;
    //       })
    //   )
    // };
    //enable the button
    function conditionalTasksAction(e) {
        e.preventDefault();
        console.log("The conditionalTasksAction was clicked.");
    }
    function parallelTasksAction(e) {
        e.preventDefault();
        console.log("The parallelTasksAction was clicked.");
    }

    // saving the new tasks
    const onSaveTask = ({ desc, date }) => {
        console.log("saving tasks");
        // setTasks([
        //   { desc: desc, date: date, id: Date.now(), complete: false },
        // ]);
    };

    //ShowTaskEdit
    const [showTaskEdit, setShowTaskEdit] = useState(false);

    return (
        <>
            {/* Create new Task using setShowTaskEdit to keep task on state */}
            <h2>1.Create new Task here</h2>
            <Button className="btn button outline" onClick={() => setShowTaskEdit(!showTaskEdit)}>
                {!showTaskEdit && "Create new Task"}
                {showTaskEdit && "➖"}
            </Button>
            {showTaskEdit && <TaskEdit task={{}} onSaveTask={onSaveTask} />}
            {/* END OF TASK CREATION */}
            <h2>2.This is The component for Conditional Steps</h2>
            <Tasks tasks={taskakia}></Tasks>
            <Button disabled={"disabled"} onClick={conditionalTasksAction}>
                Proceed Task
            </Button>
            <h2>3.This is The component for Parallel Steps</h2>
            <div className="task">
                <div className="task-list">
                    <Tasks tasks={taskakia}></Tasks>
                </div>
                <Button disabled="disabled" onClick={parallelTasksAction}>
                    Proceed Task
                </Button>
            </div>
        </>
    );
}

export default Steps;
