import React, { useContext } from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { breadcrumbsContext } from "../../../contexts/breadcrumbsContext";

const BucketObjects = ({ linkAction }) => {
    const { breadcrumbs } = useContext(breadcrumbsContext);

    const callbackFunc = linkAction || console.error("missing callback");

    return (
        <Breadcrumb>
            {/* <BreadcrumbItem onClick={() => { routeChange() }}>All Buckets</BreadcrumbItem> */}
            {breadcrumbs.map((element, index, row) => {
                if (index + 1 === row.length) {
                    return <BreadcrumbItem key={index}>{element.name}</BreadcrumbItem>;
                } else {
                    return (
                        <BreadcrumbItem
                            key={index}
                            onClick={() => {
                                callbackFunc(element);
                            }}
                        >
                            {element.name}
                        </BreadcrumbItem>
                    );
                }
            })}
        </Breadcrumb>
    );
};

export default BucketObjects;
