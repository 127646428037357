import api from "../utils/api";
import { AlertHelper } from "../utils/alertHelper";

export async function getRunCompletedTasks(run_id, params = null) {
    let response = {};
    if (params == null) {
        response = await api.v1.getRunCompletedTasks(run_id);
    } else {
        response = await api.v1.getRunCompletedTasks(run_id, params);
    }

    return response.data;
}

export async function getRunStepMetadata(run_id, step_id) {
    try {
        const response = await api.v1.getRunStepMetadata(run_id, step_id);

        return {
            ...response.data.data,
        };
    } catch (error) {
        AlertHelper.showErrorAlert("Hmmm", error?.response?.data?.details ?? "Something Went Wrong. Please try again!");
    }
}
