import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Button } from "reactstrap";
import CustomDataTable from "../../components/datatable";
import { getWorkflowRuns, getWorkflow } from "../../common/workflow";

const ViewWorkflowRuns = () => {
    const { workflowid } = useParams();
    const [workflowName, setWorkflowName] = useState("");
    const [runList, setRunList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalDataCount, setTotalDataCount] = useState(0);

    const columns = [
        {
            name: "Name",
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: "Created At",
            cell: (row) => <>{new Date(row.created_at).toLocaleString()}</>,
            sortable: (row) => row.created_at,
        },
        {
            name: "Updated At",
            cell: (row) => <>{new Date(row.updated_at).toLocaleString()}</>,
            sortable: (row) => row.updated_at,
        },
        {
            name: "Actions",
            cell: (row) => {
                const Child = () => {
                    return (
                        <>
                            <Link to={"/workflow/" + workflowid + "/run/" + row.id}>
                                {row.state.completed == false ? (
                                    <>
                                        <Button color="success">Continue</Button>
                                    </>
                                ) : (
                                    <>
                                        <Button color="primary">View</Button>
                                    </>
                                )}
                            </Link>
                        </>
                    );
                };
                return <Child />;
            },
            sortable: false,
        },
    ];

    async function getRunsCallback() {
        setLoading(true);

        try {
            const runs = await getWorkflowRuns(0, 10, workflowid);
            setTotalDataCount(runs.count);
            setRunList(runs.data);

            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        (async () => {
            const [name] = await getWorkflow(workflowid);
            setWorkflowName(name);

            await getRunsCallback();
        })();
    }, []);

    return (
        <>
            {loading ? (
                <>
                    <div className="loader-container">
                        <div className="spinner" style={{ marginLeft: "-50%", marginTop: "-10%" }}></div>
                    </div>
                </>
            ) : (
                <>
                    <CustomDataTable
                        data={runList}
                        columns={columns}
                        tableName={`${workflowName}: List of runs`}
                        hasServerPagination={true}
                        getDataCallback={(skip, limit) => getWorkflowRuns(skip, limit, workflowid)}
                        totalDataCount={totalDataCount}
                    />
                </>
            )}
        </>
    );
};

export default ViewWorkflowRuns;
