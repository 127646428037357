import React from "react";
import { CardText, ListGroupItem } from "reactstrap";

const ListSelectedObjects = ({ objList, dataList }) => {
    return (
        <>
            {!Array.isArray(objList) &&
                Object.keys(objList).map((index) => {
                    return (
                        <ListGroupItem className="card" key={index}>
                            <>
                                <h5>Selected {index}:</h5>
                                <CardText>
                                    <span className="font-weight-bold">Bucket</span>: {objList[index].bucket_name}
                                </CardText>
                                <CardText>
                                    <span className="font-weight-bold">Object</span>: {objList[index].object_name}
                                </CardText>
                            </>
                        </ListGroupItem>
                    );
                })}

            {Array.isArray(objList) &&
                objList.map((obj, index) => {
                    return (
                        <ListGroupItem className="card" key={index}>
                            <>
                                <CardText>
                                    <span className="font-weight-bold">Bucket</span>: {obj.bucket_name}
                                </CardText>
                                <CardText>
                                    <span className="font-weight-bold">Object</span>: {obj.object_name}
                                </CardText>
                            </>
                        </ListGroupItem>
                    );
                })}

            {Array.isArray(dataList) &&
                dataList.map((obj, index) => {
                    return (
                        <ListGroupItem className="card" key={index}>
                            <>
                                <CardText>
                                    <span className="font-weight-bold">Catalog</span>: {obj.catalog}
                                </CardText>
                                <CardText>
                                    <span className="font-weight-bold">Schema</span>: {obj.schema_}
                                </CardText>
                                <CardText>
                                    <span className="font-weight-bold">Table</span>: {obj.table}
                                </CardText>
                                <CardText>
                                    <span className="font-weight-bold">Name</span>: {obj.name}
                                </CardText>
                            </>
                        </ListGroupItem>
                    );
                })}
        </>
    );
};

export default ListSelectedObjects;
