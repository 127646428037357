import endpoints from "../endpoints";
import { axios_v1 as axios } from "../axios";

/**
 * @typedef {Object} VariableAPIs
 * @property {Object} VariableAPIs.v1 - v1 api functions
 * @property {Promise} VariableAPIs.v1.getVariables - getVariables()
 * @property {Promise} VariableAPIs.v1.getVariable - getVariable(variable_id)
 * @property {Promise} VariableAPIs.v1.postVariable - postVariable(data)
 * @property {Promise} VariableAPIs.v1.putVariable - putVariable(variable_id, data)
 * @property {Promise} VariableAPIs.v1.deleteVariable - deleteVariable(variable_id)
 */

/**
 * Get variable API functions
 * @returns {VariableAPIs} api functions
 */
const variable_apis = (function VariableAPIs() {
    return {
        v1: {
            /**
             * @returns {promise}
             */
            getVariables: () => {
                return axios.get(endpoints.v1.variables());
            },
            /**
             * @param {string} variable_id
             * @returns {promise}
             */
            getVariable: (variable_id) => {
                return axios.get(endpoints.v1.variable(variable_id));
            },
            /**
             * @param {object} data
             */
            postVariable: (data) => {
                return axios.post(endpoints.v1.variables(), data);
            },
            /**
             * @param {string} variable_id
             * @param {object} data
             */
            putVariable: (variable_id, data) => {
                return axios.put(endpoints.v1.variable(variable_id), data);
            },
            /**
             * @param {string} variable_id
             * @returns {promise}
             */
            deleteVariable: (variable_id) => {
                return axios.delete(endpoints.v1.variable(variable_id));
            },
        },
    };
})();

export default variable_apis;
