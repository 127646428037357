import React from "react";
import { useParams } from "react-router-dom";

import WorkflowEngine from "../../components/wfengine/WorkflowEngine";

const Runs = () => {
    const { workflowid, run_id } = useParams();

    return (
        <>
            <WorkflowEngine workflow_id={workflowid} run_id={run_id} />
        </>
    );
};

export default Runs;
