import endpoints from "../endpoints";
import { axios_v1 as axios } from "../axios";

/**
 * @typedef {Object} RunAPIs
 * @property {Object} RunAPIs.v1 - v1 api functions
 * @property {Promise} RunAPIs.v1.postRunInit - postRunInit(workflow_id, data)
 * @property {Promise} RunAPIs.v1.getRunByID - getRunByID(run_id)
 * @property {Promise} RunAPIs.v1.getRunNext - getRunNext(run_id, data)
 * @property {Promise} RunAPIs.v1.getRunStep - getRunStep(run_id, step_id)
 * @property {Promise} RunAPIs.v1.putRun - putRun(id, data)
 * @property {Promise} RunAPIs.v1.patchRunStepExclusive - patchRunStepExclusive(run_id, step_id, next_step_id)
 * @property {Promise} RunAPIs.v1.patchRunStepParallel - patchRunStepParallel(run_id, step_id, next_step_id)
 * @property {Promise} RunAPIs.v1.patchRunStepTaskExec - patchRunStepTaskExec(run_id, step_id, data)
 * @property {Promise} RunAPIs.v1.patchRunStepTaskComplete - patchRunStepTaskComplete(run_id, step_id, data)
 * @property {Promise} RunAPIs.v1.patchRunStepCallActivity - patchRunStepCallActivity(run_id, step_id, data)
 * @property {Promise} RunAPIs.v1.patchRunCallActivityComplete - patchRunCallActivityComplete(run_id)
 * @property {Promise} RunAPIs.v1.patchRunStepEvent - patchRunStepEvent(run_id, step_id)
 * @property {Promise} RunAPIs.v1.patchRunStepSend - patchRunStepSend(run_id, step_id, data)
 * @property {Promise} RunAPIs.v1.getRunStepMetadata - getRunStepMetadata(run_id, step_id)
 * @property {Promise} RunAPIs.v1.getRunObjectStorageDataReferences - getRunObjectStorageDataReferences(run_id)
 * @property {Promise} RunAPIs.v1.getRunObjectStorageDataStoreReferences - getRunObjectStorageDataStoreReferences(run_id)
 * @property {Promise} RunAPIs.v1.getRunCompletedTasks - getRunCompletedTasks(run_id, params)
 */

/**
 * Get run API functions
 * @returns {RunAPIs} api functionss
 */
const run_api = (function RunAPIs() {
    return {
        v1: {
            /**
             * @param {string} workflow_id
             * @param {object} data
             * @returns {Promise}
             */
            postRunInit: (workflow_id, data = {}) => axios.post(endpoints.v1.runWorkflow(workflow_id), data),
            /**
             * @param {string} run_id
             * @returns {Promise}
             */
            getRunByID: (run_id) => axios.get(endpoints.v1.runID(run_id)),
            /**
             * @param {string} run_id
             * @returns {Promise}
             */
            getRunNext: (run_id) => axios.get(endpoints.v1.runNext(run_id)),
            /**
             * @param {string} run_id
             * @param {string} step_id
             * @returns {Promise}
             */
            getRunStep: (run_id, step_id) => axios.get(endpoints.v1.runStep(run_id, step_id)),
            /**
             * @param {string} run_id
             * @param {object} data
             * @returns {Promise}
             */
            putRun: (id, data) => {
                return axios.put(endpoints.v1.runID(id), data);
            },
            /**
             * @param {string} run_id
             * @param {string} step_id
             * @param {string} next_step_id
             * @returns {Promise}
             */
            patchRunStepExclusive: (run_id, step_id, next_step_id) =>
                axios.patch(endpoints.v1.runStepExclusive(run_id, step_id, next_step_id)),
            /**
             * @param {string} run_id
             * @param {string} step_id
             * @param {string} next_step_id
             * @returns {Promise}
             */
            patchRunStepParallel: (run_id, step_id, next_step_id) =>
                axios.patch(endpoints.v1.runStepParallel(run_id, step_id, next_step_id)),
            /**
             * @param {string} run_id
             * @param {string} step_id
             * @param {Object} data
             * @returns {Promise}
             */
            patchRunStepTaskExec: (run_id, step_id, data) =>
                axios.patch(endpoints.v1.runStepTaskExec(run_id, step_id), data),
            /**
             * @param {string} run_id
             * @param {string} step_id
             * @param {Object} data
             * @returns {Promise}
             */
            patchRunStepTaskComplete: (run_id, step_id, data) =>
                axios.patch(endpoints.v1.runStepTaskComplete(run_id, step_id), data),
            /**
             * @param {string} run_id
             * @param {string} step_id
             * @param {Object} data
             * @returns {Promise}
             */
            patchRunStepCallActivity: (run_id, step_id, data) =>
                axios.patch(endpoints.v1.runStepCallActivity(run_id, step_id), data),
            /**
             * @param {string} run_id
             * @returns {Promise}
             */
            patchRunCallActivityComplete: (run_id) => axios.patch(endpoints.v1.runCallActivityIsCompleted(run_id)),
            /**
             * @param {string} run_id
             * @param {string} step_id
             * @returns {Promise}
             */
            patchRunStepEvent: (run_id, step_id) => axios.patch(endpoints.v1.runStepEvent(run_id, step_id)),
            /**
             * @param {string} run_id
             * @param {string} step_id
             * @param {Object} data
             * @returns {Promise}
             */
            patchRunStepSend: (run_id, step_id, data) => axios.patch(endpoints.v1.runTaskSend(run_id, step_id), data),
            /**
             * @param {string} run_id
             * @param {string} step_id
             * @returns {Promise}
             */
            getRunStepMetadata: (run_id, step_id) => axios.get(endpoints.v1.runStepMetadata(run_id, step_id)),
            /**
             * @param {string} run_id
             * @returns {Promise}
             */
            getRunObjectStorageDataReferences: (run_id) => {
                return axios.get(endpoints.v1.listRunDataReferences(run_id));
            },
            /**
             * @param {string} run_id
             * @returns {Promise}
             */
            getRunObjectStorageDataStoreReferences: (run_id) => {
                return axios.get(endpoints.v1.listRunDataStoreReferences(run_id));
            },
            /**
             * @param {string} run_id
             * @param {object?} params
             * @returns {Promise}
             */
            getRunCompletedTasks: (
                run_id,
                params = {
                    class: "",
                    not_in_type: "",
                }
            ) => {
                return axios.get(endpoints.v1.listRunCompletedTasks(run_id), { params });
            },
        },
    };
})();

export default run_api;
