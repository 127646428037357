import { loginSuccess, logoutSuccess } from "./auth-slice";
import { setTokenAction, deleteTokenAction } from "../token/localstore-actions";
import api from "../../utils/api";

export const login = (callback) => async () => {
    try {
        const response = await api.v1.login();
        window.location.assign(response.data.url);
    } catch (error) {
        console.error(error);
    } finally {
        callback();
    }
};

export const loginCallback = (token, user, callback) => async (dispatch) => {
    dispatch(setTokenAction(token));
    dispatch(loginSuccess({ username: user }));
    callback();
};

export const logout = (callback) => async (dispatch) => {
    try {
        const response = await api.v1.logout();
        if (response.status == 200) {
            dispatch(deleteTokenAction());
            dispatch(logoutSuccess());
            localStorage.clear();
            callback();
        } else {
            throw response;
        }
    } catch (error) {
        console.error(error);
    }
};
