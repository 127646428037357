import endpoints from "../endpoints";
import { axios_v1 as axios } from "../axios";

/**
 * @typedef {Object} WorkflowAPIs
 * @property {Object} WorkflowAPIs.v1 - v1 api functions
 * @property {Promise} WorkflowAPIs.v1.getWorkflows - workflow(params?)
 * @property {Promise} WorkflowAPIs.v1.postWorkflow - postWorkflow(data)
 * @property {Promise} WorkflowAPIs.v1.putWorkflow - putWorkflow(id, data)
 * @property {Promise} WorkflowAPIs.v1.putWorkflowWorkspace - putWorkflowWorkspace(id, ws_id)
 * @property {Promise} WorkflowAPIs.v1.getDeletedWorkflows - getDeletedWorkflows(params?)
 * @property {Promise} WorkflowAPIs.v1.getWorkflowByID - getWorkflowByID(workflow_id)
 * @property {Promise} WorkflowAPIs.v1.getWorkflowTaskTypes - getWorkflowTaskTypes()
 * @property {Promise} WorkflowAPIs.v1.getWorkflowRun - getWorkflowRun(workflow_id)
 * @property {Promise} WorkflowAPIs.v1.getWorkflowCategories - getWorkflowCategories(params?)
 * @property {Promise} WorkflowAPIs.v1.postWorkflowCategories - postWorkflowCategories(data)
 * @property {Promise} WorkflowAPIs.v1.putWorkflowCategory - putWorkflowCategory(id, data)
 * @property {Promise} WorkflowAPIs.v1.deleteWorkflowCategory - deleteWorkflowCategory(id)
 */

/**
 * Get workflow API functions
 * @returns {WorkflowAPIs} api functionss
 */
const workflow_api = (function WorkflowAPIs() {
    return {
        v1: {
            /**
             * @param {object?} params
             * @returns {Promise}
             */
            getWorkflows: (
                params = {
                    skip: 0,
                    limit: 10,
                    order: "created_at",
                    direction: "desc",
                    is_template: false,
                    category: null,
                }
            ) => {
                return axios.get(endpoints.v1.workflows(), { params });
            },
            /**
             * @param {object} data
             * @returns {Promise}
             */
            postWorkflow: (data) => {
                return axios.post(endpoints.v1.workflows(), data);
            },
            /**
             * @param {string} id
             * @param {object} data
             * @returns {Promise}
             */
            putWorkflow: (id, data) => {
                return axios.put(endpoints.v1.workflowID(id), data);
            },
            /**
             * @param {string} id
             * @param {object} data
             * @returns {Promise}
             */
            putWorkflowWorkspace: (id, ws_id) => {
                return axios.put(endpoints.v1.workflowWorkspace(id), { ws_id });
            },
            getDeletedWorkflows: (
                params = {
                    skip: 0,
                    limit: 100,
                    order: "created_at",
                    direction: "desc",
                    is_template: false,
                    category: null,
                }
            ) => {
                return axios.get(endpoints.v1.deletedWorkflows(), { params });
            },
            /**
             * @param {string} workflow_id
             * @returns {Promise}
             */
            getWorkflowByID: (workflow_id) => axios.get(endpoints.v1.workflowID(workflow_id)),
            /**
             * @param {string} workflow_name
             * @returns {Promise}
             */
            getWorkflowByName: (workflow_name) => axios.get(`${endpoints.v1.workflowSearch()}?name=${workflow_name}`),
            /**
             * @returns {Promise}
             */
            getWorkflowTaskTypes: () => axios.get(endpoints.v1.workflowTaskTypes()),
            /**
             * @param {string} workflow_id
             * @returns {Promise}
             */
            getWorkflowRun: (
                workflow_id,
                params = {
                    skip: 0,
                    limit: 10,
                    order: "created_at",
                    direction: "desc",
                }
            ) => axios.get(endpoints.v1.workflowRuns(workflow_id), { params }),
            /**
             * @param {object?} params
             * @returns {Promise}
             */
            getWorkflowCategories: (
                params = {
                    skip: 0,
                    limit: 10,
                    order: "name",
                    direction: "asc",
                }
            ) => {
                return axios.get(endpoints.v1.workflowCategories(), { params });
            },
            /**
             * @param {object} data
             * @param {string} code
             */
            postWorkflowCategories: (data) => {
                return axios.post(endpoints.v1.workflowCategories(), data);
            },
            /**
             * @param {string} id
             * @param {object} data
             * @param {string} code
             */
            putWorkflowCategory: (id, data) => {
                return axios.put(endpoints.v1.workflowCategoryID(id), data);
            },
            /**
             * @param {string} id
             * @param {string} code
             */
            deleteWorkflowCategory: (id) => {
                return axios.delete(endpoints.v1.workflowCategoryID(id));
            },
        },
    };
})();

export default workflow_api;
