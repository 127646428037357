import React, { useEffect, useContext } from "react";
import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";
import { getListObjectStorageBuckets } from "../../common/datalake";
import { breadcrumbsContext } from "../../contexts/breadcrumbsContext";
import BucketObjects from "./BucketObjects";

const ObjectStorage = () => {
    const [buckets, setBuckets] = useState([]);
    const { breadcrumbs, setBreadcrumbs } = useContext(breadcrumbsContext);
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [currentBucket, setCurrentBucket] = useState(undefined);

    const handleClose = () => {
        document.querySelector("#root").style.display = "block";
        setShow(false);
    };
    const handleShow = () => {
        document.querySelector("#root").style.display = "none";

        setShow(true);
    };

    useEffect(() => {
        (async () => {
            setLoading(true);

            try {
                const bucketList = await getListObjectStorageBuckets();
                setBuckets(bucketList);
            } catch (error) {
                console.log(error);
            }
            setLoading(false);
        })();
    }, []);

    return (
        <>
            {loading ? (
                <>
                    <div className="loader-container">
                        <div className="spinner" style={{ marginLeft: "-50%", marginTop: "-10%" }}></div>
                    </div>
                </>
            ) : (
                <>
                    <h1>List of Buckets</h1>

                    {buckets.map((element, index) => (
                        <ListGroup.Item className="card" key={index}>
                            {element._name}

                            <Button
                                onClick={() => {
                                    setBreadcrumbs([
                                        {
                                            name: element._name,
                                            bucket: element._name,
                                            object: "",
                                            index: 0,
                                        },
                                    ]);
                                    handleShow();
                                    setCurrentBucket(element._name);
                                }}
                            >
                                Explore
                            </Button>
                        </ListGroup.Item>
                    ))}
                </>
            )}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Modal heading</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {currentBucket && (
                        <>
                            <BucketObjects bucketname={currentBucket} />
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ObjectStorage;
