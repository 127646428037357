import { React } from "react";
import { Card, CardBody, CardTitle, CardText, Button } from "reactstrap";
import { AlertHelper } from "../../utils/alertHelper";

export default ({ id, name, callback, workflowStores, stores, refreshCallback, metadata }) => {
    function checkForWfStores() {
        let workflowStoresKeys = Object.keys(workflowStores);

        if (stores.length > 0) {
            let storeFirstItemKey = Object.keys(stores[0])[0];
            let storeFirstItem = stores[0];

            if (workflowStoresKeys.includes(storeFirstItemKey)) {
                if (workflowStores[storeFirstItemKey].type == "DataObject") {
                    if (storeFirstItem[storeFirstItemKey].mode == "set") {
                        return {
                            mode: storeFirstItem[storeFirstItemKey].mode,
                            sid: storeFirstItemKey,
                            info: workflowStores[storeFirstItemKey],
                            exists: true,
                        };
                    }
                }
            }
        }

        return { exists: false };
    }

    function callbackMiddleware(element) {
        if (typeof metadata.object === "undefined") {
            callback(id);
            return;
        }

        const store = checkForWfStores();
        if (store.exists) {
            element.target.disabled = true;

            callback(id, {
                store: {
                    [store.sid]: {
                        [store.mode]: [
                            {
                                bucket_name: metadata.object.bucket_name,
                                object_name: metadata.object.object_name,
                            },
                        ],
                    },
                },
            });
        } else {
            AlertHelper.showErrorAlert("Store is not properly configured");
        }
    }

    return (
        <>
            <Card>
                <CardBody>
                    <CardTitle tag="h3">{name}</CardTitle>
                    {metadata &&
                        Object.keys(metadata).map((key) => {
                            if (key == "message") {
                                return <CardText key={key}>{metadata[key]}</CardText>;
                            } else if (key == "evaluation") {
                                return (
                                    <div key={key}>
                                        <h5>Evaluation metrics</h5>
                                        <ul>
                                            <li>
                                                <span className="font-weight-bold">Fit time</span>:{" "}
                                                {metadata[key].fit_time}
                                            </li>
                                            <li>
                                                <span className="font-weight-bold">Score time</span>:{" "}
                                                {metadata[key].score_time}
                                            </li>
                                            <li>
                                                <span className="font-weight-bold">
                                                    Train negative mean absolute error
                                                </span>
                                                : {metadata[key].train_neg_mean_absolute_error}
                                            </li>
                                            <li>
                                                <span className="font-weight-bold">
                                                    Train negative mean squared error
                                                </span>
                                                : {metadata[key].train_neg_mean_squared_error}
                                            </li>
                                            <li>
                                                <span className="font-weight-bold">Train r2</span>:{" "}
                                                {metadata[key].train_r2}
                                            </li>
                                            <li>
                                                <span className="font-weight-bold">
                                                    Test negative mean absolute error
                                                </span>
                                                : {metadata[key].test_neg_mean_absolute_error}
                                            </li>
                                            <li>
                                                <span className="font-weight-bold">
                                                    Test negative mean squared error
                                                </span>
                                                : {metadata[key].test_neg_mean_squared_error}
                                            </li>
                                            <li>
                                                <span className="font-weight-bold">Test r2</span>:{" "}
                                                {metadata[key].test_r2}
                                            </li>
                                        </ul>
                                    </div>
                                );
                            } else if (key == "object") {
                                return (
                                    <div key={key}>
                                        <h5>Object Details</h5>
                                        <ul>
                                            <li>
                                                <span className="font-weight-bold">Bucket</span>:{" "}
                                                {metadata[key].bucket_name}
                                            </li>
                                            <li>
                                                <span className="font-weight-bold">Object</span>:{" "}
                                                {metadata[key].object_name}
                                            </li>
                                            <li>
                                                <span className="font-weight-bold">URL (will expire soon)</span>:{" "}
                                                <a href="{metadata[key].url}" target="_blank">
                                                    {metadata[key].url}
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                );
                            } else if (key == "prediction") {
                                return (
                                    <div key={key}>
                                        <h5>Prediction array</h5>
                                        <CardText key={key}>{metadata[key]}</CardText>
                                    </div>
                                );
                            }
                        })}
                    <Button color="warning" onClick={refreshCallback}>
                        Refresh
                    </Button>
                    {metadata && (
                        <Button color="success" onClick={callbackMiddleware}>
                            Proceed
                        </Button>
                    )}
                </CardBody>
            </Card>
        </>
    );
};
