import React, { useState, useEffect } from "react";

import { Input, Label, Button, InputGroup, Form } from "reactstrap";

import { useForm, Controller } from "react-hook-form";

import { getCategoryModules } from "../../common/module";

import { createModule, updateModule, deleteModule } from "../../common/module";

import api from "../../utils/api";

const ModuleModel = ({ inputValues, isDeleteMode }) => {
    const [workflowEntityTypes, setWorkflowEntityTypes] = useState([]);

    isDeleteMode = isDeleteMode || false;
    let updatedValues = inputValues || {
        name: "",
        code: "",
        category_id: "",
        task: "",
        instructions: {},
    };

    const [moduleCategories, setModuleCategories] = useState([]);

    const {
        control,
        setError,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({ updatedValues });

    useEffect(() => {
        setValue("name", updatedValues.name);
        setValue("code", updatedValues.code);
        setValue("category_id", updatedValues.category_id);
        setValue("task", updatedValues.task);
        setValue("instructions", JSON.stringify(updatedValues.instructions));
    }, [updatedValues]);

    useEffect(() => {
        (async () => {
            const categories = (await getCategoryModules(0, 1000))?.data;
            setModuleCategories(categories);

            const workflowTaskTypes = (await api.v1.getWorkflowTaskTypes())?.data;

            const tmp = [];
            Object.keys(workflowTaskTypes).forEach(function (key, index) {
                workflowTaskTypes[key].forEach((element) => {
                    tmp.push(element);
                });
            });
            setWorkflowEntityTypes(tmp);
        })();
    }, []);

    const onSubmit = (data) => {
        if (Object.values(data).every((field) => field.length > 0 || field.toString().length > 0)) {
            const processedData = {
                name: data.name,
                task: data.task,
                instructions: JSON.parse(data.instructions),
                code: data.code,
                category_id: data.category_id,
            };

            let callbackStatus = false;
            inputValues == undefined
                ? (callbackStatus = createModule(processedData))
                : (callbackStatus = updateModule(inputValues.id, processedData));
            // (inputValues != undefined) && callbackStatus ? AlertHelper.showSuccessAlert('Good job!', 'New Module Category Created!') : AlertHelper.showSuccessAlert('Good job!', 'Module Category was successfully updated!');
        } else {
            for (const key in data) {
                if (data[key].length === 0) {
                    setError(key, {
                        type: "manual",
                    });
                }
            }
        }
    };

    return (
        <React.Fragment>
            <Form className="mt-2 pt-50" onSubmit={handleSubmit(onSubmit)}>
                {!isDeleteMode ? (
                    <>
                        <div className="mb-1">
                            <Label className="form-label" for="name">
                                Name
                            </Label>
                            <Controller
                                name="name"
                                control={control}
                                render={({ field }) => (
                                    <InputGroup>
                                        <Input
                                            id="name"
                                            name="name"
                                            placeholder="Module Name..."
                                            invalid={errors.name && true}
                                            {...field}
                                        />
                                    </InputGroup>
                                )}
                            />
                            {errors.name && (
                                <span style={{ color: "red" }}>
                                    Please enter a valid Module Name
                                    <br />
                                </span>
                            )}

                            <Label className="form-label" for="code">
                                Code
                            </Label>
                            <Controller
                                name="code"
                                control={control}
                                render={({ field }) => (
                                    <InputGroup>
                                        <Input
                                            id="code"
                                            name="code"
                                            placeholder="Code..."
                                            invalid={errors.code && true}
                                            {...field}
                                        />
                                    </InputGroup>
                                )}
                            />
                            {errors.code && (
                                <span style={{ color: "red" }}>
                                    Please enter a valid Code
                                    <br />
                                </span>
                            )}

                            <Label className="form-label" for="category_id">
                                Code
                            </Label>
                            <Controller
                                name="category_id"
                                control={control}
                                render={({ field }) => (
                                    <InputGroup>
                                        <Input
                                            id="category_id"
                                            name="category_id"
                                            type="select"
                                            defaultValue=""
                                            invalid={errors.category_id && true}
                                            {...field}
                                        >
                                            <option value="" disabled hidden>
                                                Choose here
                                            </option>

                                            {moduleCategories &&
                                                moduleCategories.map((category) => (
                                                    <option key={category.id} value={category.id}>
                                                        {category.name}
                                                    </option>
                                                ))}
                                        </Input>
                                    </InputGroup>
                                )}
                            />
                            {errors.category_id && (
                                <span style={{ color: "red" }}>
                                    Please select category
                                    <br />
                                </span>
                            )}

                            <Label className="form-label" for="task">
                                Task
                            </Label>
                            <Controller
                                name="task"
                                control={control}
                                render={({ field }) => (
                                    <InputGroup>
                                        <Input
                                            id="task"
                                            name="task"
                                            type="select"
                                            defaultValue=""
                                            invalid={errors.task && true}
                                            {...field}
                                        >
                                            <option value="" disabled hidden>
                                                Choose here
                                            </option>

                                            {workflowEntityTypes.map((workflowEntity, i) => (
                                                <option key={i} value={workflowEntity}>
                                                    {workflowEntity}
                                                </option>
                                            ))}
                                        </Input>
                                    </InputGroup>
                                )}
                            />
                            {errors.task && (
                                <span style={{ color: "red" }}>
                                    Please select task
                                    <br />
                                </span>
                            )}

                            <Label className="form-label" for="instructions">
                                Instructions
                            </Label>
                            <Controller
                                name="instructions"
                                control={control}
                                render={({ field }) => (
                                    <InputGroup>
                                        <Input
                                            id="instructions"
                                            name="instructions"
                                            type="textarea"
                                            placeholder="Instructions..."
                                            invalid={errors.instructions && true}
                                            {...field}
                                        />
                                    </InputGroup>
                                )}
                            />
                            {errors.instructions && (
                                <span style={{ color: "red" }}>
                                    Please enter valid Instructions
                                    <br />
                                </span>
                            )}
                        </div>
                        <Button className="me-1" color="primary" type="submit">
                            Submit
                        </Button>
                    </>
                ) : (
                    <>
                        <span>Are you sure you want to delete the selected entry?</span> <br />
                        <br />
                        <Button
                            color="danger"
                            onClick={() => {
                                deleteModule(inputValues.id);
                            }}
                        >
                            Confirm
                        </Button>
                    </>
                )}
            </Form>
        </React.Fragment>
    );
};

export default ModuleModel;
