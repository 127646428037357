import React, { useEffect, useContext, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";
import { getDataStorageCatalogs, getDataStorageCatalogSchemas } from "../../common/datalake";
import { breadcrumbsContext } from "../../contexts/breadcrumbsContext";
import CatalogSchemas from "./CatalogSchemas";

const Catalogs = () => {
    const [schemas, setSchemas] = useState([]);
    const { breadcrumbs, setBreadcrumbs } = useContext(breadcrumbsContext);
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [currentCatalog, setCurrentCatalog] = useState(undefined);
    const [currentSchema, setCurrentSchema] = useState(undefined);

    const handleClose = () => {
        document.querySelector("#root").style.display = "block";
        setShow(false);
    };
    const handleShow = () => {
        document.querySelector("#root").style.display = "none";

        setShow(true);
    };

    useEffect(() => {
        (async () => {
            setLoading(true);

            try {
                const catalogList = await getDataStorageCatalogs();
                let catalog = "";
                for (const cat of catalogList.data) {
                    if (cat[0].trim() == "iceberg") {
                        catalog = "iceberg";
                    }
                }
                if (catalog !== "") {
                    const schemaList = await getDataStorageCatalogSchemas(catalog);
                    setSchemas(schemaList.data);
                    setCurrentCatalog(catalog);
                }
            } catch (error) {
                console.log(error);
            }

            setBreadcrumbs([]);

            setLoading(false);
        })();
    }, []);

    return (
        <>
            {loading ? (
                <>
                    <div className="loader-container">
                        <div className="spinner" style={{ marginLeft: "-50%", marginTop: "-10%" }}></div>
                    </div>
                </>
            ) : (
                <>
                    <h1>List of Catalogs</h1>

                    {schemas.map((element, index) => (
                        <ListGroup.Item className="card" key={index}>
                            {element[0]}

                            <Button
                                onClick={() => {
                                    handleShow();
                                    setCurrentSchema(element[0]);
                                }}
                            >
                                Explore
                            </Button>
                        </ListGroup.Item>
                    ))}
                </>
            )}
            <Modal show={show} onHide={handleClose}>
                <Modal.Body>
                    {currentCatalog && (
                        <>
                            <CatalogSchemas catalogName={currentCatalog} schemaName={currentSchema} />
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Catalogs;
