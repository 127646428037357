import api from "../utils/api";
import { getAllGeneric, postGeneric, putGeneric, deleteGeneric } from "../utils/crud";

export async function getCategoryModules(skip, per_page, params = {}) {
    const parameters = {
        skip: skip,
        limit: per_page,
        order: "created_at",
        direction: "desc",
        ...params,
    };
    return await getAllGeneric(api.v1.getCategoryModules, parameters);
}

export const createModuleCategory = async (data) => {
    await postGeneric(api.v1.postCategoryModule, data);
};

export const updateModuleCategory = async (id, data) => {
    await putGeneric(api.v1.putCategoryModule, id, data);
};

export const deleteModuleCategory = async (id) => {
    await deleteGeneric(api.v1.deleteCategoryModule, id);
};

export async function getModules(skip, per_page, params = {}) {
    const parameters = {
        skip: skip,
        limit: per_page,
        order: "created_at",
        direction: "desc",
        category: null,
        ...params,
    };
    return await getAllGeneric(api.v1.getModules, parameters);
}

export const createModule = async (data) => {
    await postGeneric(api.v1.postModule, data);
};

export const updateModule = async (id, data) => {
    await putGeneric(api.v1.putModule, id, data);
};

export const deleteModule = async (id) => {
    await deleteGeneric(api.v1.deleteModule, id);
};
