import React, { useEffect, useContext, useState } from "react";

import { Button } from "reactstrap";
import { ListGroup, ListGroupItem } from "reactstrap";

import { breadcrumbsContext } from "../../contexts/breadcrumbsContext";
import { ObjectSelectedContext } from "../../contexts/datalake/objectstorage";
import BreadCrumbs from "./BreadCrumbs";
import { getListObjectStorageObjects } from "../../common/datalake";
import { AlertHelper } from "../../utils/alertHelper";
import { store } from "../../redux";

const BucketObjects = ({ bucketname }) => {
    const [objects, setObjects] = useState([]);
    const [folderSelected, setFolderSelected] = useState({
        name: "",
        bucket: "",
        object: "",
        index: 0,
    });
    const { breadcrumbs, setBreadcrumbs } = useContext(breadcrumbsContext);
    const { objectSelected, setObjectSelected } = useContext(ObjectSelectedContext);

    function selectWsID(state) {
        return state.localStore.wsid;
    }

    useEffect(() => {
        (async () => {
            const objList = await getListObjectStorageObjects(bucketname);
            setObjects(objList);
            setFolderSelected({
                name: "",
                _bucket_name: bucketname,
                _object_name: "",
            });
        })();
    }, []);

    function parseObjectName(fullPathName) {
        const objects = fullPathName.split("/");
        const len = objects.length;

        if (objects[len - 1] === "") {
            return objects[len - 2];
        } else {
            return objects[len - 1];
        }
    }

    const folderNavigation = async (element) => {
        try {
            const objList = await getListObjectStorageObjects(element._bucket_name, { obj_name: element._object_name });

            setFolderSelected({
                name: parseObjectName(element._object_name),
                _bucket_name: element._bucket_name,
                _object_name: element._object_name,
            });
            setBreadcrumbs((oldArray) => [
                ...oldArray,
                {
                    name: parseObjectName(element._object_name),
                    bucket: element._bucket_name,
                    object: element._object_name,
                    index: oldArray.length,
                },
            ]);
            setObjects(objList);
        } catch (error) {
            console.log(error);
        }
    };

    const breadcrumbsFunc = async (element) => {
        let objList;
        if (element.index === 0) {
            objList = await getListObjectStorageObjects(element.bucket);
        } else {
            objList = await getListObjectStorageObjects(element.bucket, { obj_name: element.object });
        }
        setObjects(objList);
        setBreadcrumbs((oldArray) => oldArray.slice(0, element.index + 1));
    };

    const selectFoldersContent = async (element) => {
        try {
            const objList = await getListObjectStorageObjects(element._bucket_name, { obj_name: element._object_name });

            const group_name = parseObjectName(element._object_name);

            objList.forEach((obj) => {
                if (obj._object_name.slice(-1) != "/") {
                    selectObject(obj, group_name);
                }
            });

            AlertHelper.showSuccessAlert("Folder Content", "Content added successfully");
        } catch (error) {
            AlertHelper.showErrorAlert("Folder Content", "Unexpected error");
        }
    };

    const selectObject = (element, group_name = "") => {
        if (!isObjectSelected(element)) {
            let obj = {
                bucket_name: element._bucket_name,
                object_name: element._object_name,
                ws_id: selectWsID(store.getState()),
                group_name,
            };
            setObjectSelected((oldArray) => [...oldArray, obj]);
        }
    };

    function isObjectSelected(element) {
        let isSelected = false;

        objectSelected.every((object) => {
            if (object.bucket_name === element._bucket_name && object.object_name === element._object_name) {
                isSelected = true;
                return false;
            }
            return true;
        });

        return isSelected;
    }

    return (
        <>
            <BreadCrumbs linkAction={breadcrumbsFunc} />
            <h3 style={{ marginBottom: "2rem" }}>
                {folderSelected.name != "" && <>List of {folderSelected.name} </>}
                <Button
                    style={{ fontSize: "medium", display: "inline" }}
                    color="info"
                    onClick={() => {
                        selectFoldersContent(folderSelected);
                    }}
                >
                    Select all
                </Button>
            </h3>

            {objects.map((element, index) => (
                <ListGroupItem className="card" key={index}>
                    {element._object_name.slice(-1) == "/" ? (
                        <>
                            {parseObjectName(element._object_name)}/
                            <Button
                                onClick={() => {
                                    folderNavigation(element);
                                }}
                            >
                                Open Folder
                            </Button>
                        </>
                    ) : (
                        <>
                            {parseObjectName(element._object_name)}
                            <Button
                                color="info"
                                onClick={() => {
                                    selectObject(element);
                                }}
                            >
                                Select Object
                            </Button>
                        </>
                    )}
                </ListGroupItem>
            ))}
        </>
    );
};

export default BucketObjects;
