import React, { useEffect, useState } from "react";
import api from "../../utils/api";
import { Badge, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from "reactstrap";
import { MoreVertical, Edit, FileText, Archive, Trash, X } from "react-feather";
import AddNewModal from "../../components/addNewModal";
import modalTypes from "../../utils/modalTypes";
import WorkflowCategoryModel from "./wfCategoryModel";

export const columns = [
    {
        name: "Name",
        selector: (row) => row.name,
        sortable: true,
    },
    {
        name: "Code",
        selector: (row) => row.code,
        sortable: false,
    },
    {
        name: "Created At",
        cell: (row) => <>{row.created_at.split("T")[0]}</>,
        sortable: (row) => row.created_at,
    },
    {
        name: "Updated At",
        cell: (row) => <>{row.updated_at.split("T")[0]}</>,
        sortable: (row) => row.updated_at,
    },
    {
        name: "Actions",
        cell: (row) => {
            const Child = () => {
                const [quickEditModal, setQuickEditModal] = useState(false);
                const [deleteModal, setDeleteModal] = useState(false);

                const handleQuickEditModal = async () => {
                    setQuickEditModal(!quickEditModal);
                };

                const handleDeleteModal = async () => {
                    setDeleteModal(!deleteModal);
                };

                return (
                    <>
                        <UncontrolledDropdown>
                            <DropdownToggle className="pe-1" tag="span">
                                <MoreVertical size={15} />
                            </DropdownToggle>
                            <DropdownMenu end>
                                <DropdownItem tag="a" className="w-100" onClick={handleQuickEditModal}>
                                    <Archive size={15} />
                                    <span className="align-middle ms-50">Edit</span>
                                </DropdownItem>
                                <DropdownItem tag="a" className="w-100" onClick={handleDeleteModal}>
                                    <Trash size={15} />
                                    <span className="align-middle ms-50">Delete</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>

                        <AddNewModal
                            title={"Edit Workflow Category"}
                            open={quickEditModal}
                            handleModal={handleQuickEditModal}
                            type={modalTypes.UPDATE}
                            Model={WorkflowCategoryModel}
                            inputModel={row}
                            closeModalCallback={() => {
                                window.location.reload();
                            }}
                        />

                        <AddNewModal
                            title={"Delete Worfklow Category"}
                            open={deleteModal}
                            handleModal={handleDeleteModal}
                            type={modalTypes.DELETE}
                            Model={WorkflowCategoryModel}
                            inputModel={row}
                            closeModalCallback={() => {
                                window.location.reload();
                            }}
                        />
                    </>
                );
            };
            return <Child />;
        },
        sortable: false,
    },
];
