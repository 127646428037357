import { Outlet } from "react-router-dom";
import Sidebar from "../components/sidebar/Sidebar";
import React, { Component } from "react";

const LoginLayout = () => {
    return (
        <div>
            <div>
                <Outlet />
            </div>
        </div>
    );
};

export default LoginLayout;
