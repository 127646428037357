import React, { useEffect, useContext, useState } from "react";
import { breadcrumbsContext } from "../../contexts/breadcrumbsContext";
import BreadCrumbs from "./BreadCrumbs";

import CatalogSchemaTables from "./CatalogSchemaTables";

import { getDataStorageCatalogSchemas, getDataStorageCatalogSchemaTables } from "../../common/datalake";

const CatalogSchemas = ({ catalogName, schemaName }) => {
    const [showTables, setShowTables] = useState(false);
    const [selectedSchema, setSelectedSchema] = useState("");
    const [objects, setObjects] = useState([]);
    const { breadcrumbs, setBreadcrumbs } = useContext(breadcrumbsContext);

    useEffect(() => {
        (async () => {
            const objList = await getDataStorageCatalogSchemaTables(catalogName, schemaName);

            setBreadcrumbs((oldArray) => [
                ...oldArray,
                {
                    name: schemaName,
                    catalog: catalogName,
                    schemaName,
                    index: oldArray.length,
                },
            ]);

            setShowTables(true);
            setSelectedSchema(schemaName);
            setObjects(objList.data);
        })();
    }, []);

    const breadcrumbsFunc = async (element) => {
        let objList;
        if (element.index === 0) {
            objList = await getDataStorageCatalogSchemas(element.catalog);
            setShowTables(false);
        } else if (element.index === 1) {
            objList = await getDataStorageCatalogSchemaTables(element.catalog, element.schema);
            setShowTables(true);
        }
        setObjects(objList.data);
        setBreadcrumbs((oldArray) => oldArray.slice(0, element.index + 1));
    };

    return (
        <>
            <BreadCrumbs linkAction={breadcrumbsFunc} />

            {showTables ? (
                <CatalogSchemaTables catalog={catalogName} schema={selectedSchema} tables={objects} />
            ) : (
                <>Loading...</>
            )}
        </>
    );
};

export default CatalogSchemas;
