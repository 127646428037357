import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button, Sizes } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

export default function DeleteWorkflowModal(props) {
    //react hook for modal
    const [show, setShow] = useState();

    const handleClose = () => {
        document.querySelector("#root").style.display = "block";
        setShow(false);
    };
    const handleShow = () => {
        document.querySelector("#root").style.display = "none";

        setShow(true);
    };
    const { workflowname, workflowndescription, workflowid } = props;

    //delete button action
    const deleteWorkflow = () => {
        var config = {
            method: "delete",
            url: process.env.REACT_APP_API_LINK + "/workflow/" + workflowid,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };

        axios(config)
            .then(function (response) {
                console.log(response);
                props.callback();
            })
            .catch(function (error) {
                console.log(error);
            });

        //close modal
        handleClose();
    };
    return (
        <>
            <Button variant={props.buttoncolor} className="nextButton" onClick={handleShow}>
                {props.modaltitle}
            </Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.modalheader}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props.modalbody} {props.workflowname}
                </Modal.Body>
                <Modal.Footer>
                    <Button className="mb-2" variant="danger" size="sm" onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        className="mb-2"
                        variant="primary"
                        size="sm"
                        workflowid={workflowid}
                        onClick={() => {
                            deleteWorkflow(workflowid);
                            handleClose();
                        }}
                    >
                        {props.rightbutton}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
